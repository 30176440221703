import { useEffect, useState } from 'react';
import MappingUtils, { getMappingStore } from '../../../../utils/CreatorTools/MappingUtils';
import getMappingToolStore, { AIMatchings } from '../data/MappingToolStore';
import { IDatabaseLineItem } from '../../LineItemDBCreationToolPage';
import { CollectionReference, collection } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import SharedUtils from '../../../../shared/SharedUtils';
import LoadingAnimation from '../../../../components/ui/utils/LoadingAnimation';
import { TemplateCellTuple } from '../../../../shared/Azure';
import { observer } from 'mobx-react';
import { logDebug } from '../../../../shared/logger';
import { wizardTitles } from './GenericWizardLeftBar';
import { IWizardStepProps } from './GenericWizardRightBar';



const MappingWizardStep1: React.FC<IWizardStepProps> = observer(({
	onCancel,
	onNext,
}) => {
	const mappingStore = getMappingStore();
	const mappingToolStore = getMappingToolStore();
	const [searchLineItemInput, setSearchLineItemInput] = useState('');
	const firestoreToolsCollectionRef = collection(useFirestore(), SharedUtils.getDbPath('tools')) as CollectionReference<IDatabaseLineItem>;
	const lineItemsDataFromDataBase = useFirestoreCollectionData<IDatabaseLineItem>(firestoreToolsCollectionRef, { idField: 'id' });
	const [showAnalysing, setShowAnalysing] = useState(false);
	// const [forceRender, setForceRender] = useState(0);
	const selectedTupple = mappingStore.templateData!.rows[mappingToolStore.selectedTuple[0]].cellTuples[mappingToolStore.selectedTuple[1]];

	// useEffect(() => {
	// 	logDebug('have change in selected line items', mappingToolStore.selectedLineItems);
	// 	setForceRender(forceRender + 1);
	// }, [mappingToolStore.selectedLineItems]);
	// useEffect(() => {
	// 	const selectedTupple = mappingStore.templateData!.rows[mappingToolStore.selectedTuple[0]].cellTuples[mappingToolStore.selectedTuple[1]];
	// 	// TODO :: this is quite a mess. Do something about it
	// 	mappingToolStore.setCurrentTupple(selectedTupple);
	// }, [selectedTupple]);



	async function analyzeMatch(selectedTupple: TemplateCellTuple, selectedLineItem: IDatabaseLineItem[]) {
		// logDebug('selectedTupple', SharedUtils.clone(selectedTupple));
		setShowAnalysing(true);

		const ticSheetInput = selectedTupple.meta?.heatMapProcessedData?.candidates || [
			// 'r+d', 'r+r', 'r', 'remove', 'replace', '1/2 inch', '4 feet', '2 ft'
		];
		const sel = [] as string[];
		selectedTupple.meta?.selectables?.forEach((s) => {
			const cleanSelectable = SharedUtils.trimAllThese(s, ['(', ')', '/']).trim();
			sel.push(cleanSelectable);
		});
		if (sel.length > 0) {
			logDebug('sel', sel);
		}

		for (const li of selectedLineItem) {
			if (mappingToolStore.getAIMatchings(li.id) !== undefined) continue;
			logDebug('analyzing', li.originalLineItem, li.id);
			const allVariables = SharedUtils.removeDuplicateAndEmpty(li.elements.flatMap(e => e.type === 'variable' ? e.acceptableValues! : []));
			const aiRet = await MappingUtils.linkVariablesUsingOpenAI(allVariables, ticSheetInput) as AIMatchings;
			sel.forEach((s) => {
				if (!Object.keys(aiRet).find(k => k.toLowerCase() === s.toLowerCase())) {
					aiRet[s] = {
						variable: 'not available',
						confidence: 'confident'
					};
				}
			});
			// TODO :: varify the result mapping contains all the keys from the ticSheetInput
			// TODO :: make sure the returned value is respecting the interface
			mappingToolStore.setAIMatchings(li.id, aiRet);
		}
		// await mappingToolStore.saveCurrentMapping('new');
		setShowAnalysing(false);
		onNext(2);
	}


	const getAutoComplateResults = (_lineItemsDataFromDataBase: IDatabaseLineItem[], _searchLineItemInput: string, _selectedLineItem: IDatabaseLineItem[]) => {
		_lineItemsDataFromDataBase = _lineItemsDataFromDataBase.filter(li => !li.deleted);
		const options = MappingUtils.filterDatabase(_lineItemsDataFromDataBase, _searchLineItemInput).filter(li => !_selectedLineItem.find(sli => sli.id === li.id));
		if (options.length === 0) return <div className='px-1.5 text-gray-300'>No results found</div>;
		return <div>
			{
				options.map((lineItem) => {
					return <div key={lineItem.id} className='cursor-pointer'
						onClick={() => {
							mappingToolStore.selectedLineItems = [...mappingToolStore.selectedLineItems, lineItem];
							setSearchLineItemInput('');
						}}
					>
						<div className="w-full px-1.5 py-0.5 justify-start items-center inline-flex hover:bg-gray-100 w-full">
							<div className="grow shrink basis-0 pl-2 pr-2.5 py-1.5 rounded-md flex-col justify-start items-start gap-2 inline-flex">
								<div className="self-stretch justify-start items-center gap-2 inline-flex">
									<div className="text-gray-900 text-base font-medium font-['Hanken Grotesk'] leading-normal  ">
										{
											lineItem.originalLineItem
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				})
			}
		</div>
	}

	return <>
		{
			!showAnalysing && <>
				<div className="self-stretch h-full flex-col justify-start items-start gap-8 flex">
					<div className="self-stretch justify-start items-start gap-2.5 inline-flex">
						<div className="grow shrink basis-0 text-gray-900 text-lg font-normal font-['Hanken Grotesk'] leading-7">{wizardTitles[mappingToolStore.wizardStep - 1]}</div>
					</div>
					<div className="self-stretch max-h-[600px] flex-col justify-start items-center gap-3 flex ">
						<div className="self-stretch h-48 px-14 pt-10 pb-14 bg-gray-100 rounded-3xl flex-col justify-start items-start gap-6 flex">
							<div className="text-gray-900 text-xl font-semibold font-['Hanken Grotesk'] leading-7 select-none">Tic-sheet cell</div>
							<div className="self-stretch h-11 flex-col justify-start items-start gap-0.5 flex">
								<div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
									<div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
										<div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
											<div className="text-gray-500 text-base font-normal font-['Hanken Grotesk'] leading-normal">
												<span className='mr-4'>
													{selectedTupple.cells[0].content}
												</span>
												|
												<span className='ml-4'>
													{selectedTupple.cells[1].content}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="self-stretch min-h-48 px-14 pt-10 pb-14 bg-gray-100 rounded-3xl flex-col justify-start items-start gap-4 flex">
							<div className="text-gray-900 text-xl font-semibold font-['Hanken Grotesk'] leading-7 select-none">Select matching line item</div>
							<div className="self-stretch  flex-col justify-start items-start gap-5 flex">
								<div className="self-stretch h-11 flex-col justify-start items-start gap-0.5 flex">
									<div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
										<div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
											<div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
												<input className='w-full p-1 focus:outline-none focus:border-none' type='text' placeholder='Select' value={searchLineItemInput} onChange={(e) => setSearchLineItemInput(e.target.value)} />
												<div className="justify-end items-center flex">
													<img className="w-5 h-5 relative rotate-90" src='/assets/ui/chevron-right.svg' />
												</div>
											</div>
										</div>
										{
											!!searchLineItemInput.length && <div className='w-full bg-white shadow rounded p-2 relative z-10'>
												{
													getAutoComplateResults(lineItemsDataFromDataBase.data, searchLineItemInput, mappingToolStore.selectedLineItems)
												}
											</div>
										}
									</div>
								</div>
								<div className='w-full max-h-[200px] overflow-y-scroll'>
									{
										mappingToolStore.selectedLineItems.map((lineItem) => {
											return <div key={lineItem.id} className="self-stretch my-3 flex-col justify-start items-start flex w-full pr-2">
												<div className="self-stretch px-3.5 py-2.5 bg-[#EFF8FF] hover:bg-[#D1E9FF] rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
													<div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
														<div className="text-gray-500 text-base font-normal font-['Hanken Grotesk'] leading-normal">{lineItem.originalLineItem}</div>
													</div>
													<div className="justify-end items-center flex cursor-pointer" onClick={() => {
														mappingToolStore.selectedLineItems = mappingToolStore.selectedLineItems.filter(sli => sli.id !== lineItem.id);
													}}>
														<img className="w-5 h-5 relative" src='/assets/ui/x-close.svg' />
													</div>
												</div>
											</div>
										})
									}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="w-full p-6 bg-white border-t border-gray-200 justify-between items-start inline-flex">
					<div
						className="px-[18px] py-2.5 bg-white rounded-lg border border-gray-200 justify-center items-center gap-2 flex cursor-pointer select-none"
						onClick={() => {
							onCancel();
						}}
					>
						<div className="justify-start items-center gap-2 flex ">
							<div className="text-slate-700 text-base font-semibold font-['Hanken Grotesk'] leading-normal">Cancel</div>
						</div>
					</div>
					<div className="justify-end items-center gap-14 flex">
						<div
							className="h-11 px-[18px] py-2.5 bg-blue-900 rounded-lg shadow border border-blue-900 justify-center items-center gap-2 flex cursor-pointer select-none"
							onClick={() => {
								analyzeMatch(selectedTupple, mappingToolStore.selectedLineItems);
							}}
						>
							<div className="justify-start items-center gap-2 flex">
								<div className="text-white text-base font-semibold font-['Hanken Grotesk'] leading-normal ">Analyze match</div>
							</div>
						</div>
					</div>
				</div>
			</>
		}
		{
			showAnalysing &&
			<div className='flex w-full h-full items-center justify-center '>
				<div className='flex flex-col w-full justify-center items-center'>
					<div>Analyzing...</div>
					<img className='w-64 h-64' src='/assets/ui/loading-building.gif' />
				</div>
			</div>
		}
		{lineItemsDataFromDataBase.status === 'loading' && <LoadingAnimation />}
	</>
});

export default MappingWizardStep1;