import { observer } from 'mobx-react';
import getMappingToolStore from '../data/MappingToolStore';
import { IWizardManager } from '../../../../data/DataStores';


export const wizardTitles = [
	"Match Mapping",
	"Variable's Mapping",
	"Map Unmapped Variables",
	"Final Tests",
];
// TODO :: make more generic
const GenericWizardLeftBar: React.FC<{ manager?: IWizardManager }> = observer(({ manager }) => {
	const mappingToolStore = getMappingToolStore();
	const localWizardTitles = manager?.wizardTitles || wizardTitles;
	const wizardSteps = localWizardTitles.map((_, i) => i + 1);
	const getStep = () => {
		if (manager) return manager.wizardStep;
		return mappingToolStore.wizardStep;
	}

	return <div className="w-64 h-full px-5 pt-[59px] bg-gray-50 flex-col justify-start items-start gap-16 inline-flex">
		<div className="text-gray-900 text-lg font-semibold font-['Hanken Grotesk'] leading-7 no-break">Create new project</div>
		<div className="h-80 flex-col justify-start items-start flex">
			{
				wizardSteps.map((step, i) => {
					return <div key={i} className="h-20 justify-start items-start gap-3 inline-flex">
						<div className="self-stretch pb-1 flex-col justify-start items-center gap-1 inline-flex">
							<div className="w-6 h-6 bg-sky-50 rounded-xl shadow justify-center items-center inline-flex">
								<div className="w-6 h-6 relative bg-sky-50 rounded-xl flex-col justify-center items-center flex">
									{step <= getStep() && <div className="w-2 h-2 bg-blue-600 rounded-full" />}
									{step > getStep() && <div className="w-2 h-2 bg-gray-200 rounded-full" />}
									{step < getStep() && <img src="/assets/ui/v.svg" />}
								</div>
							</div>
							{
								wizardSteps.length !== i + 1 &&
								<div className={`${step < getStep() ? 'bg-blue-600' : 'bg-gray-200'} w-0.5 grow shrink basis-0  rounded-sm`} />
							}
						</div>
						<div className="py-0.5 flex-col justify-start items-start inline-flex">
							<div className={`${step === getStep() ? 'text-blue-700' : 'text-slate-700 '} self-stretch  text-sm font-semibold font-['Hanken Grotesk'] leading-tight`}>
								{localWizardTitles[step - 1]}
							</div>
						</div>
					</div>
				})
			}

		</div>
	</div>
});


export default GenericWizardLeftBar;