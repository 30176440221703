import React, { useEffect, useRef } from 'react';
import { getUserStore } from '../../data/DataStores';
import LassoMVPLoginPage from './LassoMVPLoginPage';
import { getAuth } from 'firebase/auth';
import { logDebug } from '../../shared/logger';
import LassoMVPCreatorPage from './LassoMVPCreatorPage';
import LassoMVPUserPage from './LassoMVPUserPage';


const LassoMVPLandingPage: React.FC = () => {
	const userStore = useRef(getUserStore());

	if (userStore.current.user) {
		if (userStore.current.isUserCreator) {
			return <LassoMVPCreatorPage />
		} else {
			return <LassoMVPUserPage />
		}

		// return <div>Logged in as {userStore.current.user.email}
		// 	<div>
		// 		<button onClick={async () => {
		// 			await getAuth().signOut()
		// 			location.reload();
		// 		}}>Logout</button>
		// 	</div>
		// </div>;
	} else {
		return <LassoMVPLoginPage />
	}
};

export default LassoMVPLandingPage;