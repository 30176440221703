import { observer } from 'mobx-react';
import getMappingToolStore, { createLineItemFromMappingAndInput } from '../data/MappingToolStore';
import { getMappingStore } from '../../../../utils/CreatorTools/MappingUtils';
import { wizardTitles } from './GenericWizardLeftBar';
import { IWizardStepProps } from './GenericWizardRightBar';

const MappingWizardStep4: React.FC<IWizardStepProps> = observer(({
	onCancel,
	onNext,
	onBack
}) => {
	const mappingToolStore = getMappingToolStore();
	const mappingStore = getMappingStore();
	const selectedTupple = mappingStore.templateData!.rows[mappingToolStore.selectedTuple[0]].cellTuples[mappingToolStore.selectedTuple[1]]!;
	return <>
		<div className="self-stretch h-full flex-col justify-between items-start gap-8 flex">
			<div className="self-stretch justify-start items-start gap-2.5 inline-flex">
				<div className="grow shrink basis-0 text-gray-900 text-lg font-normal font-['Hanken Grotesk'] leading-7">{wizardTitles[mappingToolStore.wizardStep - 1]}</div>
			</div>
			<div className='h-[60%] overflow-y-scroll'>
				{
					selectedTupple.meta!.heatmapData!.map((data, index) => {
						const candidate = mappingStore.getCandidates(data)
						if (!candidate.length) return null;
						return <div className='w-full flex-col my-2 border border-black'>
							<img src={`data:image/png;base64,${data.region64}`} />
							<div>{
								mappingToolStore.selectedLineItems.map((lineItem, index) => {
									return <div>
										{
											[0].map(() => {
												const mapping = mappingToolStore.getVariablesMapping(lineItem.id).filter(m => !m.deleted);
												// const mappedCandidates = candidate.filter(c => mapping.find(m => m.ticInput.trim().toLowerCase() === c.trim().toLowerCase()));
												return <div>
													{/* <div>{mappedCandidates.join(' ')}</div> */}
													<div>{candidate.join(' ')}</div>
													<div>{createLineItemFromMappingAndInput(lineItem, mappingToolStore.getVariablesMapping(lineItem.id), candidate.join(' '))}</div>
												</div>
											})
										}
									</div>
								})

							}</div>
						</div>
					})
				}
			</div>

			<div className="w-full p-6 bg-white border-t border-gray-200 justify-between items-start inline-flex">
				<div
					className="px-[18px] py-2.5 bg-white rounded-lg border border-gray-200 justify-center items-center gap-2 flex cursor-pointer select-none"
					onClick={() => {
						onBack();
					}}
				>
					<div className="justify-start items-center gap-2 flex ">
						<div className="text-slate-700 text-base font-semibold font-['Hanken Grotesk'] leading-normal">Back</div>
					</div>
				</div>
				<div className="justify-end items-center gap-14 flex">
					<div
						className="h-11 px-[18px] py-2.5 bg-blue-900 rounded-lg shadow border border-blue-900 justify-center items-center gap-2 flex cursor-pointer select-none"
						onClick={() => {
							onCancel();
							// analyzeMatch(selectedTupple, selectedLineItem);
						}}
					>
						<div className="justify-start items-center gap-2 flex">
							<div className="text-white text-base font-semibold font-['Hanken Grotesk'] leading-normal ">Save Mapping</div>
						</div>
					</div>
				</div >
			</div >
		</div>

	</>
});

export default MappingWizardStep4;