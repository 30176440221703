import { getAuth } from 'firebase/auth';
import { logDebug, logError } from '../shared/logger';
import { EstimaticsFileTypes, EstimaticsProjectStoreType, getUserStore } from '../data/DataStores';
import { v4 as uuidv4 } from 'uuid';

class RequestQueue {
	private queue: (() => Promise<any>)[] = [];
	private isProcessing = false;

	async addToQueue(request: () => Promise<any>): Promise<any> {
		return new Promise((resolve, reject) => {
			this.queue.push(async () => {
				try {
					const result = await request();
					resolve(result);
				} catch (error) {
					reject(error);
				}
			});
			this.processQueue();
		});
	}

	private async processQueue() {
		if (this.isProcessing || this.queue.length === 0) {
			return;
		}

		this.isProcessing = true;
		const nextRequest = this.queue.shift();
		if (nextRequest) {
			await nextRequest();
		}
		this.isProcessing = false;
		this.processQueue();
	}
}

export interface UploadProgressCallback {
	(progress: number): void;
}
export default class RestAPI {
	private static _requestQueue = new RequestQueue();
	public static DEV_SERVER_BASE_URL = 'http://localhost:8080';
	public static PROD_SERVER_BASE_URL = `https://${location.host}`;
	public static SERVER_BASE_URL = process.env.NODE_ENV !== 'production' ? RestAPI.DEV_SERVER_BASE_URL : RestAPI.PROD_SERVER_BASE_URL;
	public static PUBLIC_BASE_URL = `${RestAPI.SERVER_BASE_URL}/output`;
	public static API_URL = `${RestAPI.SERVER_BASE_URL}/rest/v1`;

	private static _lassoToken: string | null = null;
	public static getServerBaseUrl(path: string): string {
		if (path.startsWith('/')) {
			path = path.substring(1);
		}
		if (path.startsWith('home/lasso/output')) {
			return `${RestAPI.PROD_SERVER_BASE_URL}/${path}`;
		}
		return `${RestAPI.SERVER_BASE_URL}/${path}`;
	}

	public static getStorageFileUrl(path: string): string {
		if (path.startsWith('/')) {
			path = path.substring(1);
		}

		return `${RestAPI.SERVER_BASE_URL}/rest/files/${path}`;
	}


	static async fetchAPI(api: string, method = 'GET', params: any = {}, dataField = 'data', sendAuth = true): Promise<any> {

		if (Object.keys(params).length > 0 && method === 'GET') {
			api += '?';
			Object.entries(params).forEach(([key, value]) => {
				api += `${key}=${value}&`;
			});
			params = {};
		}
		const newURL = api.startsWith('/rest/') ? `${RestAPI.SERVER_BASE_URL}${api}` : `${RestAPI.API_URL}/${api}`;
		const retData = await this.fetchURL(newURL, method, params, dataField, sendAuth);
		// logDebug('fetchAPI', api, method, params, 'response:', retData);
		return retData;
	}

	static async getIdToken(): Promise<string | null> {
		if (getAuth().currentUser) {
			try {
				return await getAuth().currentUser!.getIdToken();
			} catch (err) {
				logError('Error in getIdToken :', err);
			}
		}
		return null;
	}

	static async getBearerHeaders(): Promise<{ [s: string]: string }> {
		const authToken = await this.getIdToken() || '';
		return {
			'authorization': 'Bearer ' + authToken,
			'uid': getAuth().currentUser!.uid,
			'lasso-token': RestAPI._lassoToken || ''
		};
	}

	private static _useQueue = false;
	static async fetchURL(url: URL | string, method = 'GET', params: any = {}, dataField = 'data', sendAuth = true): Promise<any> {
		const request = async () => {
			const isFormData = params instanceof FormData;
			if (typeof url === 'string') {
				url = new URL(url);
			}
			let authToken = 'none';
			let uid = 'none';
			let response;
			if (sendAuth) {
				const auth = getAuth();
				authToken = await this.getIdToken() || '';
				uid = auth.currentUser?.uid ?? 'none';
			}
			logDebug('using lasso token', RestAPI._lassoToken)
			if (method === 'POST') {
				response = await fetch(url, {
					method,
					body: isFormData ? params : JSON.stringify(params),
					headers: isFormData ? {
						'authorization': 'Bearer ' + authToken,
						'uid': uid,
						'lasso-token': RestAPI._lassoToken || ''
					} : {
						'Content-Type': 'application/json;charset=UTF-8',
						'Accept': 'application/json',
						'authorization': 'Bearer ' + authToken,
						'uid': uid,
						'lasso-token': RestAPI._lassoToken || ''
					},
				});
			} else {
				try {
					response = await fetch(url, {
						method,
						headers: {
							'Content-Type': 'application/json;charset=UTF-8',
							'Accept': 'application/json',
							'authorization': 'Bearer ' + authToken,
							'uid': uid
						},
					});
				} catch (error) {
					return null;
				}
			}
			try {

				// TODO :: check success flag in response
				const data: any = await response.json();
				if (!data.success) {
					logError('Error in fetchURL :', data.error);
					return null;
				}
				if (data.lassoToken) {
					// set local cookie
					document.cookie = `lassoToken=${data.lassoToken};max-age=3600;SameSite=Strict`;
					RestAPI._lassoToken = data.lassoToken;
					// logDebug('Lasso token updated ' + RestAPI._lassoToken);
				}
				if (dataField && dataField in data) {
					return data[dataField];
				}
				return data;
			}
			catch (error) {
				try {
					const text: any = await response.text();
					return text;
				} catch (err) {
					logError('Error reading text from url :', err);
				}
			}
			return null
		}
		if (RestAPI._useQueue) {
			return RestAPI._requestQueue.addToQueue(request);
		} else {
			return request();
		}
	}




	// TODO :: These upload function not really belong here, move them to a separate file
	static async uploadFileWithProgress(
		file: File,
		uploadId: string,
		destination: string,
		onProgress: UploadProgressCallback,
	): Promise<{ source: string, destination: string, uploadId: string, mime: string }[] | null> {
		try {
			const xhr = new XMLHttpRequest();
			const formData = new FormData();
			formData.append('files', file);
			formData.append('destination', destination);
			const headers = await RestAPI.getBearerHeaders();
			xhr.open('POST', RestAPI.getServerBaseUrl('/rest/files/upload')); // Replace with your upload URL



			for (const [key, value] of Object.entries(headers)) {
				xhr.setRequestHeader(key, value);
			}

			// Progress event
			xhr.upload.onprogress = (event: ProgressEvent) => {
				if (event.lengthComputable) {
					const percentComplete = (event.loaded / event.total) * 100;
					logDebug(`Upload progress: ${percentComplete.toFixed(2)}%`);
					onProgress(percentComplete);
				}
			};

			const ret = await new Promise((resolve, reject) => {
				// Load event
				xhr.onload = () => {
					if (xhr.status === 200) {
						resolve(xhr.response);
					} else {
						reject(new Error(`Upload failed: ${xhr.statusText}`));
					}
				};

				// Error event
				xhr.onerror = () => {
					reject(new Error('Upload failed'));
				};

				if (file.name.includes('!$$$$$SimulateError$$$$$!')) {
					onProgress(0);
					setTimeout(() => {
						onProgress(10);
					}, 500);
					setTimeout(() => {
						onProgress(20);
					}, 1000);
					setTimeout(() => {
						reject(new Error('Simulated error'));
					}, 1500);
				} else {
					// Send the request
					xhr.send(formData);
					onProgress(0);
				}
			});
			const retJson = JSON.parse(ret as string) as { success: boolean, data: { source: string, destination: string, mime: string }[] };
			if (retJson.success) {
				return [
					{
						source: retJson.data[0].source,
						destination: retJson.data[0].destination,
						mime: retJson.data[0].mime,
						uploadId
					}
				]
			}
			return null;
		} catch (err) {
			onProgress(-1);
		}
		return null;
	}


	static async uploadOneFile(file: File, uploadId: string, fileType: EstimaticsFileTypes, store: EstimaticsProjectStoreType): Promise<{
		source: string;
		destination: string;
		uploadId: string; mime: string
	}[] | null> {
		store.addFile({
			uploadId,
			originalname: file.name,
			uploadedSofar: 0,
			size: file.size,
			fileType,
			uploadDate: new Date().toLocaleDateString('en-US'),
			uploadBy: getUserStore().user!.lassoUserId,
		});
		const response = RestAPI.uploadFileWithProgress(file,
			uploadId,
			`organizations/${getUserStore().user!.organizationId}/private/estimates/${store.newEstimateID}/files`,
			(progress) => {
				store.updateEstimaticsDataFileProgress(uploadId, progress);
				logDebug(`Upload progress: ${progress.toFixed(2)}%`);
				// Here you can update the UI with the progress
			});
		return response;
	}
	static async uploadFiles(files: FileList | File[] | null, fileType: EstimaticsFileTypes, store: EstimaticsProjectStoreType) {
		if (files?.length) {
			store.nextDisabled = store.nextDisabled + 1;
			try {
				const uploadIds: string[] = [];
				const prms: Promise<{ source: string; destination: string; uploadId: string; mime: string }[] | null>[] = [];
				for (let i = 0; i < files.length; i++) {
					const file = files[i];
					const uploadId = uuidv4();
					uploadIds.push(uploadId);
					const response = RestAPI.uploadOneFile(file, uploadId, fileType, store);
					prms.push(response);
				}
				const dests = await Promise.allSettled(prms);
				dests.forEach((d, index) => {
					if (d.status === 'fulfilled') {
						store.updateEstimaticsDataFileProperties(uploadIds[index],
							{
								remotePath: d.value![0].destination,
								mime: d.value![0].mime
							});
					} else {
						store.updateEstimaticsDataFileProperties(uploadIds[index], { error: (d.reason || 'unknow error') });
						logError('Upload error', d.reason);
					}
				});
			} catch (error) {
				logError('Upload error', error);
			}
			store.nextDisabled = store.nextDisabled - 1;
		}
	}



}

