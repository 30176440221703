import { observer } from 'mobx-react';
import { EstimaticsNoneImageFileCard, UploadFilesCard, getImageForFile } from './EstimaticsTicSheetsPanel';
import { EstimaticsFileTypes, getEstimaticsCurrentProjectStore } from '../../../data/DataStores';
import RestAPI from '../../../utils/restAPI';
import { getHumanReadableSize } from '../NewProjectWizard/NewProjectWizardStep2';
import { useEffect } from 'react';

const EstimaticsOtherDocsPanel: React.FC<{}> = observer(({ }) => {
	const currentEstimateStore = getEstimaticsCurrentProjectStore();
	const others = currentEstimateStore.estimaticsData.files.filter(f => !f.deleted && f.fileType === EstimaticsFileTypes.other)
	useEffect(() => {
		document.body.style.overflowY = 'scroll';
		return () => {
			document.body.style.overflowY = '';
		};
	}, []);

	return <div className="flex flex-wrap gap-5 justify-left p-8">
		<UploadFilesCard fileType={EstimaticsFileTypes.other} />
		{
			others.map((file, index) => <EstimaticsNoneImageFileCard key={index} file={file} />)
		}
	</div>

});

export default EstimaticsOtherDocsPanel;