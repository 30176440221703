
import React, { useEffect, useRef } from 'react';
import { getEstimaticsCurrentProjectStore } from '../../../data/DataStores';
import SharedUtils from '../../../shared/SharedUtils';
import { useNavigate } from 'react-router-dom';

export const NewProjectWizardStep3: React.FC<{ onNext: (n: number) => void; onBack: () => void; onCancel: () => void }> = ({ onNext, onBack, onCancel }) => {
	const newProjectStore = getEstimaticsCurrentProjectStore();
	const lastUploadId = useRef('');
	const [showError, setShowError] = React.useState(false);
	const navigate = useNavigate();
	async function uploadEstimate() {
		setShowError(false);
		const success = await newProjectStore.uploadEstimate(undefined, undefined, true);
		if (success) {
			// await SharedUtils.sleep(100);
			// onCancel();
			navigate(`/projects/${newProjectStore.estimaticsData.estimateId}`)
		} else {
			setShowError(true);
		}
	}
	useEffect(() => {
		// avoid uploading the same estimate twice
		if (lastUploadId.current === newProjectStore.newEstimateID) {
			return;
		}
		lastUploadId.current = newProjectStore.newEstimateID;
		uploadEstimate();
	}, []);
	return <div className='flex w-full h-full items-center justify-center select-none'>
		<div className='flex flex-col w-full justify-center items-center'>
			{
				!showError &&
				<div>
					<div>Creating Estimate...</div>
					<img className='w-64 h-64' src='/assets/ui/loading-building.gif' />
				</div>
			}
			{
				showError &&
				<div className="w-[616px] h-[366px] px-[88px] flex-col justify-center items-center gap-[23px] inline-flex">
					<div className="w-32 h-32 p-[5.82px] justify-center items-center inline-flex">
						<img src='/assets/ui/sign-no.svg' className="w-[116.36px] h-[116.36px] relative flex-col justify-start items-start flex" />

					</div>
					<div className="text-gray-900 text-xl font-semibold font-['Hanken Grotesk'] leading-7">Server Error: Failed to Create New Project</div>
					<div className="self-stretch text-center">
						<span className="text-gray-900 text-xs font-normal font-['Hanken Grotesk'] leading-none">
							An unexpected server error occurred while attempting to create your new estimate. Please try again.
							<br />
						</span>
						<span className="text-gray-900 text-xs font-normal font-['Hanken Grotesk'] leading-none">
							Important: To avoid losing your entered data, please do not close this page. If the issue persists after multiple attempts, please contact our support team for assistance.
						</span>
					</div>
					<div className="px-4 py-2.5 bg-blue-900 rounded-lg shadow border border-blue-900 justify-start items-center gap-2 inline-flex btn"
						onClick={uploadEstimate}
					>
						<div className="justify-start items-center gap-2 flex">
							<div className="text-white text-sm font-semibold font-['Hanken Grotesk'] leading-tight">Try Again</div>
						</div>
					</div>
				</div>
			}
		</div>
	</div>
};