import * as animationData from './loading.json'
import Lottie from 'react-lottie';

const LoadingAnimation: React.FC<{
	fullScreen?: boolean,
	width?: number,
	height?: number
}> = ({ fullScreen = true, width = 400, height = 400 }) => {

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};
	return <div style={{
		position: fullScreen ? 'fixed' : 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: fullScreen ? 'rgba(0, 0, 0, 0.5)' : '',
		zIndex: 1000,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}}>
		<Lottie options={defaultOptions}
			height={width}
			width={height}
		//   isStopped={this.state.isStopped}
		// isPaused={this.state.isPaused}
		/>
	</div>
}

export default LoadingAnimation;