import { observer } from 'mobx-react';
import { getEstimaticsCurrentProjectStore } from '../../../data/DataStores';
import React, { useEffect, useRef, useState } from 'react';
import { logDebug } from '../../../shared/logger';
import Analytics from '../../../utils/Analytics';

const EstimaticsLinksPanel: React.FC<{}> = observer(({ }) => {
	const currentEstimateStore = getEstimaticsCurrentProjectStore();
	const inputRef = useRef<HTMLInputElement>(null);
	const [defaultLinkValues, setDefaultLinkValues] = useState<{ [k: number]: string }>({});
	const [forceFocus, setForceFocus] = useState(0);

	useEffect(() => {
		document.body.style.overflowY = 'scroll';
		return () => {
			document.body.style.overflowY = '';
		};
	}, []);
	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [forceFocus]);


	return <div className="mt-8 w-full min-h-[250px]  min-w-[650px] bg-white rounded-xl shadow border border-gray-200 flex-col justify-start items-start inline-flex">
		<div className="self-stretch h-[101px] pl-4 pr-6 pt-6 pb-8 bg-white border-b rounded-xl border-gray-200 flex-col justify-start items-start gap-4 flex">
			<div className="self-stretch justify-between items-center inline-flex">
				<div className="w-[480px] flex-col justify-start items-start gap-0.5 inline-flex">
					<div className="self-stretch h-[45px] flex-col justify-start items-start gap-1.5 flex">
						<div className="self-stretch px-3.5 py-2.5  justify-start items-center gap-2 inline-flex">
							<div className="grow shrink basis-0 h-[25px] justify-start items-center gap-2 flex">
								<div className="text-gray-900 text-lg font-semibold font-['Hanken Grotesk'] leading-[25.20px]">Links</div>
							</div>
						</div>
					</div>
				</div>
				<div className="self-stretch  justify-end items-center inline-flex">
					<div className="px-4 py-2.5 bg-sky-50 rounded-lg border border-sky-50 justify-center items-center gap-2 flex btn">
						<div className="justify-start items-center gap-2 flex "
							onClick={() => {
								Analytics.Instance.track('EstimaticsLinkAddClicked');
								currentEstimateStore.pushLinkFront('');
							}}
						>
							<img className="w-5 h-5 relative" src='/assets/ui/plus.svg' />
							<div className="text-blue-700 text-sm font-semibold font-['Hanken Grotesk'] leading-tight">Add link</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		{
			currentEstimateStore.estimaticsData.links.map((link, index) => {
				return <div className='w-full relative'>
					<div key={index} className="self-stretch h-[72px] flex-col justify-start items-start gap-6 flex">
						<div className={`${currentEstimateStore.estimaticsData.links.length - 1 !== index ? 'border-b' : ''} relative self-stretch h-[72px] pl-6 pr-4 bg-white border-l border-r  border-gray-200 justify-start items-center gap-6 inline-flex`}>
							{
								link &&
								<div className='no-break max-w-[80%]'>
									<div className=" basis-0 text-[#194185] text-sm font-normal font-['Hanken Grotesk'] underline leading-tight cursor-pointer" onClick={() => {
										window.open(link.startsWith('http') ? link : `http://${link}`, '_blank', 'noreferrer');
									}}>
										{link}
									</div>
								</div>
							}
							{
								!link &&
								<>
									<input ref={inputRef} type="text" className="focus:outline-none max-w-[80%] grow shrink basis-0 text-gray-900 text-sm font-normal font-['Hanken Grotesk'] border rounded p-1 leading-tight"
										placeholder="Enter link"
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												inputRef.current?.blur();
											}
										}}
										defaultValue={defaultLinkValues[index] || ''}
										onBlur={(e) => {
											if (e.target.value) {
												setTimeout(() => {
													currentEstimateStore.setLink(index, e.target.value);
													if (e.target.value !== defaultLinkValues[index]) {
														currentEstimateStore.uploadEstimate('links', true);
													}
												}, 100);
											}
											setDefaultLinkValues({});
										}} />
									<div className="absolute top-12 text-slate-600 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
										Click anywhere outside this field to save changes
									</div>
								</>
							}
							{/* <div className="grow shrink basis-0 text-gray-900 text-sm font-normal font-['Hanken Grotesk'] underline leading-tight">https://www.globaltechinsights.com/articles/breakthrough-quantum-computing-research</div> */}
						</div>
					</div>
					<div className={` absolute right-[70px] top-4  p-2  rounded-lg border border-red-50 ${link ? 'btn' : ''}`}
						onClick={() => {
							logDebug('Edit link clicked', link);
							if (!link) return;
							logDebug('Edit link clicked 1', link);
							Analytics.Instance.track('EditEstimaticsLink');
							setDefaultLinkValues({ ...defaultLinkValues, [index]: link });
							currentEstimateStore.setLink(index, '');
							setForceFocus(v => v + 1);
						}}
					>
						<img className="w-5 h-5    relative " src={`/assets/ui/edit-03${link ? '' : '-disabled'}.svg`} />
					</div>
					<div className=' absolute right-6 top-4 bg-red-50 p-2  rounded-lg border border-red-50 btn'
						onClick={() => {
							Analytics.Instance.track('DeleteEstimaticsLink');
							currentEstimateStore.removeLink(index);
							currentEstimateStore.uploadEstimate('links', true);
						}}
					>
						<img className="w-5 h-5    relative cursor-pointer" src='/assets/ui/trash-02.svg' />
					</div>

				</div>

			})}

	</div>

	return (
		<div>
			{
				currentEstimateStore.estimaticsData.links.map((link, index) => {
					return (
						<div key={index} className='flex items-center justify-start  m-4'>
							<a href={link} target='_blank' rel='noreferrer' className='bg-gray-200'>{link}</a>
						</div>
					)
				})}
		</div>
	);
});

export default EstimaticsLinksPanel;