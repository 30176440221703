import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { getMappingStore } from '../../../../utils/CreatorTools/MappingUtils';
import getMappingToolStore from '../data/MappingToolStore';
import MappingWizardStep1 from './MappingWizardStep1';
import MappingWizardStep2 from './MappingWizardStep2';
import MappingWizardStep3 from './MappingWizardStep3';
import MappingWizardStep4 from './MappingWizardStep4';
import { useEffect } from 'react';
import { IWizardManager } from '../../../../data/DataStores';


export interface IWizardStepProps {
	onNext: (next: number) => void;
	onBack: () => void;
	onCancel: () => void;
	manager?: IWizardManager;
}

// TODO :: make more generic
const GenericWizardRightBar: React.FC<{ manager?: IWizardManager, steps?: React.ComponentType<IWizardStepProps>[] }> = observer(({ manager, steps }) => {
	const mappingToolStore = getMappingToolStore();
	const mappingStore = getMappingStore();

	const onCancel = () => {
		!manager && (mappingToolStore.selectedTuple = [-1, -1]);
		(manager || mappingToolStore).wizardStep = -1;
	};
	const onNext = (next: number) => {
		(manager || mappingToolStore).wizardStep = next;
	};
	const onBack = () => {
		(manager || mappingToolStore).wizardStep--;
	};
	useEffect(() => {
		if (!manager) {
			// TODO :: this is quite a mess. Do something about it
			const selectedTupple = mappingStore.templateData!.rows[mappingToolStore.selectedTuple[0]].cellTuples[mappingToolStore.selectedTuple[1]];
			mappingToolStore.setCurrentTupple(selectedTupple);

		}
	}, []);
	// TODD:: clean
	if (!steps) {
		steps = [MappingWizardStep1, MappingWizardStep2, MappingWizardStep3, MappingWizardStep4];
	}

	const StepComponent = steps[(manager || mappingToolStore).wizardStep - 1];

	return <div className="flex w-full h-full bg-white flex-col justify-between items-start inline-flex ">
		<div className='w-full h-full '>
			<StepComponent onCancel={onCancel} onNext={onNext} onBack={onBack} manager={manager} />
		</div>
		<div className="cursor-pointer absolute right-6 top-4 bg-white">
			<img src="/assets/ui/x-close.svg" className='w-5 h-5' onClick={() => {
				if (manager || mappingToolStore.selectedLineItems.length > 0) {
					const result = window.confirm("Are you sure you want to close? You will lose all unsaved data.");
					if (result) {
						onCancel();
					}
				} else {
					onCancel();
				}
			}
			} />
		</div>
		{/* {
			mappingToolStore.wizardStep === 1 &&
			<MappingWizardStep1 onCancel={onCancel} onNext={onNext} onBack={onBack} />

		}
		{
			mappingToolStore.wizardStep === 2 &&
			<MappingWizardStep2 onCancel={onCancel} onNext={onNext} onBack={onBack} />
		}
		{
			mappingToolStore.wizardStep === 3 &&
			<MappingWizardStep3 onCancel={onCancel} onNext={onNext} onBack={onBack} />
		}
		{
			mappingToolStore.wizardStep === 4 &&
			<MappingWizardStep4 onCancel={onCancel} onNext={onNext} onBack={onBack} />
		} */}
	</div>
});


export default GenericWizardRightBar;