import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { EstimaticsDataKeys, EstimaticsFileTypes, IEstimaticsData, getAppStore, getEstimateStore, getEstimaticsCurrentProjectStore, getUserStore } from '../../data/DataStores';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { CollectionReference, collection } from 'firebase/firestore';
import LassoNavigationBar from '../components/LassoNavigationBar';
import EstimaticsImagesPanel from '../components/Estimatics/EstimaticsImagesPanel';
import EstimaticsTicSheetsPanel from '../components/Estimatics/EstimaticsTicSheetsPanel';
import EstimaticsOtherDocsPanel from '../components/Estimatics/EstimaticsOtherDocsPanel';
import EstimaticsLinksPanel from '../components/Estimatics/EstimaticsLinksPanel';
import EstimaticsNotesPanel from '../components/Estimatics/EstimaticsNotesPanel';

import EstimaticsStatusComboBox from '../components/Estimatics/EstimaticsStatusComboBox';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LoadingAnimation from '../../components/ui/utils/LoadingAnimation';
import AddressCompletion from '../../components/ui/utils/AddressCompletion';
import uploadingAnimation from './uploadingAnimation.json';
import LottieAnimation from '../../components/ui/utils/LottieAnimation';
import { AttachmentFile } from '../../shared/Azure';
import { appFeatures } from '../../data/AppFeatures';
import Analytics from '../../utils/Analytics';
import { useWindowSize } from './LassoMVPUserPage';
import SharedUtils from '../../shared/SharedUtils';
import Select from 'react-select';
import { logDebug } from '../../shared/logger';

const EstimaticsProjectTopPanelEditableInput: React.FC<{ width?: number | string, propertyName: EstimaticsDataKeys | string, textClassName?: string }> = ({ propertyName, textClassName, width }) => {
	const newProjectStore = getEstimaticsCurrentProjectStore();
	const [showLoading, setShowLoading] = useState(false);
	function _getValue(): string {
		return newProjectStore.getValue(propertyName!);
	}
	async function _setValue(v: string) {
		if (_getValue() === v) return;
		setShowLoading(true);
		try {
			await newProjectStore.setValue(propertyName!, v, true);
		} catch (err) {

		}
		setShowLoading(false);
	}
	const [startValue, setStartValue] = useState(_getValue());
	const [value, setValue] = useState(_getValue() || '_ _ _');
	const [editMode, setEditMode] = useState(false);
	const inputRef = React.useRef<HTMLInputElement>(null);


	const handleEditDone = () => {
		if (propertyName === 'projectName' && !value) {
			setValue(startValue);
		} else {
			setStartValue(value);
			setValue(value || '_ _ _');
			_setValue(value);
		}
		setEditMode(false);
		Analytics.Instance.track('EditableInputComplete', { 'propertyName': propertyName, 'value': value });
	}
	return <div className='ml-3.5 mt-2'>
		{
			editMode ?
				<input
					ref={inputRef}
					className={`outline-sky-300 shadow rounded-md px-[7px] -ml-[7px]  h-[28px] text-gray-900 text-lg font-normal font-['Hanken Grotesk'] leading-normal ${textClassName} `}
					style={{ width: width || 245 }}
					value={value}
					onChange={(e) => {
						setValue(e.target.value);
					}}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							handleEditDone();
						}
					}}
					onBlur={() => {
						handleEditDone();
					}}
				/>
				: <div className={`flex justify-between hover:-mt-[1px] hover:border hover:h-8 hover:pl-[7px] border-sky-300 rounded-md  pl-2 -ml-2  whitespace-nowrap overflow-x-hidden text-ellipsis text-gray-900 text-lg font-normal font-['Hanken Grotesk']  cursor-pointer`}
					style={{ width: width || 245 }}
					onClick={() => {
						Analytics.Instance.track('EditableInputEntered', { 'propertyName': propertyName, 'value': value });
						setEditMode(true);
						if (value === '_ _ _')
							setValue('');
						setTimeout(() => {
							inputRef.current?.focus();
						}, 0);
					}}
					onMouseEnter={() => {
						Analytics.Instance.track('EditableInputHover', { 'propertyName': propertyName, 'value': value });
					}}
				>
					<span className={textClassName}>{value}</span>
					{showLoading && <LottieAnimation animationData={uploadingAnimation} w={25} h={25} />}
				</div>
		}
	</div>
}


export const EstimaticsAssigneeBox: React.FC<{
	width?: number | string,
	height?: number | string,
	disabled?: boolean,
	// allowEmpty?: boolean,
	value?: string,
	onChange?: (v: string) => void
}> = observer(({ height, width, disabled, value, onChange }) => {
	const userStore = getUserStore();
	const user = userStore.getOtherUserById(value || '', false);
	let displayName = '';
	let newSelectValue: any = null;
	if (user) {
		displayName = `${user.firstName} ${user.lastName}`;
		newSelectValue = {
			value: user.lassoUserId,
			label: displayName
		}
	}
	// let newSelectValue: any = value || (allowEmpty ? '' : 'Unassigned');
	// newSelectValue =value ? {
	// 	value,
	// 	label: sataicValue
	// } : null;

	return <>
		{
			disabled && <div className=" max-w-[160px] px-1 py-2  -mt-1 no-break ">{displayName}</div>
		}
		{

			!disabled &&
			<Select
				placeholder='Unassigned'
				isClearable={true}
				className='ml-3 py-1 w-[160px] '
				value={
					newSelectValue
				}
				onChange={(newValue) => {
					Analytics.Instance.track('EstimaticsAssigneeChanged', {
						assignee: newValue?.value ?? '',
						oldAssignee: value,
					});
					onChange && onChange(newValue?.value ?? '');
				}}
				options={userStore.getOtherUserList(false).filter(u => !u.firstName.toLowerCase().includes('unassigned')).map((_user) => ({
					value: _user.lassoUserId,
					label: `${_user.firstName} ${_user.lastName}`
				}))}
			/>
		}
	</>
});


const EstimaticsProjectComboBox: React.FC<{
	placeholder?: string,
	options: string[],
	value: string,
	onChange: (v: string) => void,
	title?: string,
	analyticsTitle?: string,
	clearable?: boolean,
	className?: string
}> = observer(({ className, options, value, onChange, title, clearable, placeholder, analyticsTitle }) => {
	return <>
		{title &&
			<div className="self-stretch px-3.5 justify-start items-start inline-flex ">
				<div className="grow shrink basis-0 text-slate-600 text-sm font-normal font-['Hanken Grotesk'] leading-tight">{title}</div>
			</div>
		}
		<Select
			// styles={{
			// 	input(base, props) {
			// 		return {
			// 			...base,
			// 			// backgroundColor: 'red',
			// 		}
			// 	},
			// 	container(base, props) {
			// 		return {
			// 			...base,
			// 			// backgroundColor: 'cyan',
			// 		}
			// 	},
			// 	control(base, props) {
			// 		return {
			// 			...base,
			// 			// backgroundColor: 'blue',
			// 		}
			// 	}
			// }}
			placeholder={placeholder}
			isClearable={!!clearable}
			className={className || 'ml-3 py-1 w-[160px]'}
			value={value ? {
				value,
				label: value
			} : null}
			onChange={(newValue) => {
				Analytics.Instance.track('EstimaticsProjectComboBoxChanged', {
					title: title || analyticsTitle || '',
					value: newValue?.value || '',
					oldValue: value,
				});
				onChange(newValue?.value || '');
			}}
			options={options.map(opt => ({ value: opt, label: opt }))}
		/>
	</>
});

const RightEstimatePanel: React.FC<{ bottom?: boolean }> = observer(({ bottom }) => {
	const currentProjectStore = getEstimaticsCurrentProjectStore();
	const userStore = getUserStore();
	return <div className={`-ml-8 mt-4  w-${bottom ? 'full' : '[424px]'} px-4 py-4 bg-white rounded-lg shadow border border-gray-200 flex-col justify-start items-center gap-4 inline-flex `}>
		<div className="self-stretch justify-start items-start gap-4 inline-flex">
			<div className="grow shrink basis-0 px-3.5 flex-col justify-start items-start gap-1 inline-flex">
				<div className="self-stretch text-slate-600 text-sm font-normal font-['Hanken Grotesk'] leading-tight">Approx. claim</div>
				<div className="w-[160px] relative -left-[14px]">
					<EstimaticsProjectTopPanelEditableInput propertyName='approximateClaim' textClassName='text-xl  font-semibold' width={150} />
				</div>
			</div>
			<div className="grow shrink basis-0 px-3.5 flex-col justify-start items-start gap-1 inline-flex">
				<div className="self-stretch text-slate-600 text-sm font-normal font-['Hanken Grotesk'] leading-tight">Status</div>
				<div className="w-[160px] h-8 flex-col justify-center items-start gap-2.5 flex mt-2">
					<EstimaticsStatusComboBox status={currentProjectStore.estimaticsData.status} />
				</div>
			</div>
		</div>
		<div className="self-stretch justify-start items-start gap-4 inline-flex">
			<div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
				<div className="self-stretch px-3.5 justify-start items-start inline-flex">
					<div className="grow shrink basis-0 text-slate-600 text-sm font-normal font-['Hanken Grotesk'] leading-tight">Assignee</div>
				</div>
				<div className="self-stretch h-11 flex-col justify-start items-start gap-0.5 flex">
					<div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
						<div className="self-stretch  rounded-lg justify-start items-center gap-2 inline-flex">
							<EstimaticsAssigneeBox value={currentProjectStore.estimaticsData.assignee} onChange={(v) => {
								currentProjectStore.setValue('assignee', v, true);
							}} />
						</div>
					</div>
				</div>
			</div>
			<div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
				<EstimaticsProjectComboBox
					placeholder='Office'
					clearable={true}
					title='Office location'
					options={userStore.officeList.filter((office) => !!office)}
					value={currentProjectStore.estimaticsData.office} onChange={(v) => {
						currentProjectStore.setValue('office', v, true);
					}} />
			</div>
		</div>
		<div className="self-stretch justify-start items-start gap-4 inline-flex">
			<div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
				<EstimaticsProjectComboBox
					title='Loss Category'
					options={['Other/Unknown', 'Cat 1', 'Cat 2', 'Cat 3']}
					value={currentProjectStore.estimaticsData.category || 'Other/Unknown'}
					onChange={(v) => currentProjectStore.setValue('category', v, true)}
				/>
			</div>
			<div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
				<EstimaticsProjectComboBox
					title='Type of Loss'
					options={['Other/Unknown', 'Water', 'Fire', 'Mold']}
					value={currentProjectStore.estimaticsData.typeOfLoss || 'Other/Unknown'}
					onChange={(v) => currentProjectStore.setValue('typeOfLoss', v, true)} />
			</div>
		</div>
		<div className="self-stretch justify-start items-start gap-4 inline-flex">
			<div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
				<div className="self-stretch px-3.5 justify-start items-start inline-flex">
					<div className="grow shrink basis-0 text-slate-600 text-sm font-normal font-['Hanken Grotesk'] leading-tight">Date contacted</div>
				</div>
				<div className="self-stretch h-11 flex-col justify-start items-start gap-0.5 flex relative">
					<div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex  max-w-32 ">
						<div className="self-stretch px-3.5 py-2.5 rounded-lg justify-start items-center gap-2 inline-flex">
							<DatePicker
								className='focus:outline-none border rounded-md px-1 py-1 w-40'
								selected={currentProjectStore.estimaticsData.dateContacted ?
									new Date(currentProjectStore.estimaticsData.dateContacted.replace(/\//g, '-')) : new Date()}
								onChange={(date) => {
									if (date) {
										Analytics.Instance.track('EstimaticsDateContactedChanged', {
											date: date.toLocaleDateString('en-US'),
											oldDate: currentProjectStore.estimaticsData.dateContacted,
										});
										currentProjectStore.setValue('dateContacted', date.toLocaleDateString('en-US'), true);
									}
								}}
								dateFormat="yyyy/MM/dd"
								isClearable={false}
								placeholderText="Select a date"
							/>
						</div>
					</div>
					<img src='/assets/ui/calendar.svg' className='w-5 h-5 absolute right-4 top-4 '
						style={{
							pointerEvents: 'none'
						}}
					/>
				</div>
			</div>
			<div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
				<div className="self-stretch px-3.5 justify-start items-start inline-flex">
					<div className="grow shrink basis-0 text-slate-600 text-sm font-normal font-['Hanken Grotesk'] leading-tight">Date of Loss</div>
				</div>
				<div className="self-stretch h-11 flex-col justify-start items-start gap-0.5 flex relative">
					<div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex max-w-32">
						<div className="self-stretch px-3.5 py-2.5 rounded-lg justify-start items-center gap-2 inline-flex ">
							<DatePicker
								className='focus:outline-none border rounded-md px-1 py-1 w-40'
								selected={currentProjectStore.estimaticsData.dateOfLoss ?
									new Date(currentProjectStore.estimaticsData.dateOfLoss.replace(/\//g, '-')) : new Date()}
								onChange={(date) => {
									if (date) {
										Analytics.Instance.track('EstimaticsDateOfLossChanged', {
											date: date.toLocaleDateString('en-US'),
											oldDate: currentProjectStore.estimaticsData.dateContacted,
										});
										currentProjectStore.setValue('dateOfLoss', date.toLocaleDateString('en-US'), true);
									}
								}}
								dateFormat="yyyy/MM/dd"
								isClearable={false}
								placeholderText="Select a date"
							/>
						</div>
					</div>
					<img src='/assets/ui/calendar.svg' className='w-5 h-5 absolute right-4 top-4 '
						style={{
							pointerEvents: 'none'
						}}
					/>

				</div>
			</div>
		</div>
	</div>
});

const EstimaticsProjectTopPanel: React.FC<{}> = observer(({ }) => {
	const elementClassName0 = 'w-[20%] max-w-[310px] flex-col justify-start items-start gap-0.5 inline-flex ';
	const elementClassName = 'w-[33%] max-w-[310px] flex-col justify-start items-start gap-0.5 inline-flex ';
	const currentProjectStore = getEstimaticsCurrentProjectStore();
	const userStore = getUserStore();
	const windowSize = useWindowSize()
	const wBreakpoint = 1470;

	return <div className=" px-14 justify-between items-center gap-14 flex ">
		<div className="grow shrink basis-0 flex-col justify-start items-start gap-6 inline-flex ">
			<div className="self-stretch justify-start items-start gap-6 inline-flex">
				<div className={elementClassName0}>
					<div className="self-stretch px-3.5 justify-start items-start inline-flex">
						<div className="text-gray-900 text-lg font-semibold font-['Hanken Grotesk'] leading-[25.20px] no-break">Project name</div>
					</div>
					<div className="self-stretch h-[45px] flex-col justify-start items-start gap-0.5 flex">
						<EstimaticsProjectTopPanelEditableInput propertyName='projectName' />

					</div>
				</div>
				<div className={elementClassName}>
					<div className="self-stretch px-3.5 justify-start items-start inline-flex">
						<div className="text-gray-900 text-lg font-semibold font-['Hanken Grotesk'] leading-[25.20px] no-break">Estimate Reference Number</div>
					</div>
					<div className="self-stretch h-[45px] flex-col justify-start items-start gap-0.5 flex">
						<EstimaticsProjectTopPanelEditableInput propertyName='estimateReferenceNumber' />
					</div>
				</div>
				<div className={elementClassName}>
					<div className="self-stretch px-3.5 justify-start items-start inline-flex">
						<div className="text-gray-900 text-lg font-semibold font-['Hanken Grotesk'] leading-[25.20px] no-break">Insurance carrier</div>
					</div>
					<EstimaticsProjectComboBox
						analyticsTitle='Insurance carrier'
						value={currentProjectStore.estimaticsData.insuranceCarrier || (userStore.carrierList.includes('Other') ? 'Other' : [...userStore.carrierList].sort()[0])}
						onChange={(newValue) => {
							currentProjectStore.setValue('insuranceCarrier', newValue, true)
						}}
						options={[...userStore.carrierList].sort()}
						className='self-stretch  min-w-32 max-w-64 relative my-1  mx-3 '
					/>
				</div>
			</div>
			<div className="self-stretch justify-start items-start gap-6 inline-flex">
				<div className={elementClassName0}>
					<div className="self-stretch px-3.5 justify-start items-start inline-flex">
						<div className="text-gray-900 text-base font-semibold font-['Hanken Grotesk'] leading-snug no-break">Claim number</div>
					</div>
					<div className="self-stretch h-11 flex-col justify-start items-start gap-0.5 flex">
						<div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
							<EstimaticsProjectTopPanelEditableInput propertyName='claimNumber' />
						</div>
					</div>
				</div>
				<div className={elementClassName}>
					<div className="self-stretch px-3.5 justify-start items-start inline-flex">
						<div className="text-gray-900 text-base font-semibold font-['Hanken Grotesk'] leading-snug no-break">Phone</div>
					</div>
					<div className="self-stretch h-11 flex-col justify-start items-start gap-0.5 flex">
						<div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
							<EstimaticsProjectTopPanelEditableInput propertyName='phone' />
						</div>
					</div>
				</div>
				<div className={elementClassName}>
					<div className="self-stretch px-3.5 justify-start items-start inline-flex">
						<div className="text-gray-900 text-base font-semibold font-['Hanken Grotesk'] leading-snug no-break">Requester name</div>
					</div>
					<div className="self-stretch h-11 flex-col justify-start items-start gap-0.5 flex">
						<div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
							<EstimaticsProjectTopPanelEditableInput propertyName='requesterName' />
						</div>
					</div>
				</div>
			</div>
			<div className="self-stretch justify-start items-start gap-6 inline-flex">
				<div className={elementClassName0}>
					<div className="self-stretch px-3.5 justify-start items-start inline-flex">
						<div className="text-gray-900 text-base font-semibold font-['Hanken Grotesk'] leading-snug no-break">TPA</div>
					</div>
					{
						userStore.tpaList.length === 0 &&
						<div className="self-stretch h-11 flex-col justify-start items-start gap-0.5 flex">
							<div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
								<EstimaticsProjectTopPanelEditableInput propertyName='tpa' />
							</div>
						</div>
					}
					{
						userStore.tpaList.length > 0 &&
						<EstimaticsProjectComboBox
							placeholder='Select TPA'
							analyticsTitle='TPA'
							clearable={true}
							value={currentProjectStore.estimaticsData.tpa}
							onChange={(newValue) => {
								currentProjectStore.setValue('tpa', newValue, true)
							}}
							options={userStore.tpaList.filter((tpa) => !!tpa)}
							className='self-stretch  min-w-32 max-w-64 relative   my-1  mx-3 '
						/>
					}
				</div>
				<div className={elementClassName}>
					<div className="self-stretch px-3.5 justify-start items-start inline-flex">
						<div className="text-gray-900 text-base font-semibold font-['Hanken Grotesk'] leading-snug no-break">Email</div>
					</div>
					<div className="self-stretch h-11 flex-col justify-start items-start gap-0.5 flex">
						<div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
							<EstimaticsProjectTopPanelEditableInput propertyName='email' />
						</div>
					</div>
				</div>
				<div className={elementClassName}>
					<div className="self-stretch px-3.5 justify-start items-start inline-flex">
						<div className="text-gray-900 text-base font-semibold font-['Hanken Grotesk'] leading-snug no-break">Property address</div>
					</div>
					<div className="self-stretch h-11 flex-col justify-start items-start gap-0.5 flex">
						<div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
							<AddressCompletion
								className='self-stretch  min-w-32 max-w-64 focus:outline-none py-1  m-2 ml-3 px-1 border rounded-md'
								value={currentProjectStore.getValue('propertyAddress')}
								onChange={(v) => currentProjectStore.setValue('propertyAddress', v)}
								placeholder='Enter address'
								onComplete={(v) => {
									Analytics.Instance.track('EstimaticsAddressCompleted', {
										address: v,
										oldAddress: currentProjectStore.getValue('propertyAddress'),
									});
									currentProjectStore.setValue('propertyAddress', v, true);
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			{
				userStore.extraFields.length > 0 &&
				SharedUtils.chunkArray(userStore.extraFields, 3).map((chunk, index) => {
					return <div className="self-stretch justify-start items-start gap-6 inline-flex">

						{
							chunk.map((field, _index) => {
								return <div key={_index} className={_index === 0 ? elementClassName0 : elementClassName}>
									<div className="self-stretch px-3.5 justify-start items-start inline-flex">
										<div className="text-gray-900 text-base font-semibold font-['Hanken Grotesk'] leading-snug no-break">{field}</div>
									</div>
									<div className="self-stretch h-11 flex-col justify-start items-start gap-0.5 flex">
										<div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
											<EstimaticsProjectTopPanelEditableInput propertyName={field} />
										</div>
									</div>
								</div>
							})
						}
					</div>

				})
			}
			{
				windowSize.width <= wBreakpoint &&
				<RightEstimatePanel bottom={true} />
			}
		</div>
		{
			windowSize.width > wBreakpoint &&
			<RightEstimatePanel />
		}
	</div>
});


const EstimaticsProjectDocumentsTopBar: React.FC<{}> = observer(({ }) => {
	return <div className="text-gray-900 text-lg font-semibold font-['Hanken Grotesk'] w-full h-[101px] flex items-center  border-b border-gray-200 ">
		<div className="ml-9 ">Attached documentation</div>
	</div>
});

enum CardTypes {
	Estimate = 'Estimate',
	TicSheet = 'Tic Sheet',
	Photos = 'Photos',
	OtherDocumentation = 'Other Documentation',
	Links = 'Links',
	InternalNotes = 'Internal Notes',
	Dummy = 'Dummy'
}
// move to utils
const getEnumValues = <T extends object>(e: T): (T[keyof T])[] => {
	return Object.values(e);
};

const EstimaticsProjectDocumentCard: React.FC<{ cardType: CardTypes }> = observer(({ cardType }) => {
	const estimateStore = getEstimateStore();
	const currentProjectStore = getEstimaticsCurrentProjectStore();
	function getLastUpdateString(files: AttachmentFile[]) {
		if (!files.length) return '';
		const sortedDates = files.map(f => new Date((f.uploadDate || '01/01/1970').replace(/\//g, '-'))).sort((a, b) => {
			const dateA = new Date(a);
			const dateB = new Date(b);
			return dateB.getTime() - dateA.getTime();
		});
		const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
		const formattedDate = sortedDates[0].toLocaleDateString('en-US', options).replace(',', '.');
		return ` • Last updated: ${formattedDate}`;

	}


	const properties: { [k: string]: any } = {
		[CardTypes.Photos]: {
			title: 'Photos',
			description: `File count: ${currentProjectStore.estimaticsData.files.filter((file) => !file.deleted && file.fileType === EstimaticsFileTypes.image).length} ${getLastUpdateString(currentProjectStore.estimaticsData.files.filter((file) => !file.deleted && file.fileType === EstimaticsFileTypes.image))}`,

			imageUrl: '/assets/estimatics/CardPhotos.png',
			centerImage: '/assets/estimatics/image-03.svg',
		},
		[CardTypes.TicSheet]: {
			title: 'Tic Sheets',
			description: `File count: ${currentProjectStore.estimaticsData.files.filter((file) => !file.deleted && file.fileType === EstimaticsFileTypes.tic).length}  ${getLastUpdateString(currentProjectStore.estimaticsData.files.filter((file) => !file.deleted && file.fileType === EstimaticsFileTypes.tic))} `,
			imageUrl: '/assets/estimatics/CardTic.png',
			centerImage: '/assets/estimatics/building-02.svg',
		},
		[CardTypes.OtherDocumentation]: {
			title: 'Other Documentation',
			description: `File count: ${currentProjectStore.estimaticsData.files.filter((file) => !file.deleted && file.fileType === EstimaticsFileTypes.other).length} ${getLastUpdateString(currentProjectStore.estimaticsData.files.filter((file) => !file.deleted && file.fileType === EstimaticsFileTypes.other))} `,
			imageUrl: '/assets/estimatics/CardOther.png',
			centerImage: '/assets/estimatics/file-06.svg',

		},
		[CardTypes.Links]: {
			title: 'Links',
			description: `Link count: ${currentProjectStore.estimaticsData.links.length}`,
			imageUrl: '/assets/estimatics/CardLinks.png',
			centerImage: '/assets/estimatics/link-03.svg',

		},
		[CardTypes.InternalNotes]: {
			title: 'Internal Notes',
			description: `Note count: ${currentProjectStore.estimaticsData.notes.length}`,
			imageUrl: '/assets/estimatics/CardNote.png',
			centerImage: '/assets/estimatics/pen-tool-02.svg',

		},
		[CardTypes.Dummy]: {
			title: 'Dummy',
			description: '',
			imageUrl: '',
		}
	}

	const { title, description, lastUpdated, imageUrl, centerImage } = properties[cardType];
	const [hover, setHover] = useState(false);
	return <div
		onMouseEnter={() => {
			setHover(true);
			Analytics.Instance.track('EstimaticsDocumentCardHover', { 'cardType': cardType });
		}}
		onMouseLeave={() => {
			setHover(false);
			Analytics.Instance.track('EstimaticsDocumentCardHoverOut', { 'cardType': cardType });
		}}
		onClick={() => {
			Analytics.Instance.track('EstimaticsDocumentCardClicked', { 'cardType': cardType });
			estimateStore.setCurrentView(cardType);
		}}
		className={`${cardType === CardTypes.Dummy ? 'opacity-0' : ''} hover:bg-sky-50 cursor-pointer  grow shrink basis-0 bg-white rounded-xl border border-gray-300 flex-col justify-start items-start inline-flex`}>
		<div className="w-full h-[132px] relative bg-gray-200 overflow-hidden rounded-t-xl relative inline-block"
			style={{
				backgroundImage: `url(${imageUrl})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat'
			}}
		>
			<div className='h-full w-full flex justify-center items-center'><img src={centerImage} className='w-16 h-16' /></div>
			{
				!hover &&
				<div className="absolute inset-0 bg-black opacity-20 hover:opacity-0 "></div>
			}
		</div>
		<div className="self-stretch p-6 flex-col justify-start items-start gap-4 flex ">
			<div className="self-stretch flex-col justify-start items-start gap-4 flex">
				<div className="text-gray-900 text-lg font-semibold font-['Hanken Grotesk'] leading-[25.20px]">
					{title}
				</div>
				<div className="self-stretch flex-col justify-start items-start gap-4 flex">
					<div className="self-stretch text-gray-500 text-sm font-normal font-['Hanken Grotesk'] leading-tight">
						{description}
					</div>
				</div>
			</div>
		</div>
	</div>
});

const EstimaticsProjectDocumentsPanel: React.FC<{}> = observer(({ }) => {
	return <div className="w-full  px-14 pt-16 flex-col justify-start items-center gap-8 inline-flex">
		<div className="w-full  bg-white rounded-xl shadow border border-gray-200 flex-col justify-start items-start flex">
			<EstimaticsProjectDocumentsTopBar />
			<div className="self-stretch h-full  p-12 flex-col justify-start items-start gap-6 flex">
				<div className="self-stretch  items-stretch justify-between items-start gap-6 inline-flex">
					<EstimaticsProjectDocumentCard cardType={CardTypes.TicSheet} />
					<EstimaticsProjectDocumentCard cardType={CardTypes.Photos} />
					<EstimaticsProjectDocumentCard cardType={CardTypes.OtherDocumentation} />
				</div>
				<div className="self-stretch  items-stretch justify-start items-start gap-6 inline-flex">
					<EstimaticsProjectDocumentCard cardType={CardTypes.Links} />
					<EstimaticsProjectDocumentCard cardType={CardTypes.InternalNotes} />
					<EstimaticsProjectDocumentCard cardType={CardTypes.Dummy} />
				</div>
			</div>
		</div>
	</div>

});


const EstimaticsProjectPage: React.FC<{}> = observer(({ }) => {
	const { estimateId } = useParams();
	const [showMenu, setShowMenu] = useState(false);
	const appStore = getAppStore();
	const userStore = getUserStore();
	const estimateStore = getEstimateStore();
	const currentProjectStore = getEstimaticsCurrentProjectStore();
	const estimatesCollectionRef = collection(useFirestore(), `/organizations/${userStore.user!.organizationId}/estimates`) as CollectionReference<IEstimaticsData>;
	const _estimates = useFirestoreCollectionData<IEstimaticsData>(estimatesCollectionRef, { idField: 'id' });

	const onBackgroundClick = (e: any) => {
		let element = e.target;
		while (element) {
			if (element.getAttribute && element.id === 'filter') {
				return;
			}
			element = element.parentElement;
		}
		setShowMenu(false);
	}
	useEffect(() => {
		document.removeEventListener('click', onBackgroundClick);
		document.addEventListener('click', onBackgroundClick);
		return () => {
			document.removeEventListener('click', onBackgroundClick);
		}
	}, []);

	const getCurrentView = () => {
		switch (estimateStore.currentView) {
			case CardTypes.Estimate:
				return <>
					<EstimaticsProjectTopPanel />
					<EstimaticsProjectDocumentsPanel />
				</>
			case CardTypes.Photos:
				return <EstimaticsImagesPanel />
			case CardTypes.TicSheet:
				return <EstimaticsTicSheetsPanel />
			case CardTypes.OtherDocumentation:
				return <EstimaticsOtherDocsPanel />
			case CardTypes.Links:
				return <EstimaticsLinksPanel />
			case CardTypes.InternalNotes:
				return <EstimaticsNotesPanel />
			case CardTypes.Dummy:
				return null;
		}
	}

	useEffect(() => {
		estimateStore.setCurrentView('Estimate');

	}, []);

	useEffect(() => {
		const estimates = _estimates.data?.filter((d) => !d.deleted);
		if (estimates && estimates.length > 0) {
			const projectEstimates = estimates.find((estimate) => estimate.estimateId === estimateId);
			if (projectEstimates) {
				appStore.setBreadcrumbPath([projectEstimates.projectName]);
				currentProjectStore.estimaticsData = projectEstimates;
			}
		} else {
			appStore.setBreadcrumbPath([estimateId!]);
		}

	}, [estimateId, _estimates]);

	// TODO :: loading animation
	if (_estimates.status === 'loading' || !currentProjectStore.estimaticsData.estimateId) {
		return <div>Loading...</div>;
	}
	return <>
		<div className='flex justify-between relative'>
			<LassoNavigationBar
				views={getEnumValues(CardTypes).slice(0, getEnumValues(CardTypes).length - 1)}
				supportedViews={getEnumValues(CardTypes)}
			/>
			<div
				id='filter'
				className='btn mr-12' onClick={() => {
					Analytics.Instance.track('ProjectPageMenuClicked', {
						showMenu: !showMenu
					})
					setShowMenu(v => !v)
				}}>
				<img src='/assets/ui/dots-vertical.svg' className='w-8 h-8  ' />
			</div>
			<div className='w-[100px] absolute top-[49px] right-10' style={{
				borderBottom: '1px solid #EAECF0'
			}}>

			</div>
			{
				showMenu &&
				<div className='absolute right-16 top-[30px]'>
					<div className="bg-white hover:bg-sky-50 rounded-lg shadow border border-gray-200 flex-col justify-start items-start flex">
						<div className="self-stretch  h-[50px] border-b border-gray-200 justify-start items-start flex px-3 btn "
							id='filter'
							onClick={async () => {
								setShowMenu(false);
								Analytics.Instance.track('DeleteEstimateClicked');
								setTimeout(async () => {
									const sure = window.confirm('Are you sure you want to delete this estimate?');
									if (sure) {
										Analytics.Instance.track('DeleteEstimateConfirmed');
										await currentProjectStore.deleteEstimate();
										appStore.setBreadcrumbPath([]);
									}
								}, 0);
							}}
						>
							<div className='px-2 py-4 flex'
							>
								{/* <img src='/assets/ui/user-01.svg' className="w-5 h-5  mr-2" /> */}
								<div className=" text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
									Delete estimate
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</div>

		{
			getCurrentView()
			// estimateStore.currentView === 'Estimate' &&
			// <>
			// 	<EstimaticsProjectTopPanel />
			// 	<EstimaticsProjectDocumentsPanel />
			// </>
		}
		{
			currentProjectStore.showLoadingAnimation && <LoadingAnimation />
		}
	</>
});


export default EstimaticsProjectPage;