export interface IHeatmapData {
	id: string,
	template: [string, string],
	azure: [string, string],
	region64: string,
	words: [{ word: string, confidence: number }[], { word: string, confidence: number }[]],
	selectedWords: string[]
}
export interface IExtractionValue {
	value: string,
	region64: string
}

export interface AzureLine {
	text: string;
	boundingBox: AzurePoint[];
	words: {
		text: string;
		boundingBox: AzurePoint[];
		confidence: number;
	}[]
}
export interface AzurePoint {
	x: number;
	y: number;
}

export interface AzureBlock {
	lines: AzureLine[]
}
export interface AzureVisionResult {
	blocks: AzureBlock[]
}

export interface TableData {
	text: string;
	image: string;
	azure: AzureVisionResult;
}

export interface TableLine {
	line: number;
	cells: TableData[]
}

export interface TableHeaders {
	address: TableData,
	dash: TableData,
	room: TableData,
	debris: TableData,
	interior: TableData,
	program: TableData,
}

export interface AllTableData {
	cells: TableLine[],
	headers: TableHeaders,
	fullImage: string
}

export interface AzureFormResult {
	apiVersion: string;
	modelId: string;
	content: string;
	pages: Page[];
	tables: Table[];
	keyValuePairs: KeyValuePair[];
	styles: Style[];
	paragraphs: Paragraph[];
	documents: any[];
}

export interface KeyValuePair {
	key: Paragraph;
	value?: Paragraph;
	confidence: number;
}

export interface Paragraph {
	content: string;
	boundingRegions: BoundingRegion[];
	spans: Span[];
}

export interface BoundingRegion {
	pageNumber: number;
	polygon: Polygon[];
}

export interface Polygon {
	x: number;
	y: number;
}

export interface Span {
	offset: number;
	length: number;
}

export interface Page {
	pageNumber: number;
	angle: number;
	width: number;
	height: number;
	unit: string;
	spans: Span[];
	words: SelectionMark[];
	selectionMarks: SelectionMark[];
	lines: Line[];
}

export interface Line {
	content: string;
	polygon: Polygon[];
	spans: Span[];
}

export interface SelectionMark {
	state?: string;
	polygon: Polygon[];
	span: Span;
	confidence: number;
	content?: string;
	originalContent?: string;
}

export interface Style {
	isHandwritten: boolean;
	spans: Span[];
	confidence: number;
}

export interface Table {
	rowCount: number;
	columnCount: number;
	cells: Cell[];
	boundingRegions: BoundingRegion[];
	spans: Span[];
}

export interface Cell {
	kind?: Kind;
	rowIndex: number;
	columnIndex: number;
	rowSpan: number;
	columnSpan: number;
	content: string;
	boundingRegions: BoundingRegion[];
	spans: Span[];
	selectedWords?: SelectionMark[];
	selectedRejectedWords?: SelectionMark[];
}


export enum Kind {
	ColumnHeader = "columnHeader",
	Content = "content",
}


export interface TemplateCell {
	content: string;
	rowIndex: number;
	columnIndex: number;
	cellRef: Cell;
}

// export interface TemplateCellTupleMetadata {
// 	isCategory?: boolean;
// 	id?: string;
// 	selectables?: string[];
// 	constraints?: string;
// 	unit?: string;
// }

export interface TemplateCellTuple {
	tupleIndex: number;
	cells: [TemplateCell, TemplateCell];
	meta?: TemplateCellTupleMetadata;
}
export interface TemplateRow {
	rowIndex: number;
	cellTuples: TemplateCellTuple[];
}

export interface TemplateHeaderRow {
	rowIndex: number;
	cells: TemplateCell[];
}

export interface TemplateMetaData {
	name: string;
	id: string;
	header: TemplateHeaderRow[];
	rows: TemplateRow[];
	azureOriginal?: AzureFormResult;
}


export namespace AzureRead {
	export interface AzureReadResults {
		apiVersion: Date;
		modelId: string;
		content: string;
		pages: Page[];
		styles: Style[];
		paragraphs: Paragraph[];
	}

	export interface Page {
		pageNumber: number;
		angle: number;
		width: number;
		height: number;
		unit: string;
		spans: Span[];
		words: Word[];
		lines: Line[];
	}

	export interface Word {
		content: string;
		polygon: Polygon[];
		span: Span;
		confidence: number;
		selected?: boolean;
	}

}

export interface MatchedRow {
	azureRow: Cell[];
	templateRow: TemplateRow;
}

export interface MatchedCell {
	azureCells: [Cell, Cell];
	templateTuple: TemplateCellTuple;
}

export interface AzureResults {
	docResult: AzureFormResult;
	readResult: AzureRead.AzureReadResults;
	pngFile?: string;
}


export interface IEmailJob {
	bodySerialized: string;
	status: string;
	processID: string;
	created: string;
	startProcessing: string;
}

export interface IEmailMetaData {
	estimator: string,
	insured: string,
	property_address: string,
	claim: string,
	home_address: string,
	phone: string,
	email: string
}



export interface IEmailJobBody {
	envelope?: string;
	sender_ip?: string;
	to?: string;
	from?: string;
	charsets?: string;
	SPF?: string;
	text?: string;
	html?: string;
	headers?: string;
	dkim?: string;
	subject?: string;
	"attachment-info"?: string;
	"content-ids"?: string;
	attachments?: string;
	processID?: string;
	estimateID?: string;
	files: AttachmentFile[];

}

export interface AttachmentFile {
	originalname: string;
	size: number;
	uploadId?: string;
	fileContent?: string;
	mimetype?: string;
	fieldname?: string;
	fileType?: string;
	uploadedSofar?: number;
	remotePath?: string;
	mime?: string;
	localPath?: string;
	deleted?: boolean;
	uploadDate?: string;
	uploadBy?: string;
	error?: string;
}


export interface IJobResults {
	pdfResults: {
		pages: LineType.Page[],
		fileName: string,
		pdfID: string
		filledTicSheetData?: IFilledTicSheetData[][]
	}[],
	files: AttachmentFile[],
	processID: string,
	emailMetaData: IEmailMetaData,
}
export interface IJobResultsFirebase {
	results: string;
	status: string;
	completed: string;
}

export interface IVerificationData {
	verified: boolean;
	verificationKey: string;
	pdfId: string;
	page: number;
	tupleId: string;
	extracted: IVerificationExtracted;
	results: IVerificationResults[];
}

export interface IVerificationExtracted {
	keyData: string;
	valueData: string;
	selectedData: string;
}

export interface IVerificationResults {
	finalText: string;
	quantity: string;
	remove: string;
	replace: string;
	costTax: string;
	costOnP: string;
	estimate: string;
}


export namespace LineType {
	export interface Header {
		address: {
			content: string;
			confidence: number;
		}[];
		dash: {
			content: string;
			confidence: number;
		}[];
		room: {
			content: string;
			confidence: number;
		}[];
		debris: {
			content: string;
			confidence: number;
			selected?: boolean;
		}[];
	}
	export interface Page {
		lineItems: Line[];
		header?: Header;
		pngFile?: string;
		freeTextLines?: string[];
	}
	export interface Line {
		lineItem: string[];
		matchCell?: MatchCell;
	}

	export interface MatchCell {
		azureCells: AzureCell[];
		templateTuple: TemplateTuple;
	}

	export interface AzureCell {
		kind: string;
		rowIndex: number;
		columnIndex: number;
		rowSpan: number;
		columnSpan: number;
		content: string;
		boundingRegions: BoundingRegion[];
		spans: Span[];
		selectedWords?: SelectionMark[];
	}

	export interface BoundingRegion {
		pageNumber: number;
		polygon: Polygon[];
	}

	export interface Polygon {
		x: number;
		y: number;
	}

	export interface Span {
		offset: number;
		length: number;
	}

	export interface TemplateTuple {
		cells: Cell[];
		meta: TemplateCellTupleMetadata;
		tupleIndex: number;
	}

	export interface Cell {
		cellRef: AzureCell;
		rowIndex: number;
		columnIndex: number;
		content: string;
	}

	// export interface Meta {
	// 	estimateMapping?: string;
	// 	keyConstraints: string;
	// 	valueAcceptedValues: string;
	// 	selectables: string[];
	// 	id: string;
	// 	valueConstraints: string;
	// 	// constraints: string;
	// 	extracted?: {
	// 		extractedKeyInput: {
	// 			acceptedTokens: string[],
	// 			rejectedTokens: string[],
	// 		},
	// 		extractedValueInput: {
	// 			acceptedTokens: string[],
	// 			rejectedTokens: string[],
	// 		},
	// 		extractedSelectedWords: {
	// 			acceptedTokens: string[],
	// 			rejectedTokens: string[],
	// 		}
	// 	}
	// }

}

export interface ITokenArrays {
	acceptedTokens: string[],
	rejectedTokens: string[],
}

export interface TemplateCellTupleMetadata {
	isCategory?: boolean;
	id?: string;
	selectables?: string[];
	keyConstraints?: string;
	valueConstraints?: string;
	keyAcceptedValues?: string;
	valueAcceptedValues?: string;
	unit?: string;
	estimateMapping?: string;
	quantityMapping?: string;
	priceMapping?: string;
	tax?: string;
	OnP?: string;
	extracted?: {
		extractedKeyInput: ITokenArrays,
		extractedValueInput: ITokenArrays,
		extractedSelectedWords: ITokenArrays
	},
	heatmapData?: IHeatmapData[];
	heatMapProcessedData?: {
		bgColor?: string;
		candidates?: string[];
		count?: number;
	}
}


export interface IEstimateResults {
	finalText: string,
	quantity?: {
		count: number,
		measurement: string
	},
	estimate?: number,
	replace?: number,
	remove?: number,
	tax?: number,
	OnP?: number,
	cost?: number,
	costTax?: number,
	costOnP?: number,
}

export interface IFilledTicSheetData {
	tuple: TemplateCellTuple,
	meta: TemplateCellTupleMetadata,
	selectedWords: SelectionMark[],
	azureCells: LineType.AzureCell[],
	rowIndex: number,
	columnIndex: number

}


//////////////////////////////


export const allowedUnits = ['sf', 'lf', 'ea', 'rl']

export enum constraintsTable {
	None = "None",
	Size_S_M_L_XL = "Size_S_M_L_XL",
	W_C_F_PF_PC_DR_RR_REM_R = "W_C_F_PF_PC_DR_RR_REM_R",
	Number = "Number",
	Yes_No = "Yes_No",
	FreeText = "FreeText",
	AcceptedValues = "AcceptedValues",
}


///////////////////////

interface RectWithMeta {
	x: number;
	y: number;
	width: number;
	height: number;
	meta: {
		ocrResult: any;
	}
}

interface Config {
	rectList: RectWithMeta[];
	templateProps: {
		offsetX?: number;
		offsetY?: number;
		imageWidth: number;
		imageHeight: number;
	},
	image: string;
}

export interface IEmailData {
	FromName: string;
	MessageStream: string;
	From: string;
	FromFull: Full;
	To: string;
	ToFull: Full[];
	Cc: string;
	CcFull: any[];
	Bcc: string;
	BccFull: any[];
	OriginalRecipient: string;
	Subject: string;
	MessageID: string;
	ReplyTo: string;
	MailboxHash: string;
	Date: string;
	TextBody: string;
	HtmlBody: string;
	StrippedTextReply: string;
	Tag: string;
	Headers: Header[];
	Attachments: Attachment[];
	processID?: string;
	estimateID?: string;
}

export interface Attachment {
	Content: string;
	ContentLength: number;
	Name: string;
	ContentType: string;
	ContentID: string;
}

export interface Full {
	Email: string;
	Name: string;
	MailboxHash: string;
}

export interface Header {
	Name: string;
	Value: string;
}

export const template_X_values = [
	'xl', 'xlarge', 'x-large', 'extra large', 'extra-large',
	'large', 'l', 'lg',
	'medium', 'm', 'med',
	'small', 's', 'sm',
	'xsmall', 'x-small', 'xs', 'extra small', 'extra-small'
];

export const X_values_map: { [s: string]: string } = {
	'xl': 'Extra large',
	'xlarge': 'Extra large',
	'x-large': 'Extra large',
	'extra large': 'Extra large',
	'extra-large': 'Extra large',
	'large': 'Large',
	'l': 'Large',
	'lg': 'Large',
	'medium': 'Medium',
	'm': 'Medium',
	'med': 'Medium',
	'small': 'Small',
	's': 'Small',
	'sm': 'Small',
	'xsmall': 'Extra small',
	'x-small': 'Extra small',
	'xs': 'Extra small',
	'extra small': 'Extrasmall',
	'extra-small': 'Extra small'
};