class AppFeatures {
	public get isEstimatics(): boolean {
		return true;
	}
	public isDev(): boolean {
		return location.href.includes('localhost');
	}
	public isDebug(includeDevServer: boolean = true): boolean {
		let _location = { href: '', host: '' }; // when running on server
		if (typeof location !== undefined)
			_location = location;
		if (_location.href.includes('production')) {
			return false;
		}
		return (includeDevServer && _location.host.includes('-dev')) || _location.href.includes('localhost') || _location.href.includes('192.168.') || _location.href.includes('127.') || _location.href.includes('10.') || _location.href.includes('view=debug');
	}
}
const appFeatures = new AppFeatures();
export { appFeatures };