import { observer } from 'mobx-react';
import getMappingToolStore, { AIMatchings, IAIMatch, VariableMapping, createLineItemFromMappingAndInput } from '../data/MappingToolStore';
import MappingUtils, { getMappingStore } from '../../../../utils/CreatorTools/MappingUtils';
import React, { useEffect, useState } from 'react';
import { IDatabaseLineItem } from '../../LineItemDBCreationToolPage';
import { logDebug } from '../../../../shared/logger';
import SharedUtils from '../../../../shared/SharedUtils';
import TopMostPortal from '../../../../components/ui/utils/Portals';
import { wizardTitles } from './GenericWizardLeftBar';
import { IWizardStepProps } from './GenericWizardRightBar';


const TicSheetErrorText = 'Mark as tic sheet error';
const CreateNewVariableText = 'Add new variable';
const LineItemMapping: React.FC<{ lineItem: IDatabaseLineItem, mappingIndex: number }> = observer(({ lineItem }) => {
	// const [variablesMapping, setVariablesMapping] = useState<VariableMapping[]>([]);
	const [addTicSheetInput, setAddTicSheetInput] = useState('');
	const [showAddTicSheetInputWindow, setShowAddTicSheetInputWindow] = useState(false);
	const [showHumanImagePreviewIndex, setShowHumanImagePreviewIndex] = useState(-1);
	const [testInput, setTestInput] = useState('');
	const [testOutput, setTestOutput] = useState('');
	const mappingToolStore = getMappingToolStore();
	const mappingStore = getMappingStore();
	const selectedTupple = mappingStore.templateData!.rows[mappingToolStore.selectedTuple[0]].cellTuples[mappingToolStore.selectedTuple[1]]!;
	const allVariables = [...lineItem.elements.flatMap(e => e.type === 'variable' ? e.acceptableValues! : []), 'not available', TicSheetErrorText, CreateNewVariableText];


	useEffect(() => {
		// if (mappingToolStore.getVariablesMapping(lineItem.id).length > 0) return;
		const aiMatching: AIMatchings = mappingToolStore.getAIMatchings(lineItem.id);
		logDebug('aiMatching', aiMatching);
		const ticSheetInput = Object.keys(aiMatching);
		const mapping: VariableMapping[] = [...mappingToolStore.getVariablesMapping(lineItem.id)];
		ticSheetInput.forEach((input) => {
			if (mapping.find(m => m.ticInput.toLowerCase().trim() === input.toLowerCase().trim())) return;
			let dotColor = '';
			const deleted = false;
			const acceppted = false;
			const aiMatch = aiMatching[input]!;
			const aiVariable = allVariables.find(variable => variable.toLowerCase() === aiMatch.variable.toLowerCase()) || 'not available';
			if (aiVariable.toLowerCase() === 'not available') {
				dotColor = 'bg-red-500';
			} else {
				if (aiMatch.confidence === 'confident') {
					dotColor = 'bg-emerald-500';
				} else {
					dotColor = 'bg-yellow-400';
				}
			}
			mapping.push({ ticInput: input, aiMatch, variableValue: aiVariable, accepted: acceppted, deleted, dotColor });

		});
		// logDebug('mapping', mapping);
		mappingToolStore.setVariablesMapping(lineItem.id, mapping);

	}, [mappingToolStore.aiMatchings]);


	useEffect(() => {
		const output = createLineItemFromMappingAndInput(lineItem, mappingToolStore.getVariablesMapping(lineItem.id), testInput);
		setTestOutput(output);
	}, [testInput]);



	return <div className=" w-[730px] bg-white rounded-2xl shadow border border-gray-200 flex-col justify-start items-start inline-flex">
		<div className="self-stretch px-5 pt-6 pb-8 border-b border-gray-200 justify-between items-center inline-flex">
			<div className="flex-col justify-start items-start gap-1 inline-flex">
				<div className="justify-start items-start gap-1 inline-flex">
					<div className="text-gray-500 text-sm font-normal font-['Hanken Grotesk'] leading-tight">{lineItem.category || 'Other'}</div>
				</div>
				<div className="justify-start items-center gap-1 inline-flex">
					<div className="text-justify text-gray-900 text-xl font-medium font-['Hanken Grotesk'] leading-7 ">{selectedTupple.cells[0].content}</div>
				</div>
				<div>
					<div className="pr-2 text-justify text-gray-900 text-xl font-normal font-['Hanken Grotesk'] leading-7">{lineItem.originalLineItem}</div>
				</div>
			</div>
			<div className="justify-end items-center gap-5 flex w-64">
				<div className=" justify-end items-center gap-4 flex">
					<div className="px-3.5 py-2 bg-sky-50 rounded-lg border border-sky-50 justify-center items-center gap-2 flex relative">
						<div className="justify-start items-center gap-2 flex cursor-pointer " onClick={() => {
							setShowAddTicSheetInputWindow(true)
						}}>
							<img src='assets/ui/plus-circle.svg' className="w-5 h-5 relative" />
							<div className="text-blue-700 text-sm font-semibold font-['Hanken Grotesk'] leading-tight whitespace-nowrap">Add tic sheet input</div>
						</div>
						{
							showAddTicSheetInputWindow && <div className='w-64 h-62 shadow rounded rounded-2xl absolute bg-white top-10 right-0 z-10'>
								<div className="w-full  flex-col justify-start items-start inline-flex">
									<div className="self-stretch  p-4 flex-col justify-start items-start gap-3 flex">
										<div className="self-stretch text-gray-500 text-xs font-normal font-['Hanken Grotesk'] leading-none">Input items separated by new line:</div>
										<div className="self-stretch h-[140px] flex-col justify-start items-start gap-0.5 flex">
											<div className="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex">
												<div className="self-stretch grow shrink basis-0 px-1.5 py-1.5 bg-white rounded-lg shadow border border-sky-300 justify-start items-start gap-2 inline-flex relative">
													<textarea
														value={addTicSheetInput}
														onChange={(e) => setAddTicSheetInput(e.target.value)}
														className="self-stretch grow shrink focus:outline-none ">
													</textarea>
												</div>
											</div>
										</div>
									</div>
									<div className="self-stretch justify-between items-start inline-flex">
										<div className="grow shrink basis-0 px-4 py-4 border-t border-gray-200 justify-between items-start flex">
											<div className="justify-center items-center  flex">
												<div className="justify-start items-center  flex cursor-pointer" onClick={() => setShowAddTicSheetInputWindow(false)} >
													<div className="text-slate-600 text-xs font-medium font-['Hanken Grotesk'] leading-[18px]">Cancel</div>
												</div>
											</div>
											<div className="justify-center items-center  flex">
												<div className="justify-start items-center  flex cursor-pointer" onClick={() => {
													setShowAddTicSheetInputWindow(false);
													const aiRet: any = {};
													addTicSheetInput.split('\n').forEach((input) => {
														aiRet[input] =
															{ variable: 'not available', confidence: 'not confident' };
													});
													mappingToolStore.setAIMatchings(lineItem.id, aiRet, true);
												}} >
													<div className="text-blue-900 text-sm font-semibold font-['Hanken Grotesk'] leading-tight">No AI</div>
												</div>
											</div>
											<div className="justify-center items-center  flex">
												<div className="justify-start items-center  flex cursor-pointer" onClick={async () => {
													const inputArray = addTicSheetInput.split('\n');
													setAddTicSheetInput('')
													setShowAddTicSheetInputWindow(false)
													try {
														mappingStore.loadingData = true;
														const allVariables = lineItem.elements.flatMap(e => e.type === 'variable' ? e.acceptableValues! : []);
														const aiRet = await MappingUtils.linkVariablesUsingOpenAI(allVariables, inputArray);
														mappingToolStore.setAIMatchings(lineItem.id, aiRet, true);
														mappingStore.loadingData = false;
													} catch (err) {

													}
												}}>
													<div className="text-blue-900 text-sm font-semibold font-['Hanken Grotesk'] leading-tight">Confirm</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
		<div className="self-stretch bg-gray-50 border-b border-gray-200 justify-between items-center inline-flex">
			<div className="h-11 w-full justify-start items-center flex">
				<div className="w-48 h-11 px-8 py-3 bg-gray-50 border-b border-gray-200 justify-start items-center gap-1 flex">
					<div className="text-gray-900 text-sm font-semibold font-['Hanken Grotesk'] leading-tight">Tic sheet input</div>
				</div>
				<div className="justify-center shrink grow  h-11 pr-14 py-3 bg-gray-50 border-b border-gray-200 justify-start items-center gap-1 flex">
					<div className="text-gray-900 text-sm font-semibold font-['Hanken Grotesk'] leading-tight">Matching variables</div>
				</div>
			</div>
			<div className="h-10 px-8 opacity-0 justify-start items-start gap-4 flex">
				<div className="p-2.5 bg-red-600 rounded-lg shadow border border-red-600 justify-center items-center gap-2 flex">
					<div className="w-5 h-5 px-[2.50px] py-[1.67px] justify-center items-center flex" />
				</div>
			</div>
		</div>
		{
			mappingToolStore.getVariablesMapping(lineItem.id).map((mapping, index) => {
				return mapping.deleted ? null : <div key={index} className="self-stretch py-2 bg-white border-l border-r border-b border-gray-200 justify-between items-center inline-flex">

					<div className="w-[248px] self-stretch px-4 justify-start items-center gap-4 flex">
						{
							(selectedTupple.meta?.heatMapProcessedData?.candidates?.findIndex((s) => s === mapping.ticInput) ?? -1) >= 0 ?
								<img src='assets/ui/image-box.svg' className="w-5 h-5 relative cursor-pointer" onClick={() => {
									setShowHumanImagePreviewIndex(index);

								}} />
								:
								<div className="w-5 h-5 relative" />
						}
						{/* TODO :: move to own component and use input insead of contentEditable*/}
						<div contentEditable={true} className="w-32 focus:outline-none focus:border p-1 text-gray-900 text-sm font-normal font-['Hanken Grotesk'] leading-tight"
							onBlur={(e) => {
								const value = e.currentTarget.textContent || '';
								const newMapping = [...mappingToolStore.getVariablesMapping(lineItem.id)];
								if (newMapping[index].ticInput !== value) {
									newMapping[index].ticInput = value;
									mappingToolStore.setVariablesMapping(lineItem.id, newMapping);
								}

							}}
						>
							{mapping.ticInput}
						</div>
					</div>
					<div className="grow shrink basis-0 self-stretch px-[18px] py-4 justify-start items-center gap-2 flex">
						{/* <div className="cursor-pointer" onClick={() => {
							const newMapping = [...mappingToolStore.getVariablesMapping(lineItem.id)];
							newMapping.splice(index, 0, {
								ticInput: mapping.ticInput,
								variableValue: 'not available',
								accepted: false,
								deleted: false,
								dotColor: '',
								aiMatch: { variable: 'not available', confidence: 'not confident' }
							});
							mappingToolStore.setVariablesMapping(lineItem.id, newMapping);
						}}>
							<img src='assets/ui/plus-circle.svg' className="w-5 h-5 relative " />
						</div> */}
						<div className="grow shrink basis-0 flex-row justify-start items-start gap-0.5 inline-flex">
							<div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
								<div className="text-xs relative self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
									<select
										onChange={(e) => {
											const value = e.target.value;
											const newMapping = [...mappingToolStore.getVariablesMapping(lineItem.id)];
											newMapping[index].variableValue = value;
											newMapping[index].dotColor = value === TicSheetErrorText ? 'bg-red-500' : '';
											newMapping[index].accepted = true;
											mappingToolStore.setVariablesMapping(lineItem.id, newMapping);
										}}
										value={mapping.variableValue}
										className='w-full focus:outline-none focus:border-none pl-2'
									>
										{
											allVariables.map((variable, index) => {
												return <option
													className={variable === TicSheetErrorText ? 'text-red-500' : (variable === CreateNewVariableText ? 'text-blue-500' : '')}
													key={index}
													value={variable}
												>
													{variable}
												</option>
											})
										}
									</select>
									{
										mapping.dotColor &&
										<div className='absolute top-[15.5px] left-2 text-xs'>
											<div className="w-2.5 h-2.5 relative">
												<div className={`w-2 h-2 left-[1px] top-[1px] absolute rounded-full ${mapping.dotColor}`} />
											</div>
										</div>
									}

								</div>
							</div>
							<div className="relative text-xs w-40 px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
								<select
									onChange={(e) => {
										const value = e.target.value;
										const newMapping = [...mappingToolStore.getVariablesMapping(lineItem.id)];
										newMapping[index].measurement = value;
										mappingToolStore.setVariablesMapping(lineItem.id, newMapping);
									}}

									value={mapping.measurement || mappingToolStore.measurements[0]}
									className='w-32 px-1  focus:outline-none focus:border-none'>
									{
										mappingToolStore.measurements.map((measurement, index) => {
											return <option key={index}>{measurement}</option>
										})
									}
								</select>
							</div>

						</div>
					</div>
					<div className="h-6  pr-2 justify-end items-center  flex">
						<div className="justify-start items-center gap-4 flex w-16">
							{
								mapping.accepted ? <img src='/assets/ui/accepted.svg'></img> :
									<div className="justify-center items-center gap-2 flex cursor-pointer" onClick={() => {
										const newMapping = [...mappingToolStore.getVariablesMapping(lineItem.id)];
										newMapping[index].accepted = true;
										newMapping[index].dotColor = '';
										mappingToolStore.setVariablesMapping(lineItem.id, newMapping);
									}}>
										<div className="text-blue-700 text-sm font-semibold font-['Hanken Grotesk'] leading-tight">Accept</div>
									</div>
							}

						</div>
						<div className="w-5 h-5 mr-4 justify-center items-center flex cursor-pointer" onClick={() => {
							const newMapping = [...mappingToolStore.getVariablesMapping(lineItem.id)];
							newMapping[index].deleted = true;
							newMapping[index].accepted = true;
							mappingToolStore.setVariablesMapping(lineItem.id, newMapping);
						}}>
							<img src='assets/ui/x-close.svg' className="w-5 h-5 relative" />
						</div>
					</div>
				</div>

			})
		}
		<div className='p-2 flex'>
			Test : <input
				onChange={(e) => setTestInput(e.target.value)}
				value={testInput}
				className='border border-gray-200' />
			<div>
				Output : {testOutput}
			</div>
		</div>
		{
			showHumanImagePreviewIndex >= 0 && <TopMostPortal>
				<div className='flex flex-col'>
					{
						mappingStore.getImageForCandidate(selectedTupple, mappingToolStore.getVariablesMapping(lineItem.id)[showHumanImagePreviewIndex].ticInput).map((image, index) => {
							return <img src={image} key={index}
								className="h-8 relative cursor-pointer my-1"
								onClick={() => {
									setShowHumanImagePreviewIndex(-1);
								}}
							/>
						})
					}
				</div>
			</TopMostPortal>
		}
	</div>
});


const MappingWizardStep2: React.FC<IWizardStepProps> = observer(({
	onCancel,
	onNext,
	onBack
}) => {
	const mappingToolStore = getMappingToolStore();
	const mappingStore = getMappingStore();

	const acceptMapping = async () => {
		mappingToolStore.saveCurrentMapping('new');
	}
	return <>
		<div className="self-stretch h-full flex-col justify-start items-start gap-8 flex">
			<div className="self-stretch justify-start items-start gap-2.5 inline-flex">
				<div className="grow shrink basis-0 text-gray-900 text-lg font-normal font-['Hanken Grotesk'] leading-7">{wizardTitles[mappingToolStore.wizardStep - 1]}</div>
			</div>
			<div className='flex flex-col w-full overflow-y-scroll'>
				{
					mappingToolStore.selectedLineItems.map((lineItem, index) => {
						return <div className='mb-8' key={lineItem.id}>
							<LineItemMapping key={lineItem.id} lineItem={lineItem} mappingIndex={index} />
						</div>
					})
				}
			</div>

			<div className="w-full p-6 bg-white border-t border-gray-200 justify-between items-start inline-flex">
				<div
					className="px-[18px] py-2.5 bg-white rounded-lg border border-gray-200 justify-center items-center gap-2 flex cursor-pointer select-none"
					onClick={() => {
						onBack();
					}}
				>
					<div className="justify-start items-center gap-2 flex ">
						<div className="text-slate-700 text-base font-semibold font-['Hanken Grotesk'] leading-normal">Back</div>
					</div>
				</div>
				<div className="justify-end items-center gap-14 flex">
					<div
						className="h-11 px-[18px] py-2.5 bg-blue-900 rounded-lg shadow border border-blue-900 justify-center items-center gap-2 flex cursor-pointer select-none"
						onClick={() => {
							acceptMapping();
							onNext(3);
							// analyzeMatch(selectedTupple, selectedLineItem);
						}}
					>
						<div className="justify-start items-center gap-2 flex">
							<div className="text-white text-base font-semibold font-['Hanken Grotesk'] leading-normal ">Accept Mapping</div>
						</div>
					</div>
				</div >
			</div >
		</div>

	</>
});

export default MappingWizardStep2;