import { observer } from 'mobx-react';
import { getEstimateStore } from '../../data/DataStores';
import { useRef } from 'react';
// import './LassoTopBar.scss';
import './LassoTopBar_Estimatics.scss';
import Analytics from '../../utils/Analytics';

const LassoNavigationBar: React.FC<{ views?: string[], supportedViews?: string[] }> = observer(({ views, supportedViews }) => {
	const store = useRef(getEstimateStore());
	views = views || ['Estimate', 'Photos', 'Scope Note', 'Tic Sheet', 'Floor Plan', '3D View'];
	supportedViews = supportedViews || [
		'Estimate',
		'Photos',
		// 'Scope Note',
		'Tic Sheet',
		// 'Floor Plan', '3D View'
	];
	function setCurrentView(view: string) {
		if (supportedViews!.includes(view)) {
			Analytics.Instance.track('EstimaticsDocumentationTabClicked', { tab: view });
			store.current.setCurrentView(view);
		}

	}
	return <div className='flex navigation min-w-[800px]'>
		{
			views.map((view, index) => {
				return <div key={index} className={
					` no-break navigation-cell ${store.current.currentView === view ? 'selected' : ''} ${supportedViews!.includes(view) && view !== store.current.currentView ? 'cursor-pointer' : ''} `} onClick={() => setCurrentView(view)}>{view}</div>
			})
		}
	</div>
});
export default LassoNavigationBar;