import React, { CSSProperties } from 'react';

import Lottie from 'react-lottie';

const LottieAnimation: React.FC<{ w: number, h: number, style?: CSSProperties, className?: string, animationData: any }> = ({ style, className, w, h, animationData }) => {

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};
	return <div className={className} style={{
		...(style || {})
	}}>
		<Lottie options={defaultOptions}
			height={h}
			width={w}
		//   isStopped={this.state.isStopped}
		// isPaused={this.state.isPaused}
		/>
	</div>
}

export default LottieAnimation;