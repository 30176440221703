import React, { useEffect } from 'react';
import { logDebug } from '../../../shared/logger';

const AddressCompletion: React.FC<{
	className?: string,
	placeholder: string,
	value: string,
	onChange: (v: string) => void,
	onComplete?: (v: string) => void
}> = ({ onComplete, placeholder, value, onChange, className }) => {
	const inputRef = React.useRef<HTMLInputElement>(null);
	const autoCompleteRef = React.useRef<any>(null);
	useEffect(() => {
		if (autoCompleteRef.current) {
			return;
		}
		//@ts-ignore
		autoCompleteRef.current = new google.maps.places.Autocomplete(inputRef.current, { types: ['geocode'] });
		//@ts-ignore
		google.maps.event.addListener(autoCompleteRef.current, 'place_changed', onPlaceChanged);
	}, []);

	function onPlaceChanged(e: any) {
		const place = autoCompleteRef.current.getPlace();
		onChange(place.formatted_address);
		onComplete && onComplete(place.formatted_address);
		// // Extract the selected address components
		// const addressComponents = place.address_components;
		// for (let i = 0; i < addressComponents.length; i++) {
		// 	const component = addressComponents[i];
		// 	for (let j = 0; j < component.types.length; j++) {
		// 		const type = component.types[j];
		// 		const typeElement = document.getElementById(type);
		// 		if (typeElement) {
		// 			typeElement.value = component.long_name;
		// 		}
		// 	}
		// }
	}

	return <input
		ref={inputRef}
		value={value}
		onChange={(e) => onChange(e.target.value)}
		className={className || ''} type="text" placeholder={placeholder}
	/>;

}

export default AddressCompletion;