import html2canvas from 'html2canvas';
import { getEstimateStore } from '../data/DataStores';
import { logDebug, logError, logWarning } from '../shared/logger';
import jsPDF from 'jspdf';




interface StringRecord {
	id?: number;
	content: string;
}


export class LocalLargeStorage {
	dbName: string;
	version: number;
	constructor(dbName: string = 'myStringDB2', version: number = 1) {
		this.dbName = dbName;
		this.version = version;
	}
	initDB(): Promise<IDBDatabase> {
		return new Promise((resolve, reject) => {
			const request = indexedDB.open(this.dbName, this.version);

			request.onerror = (event) => {
				logError('Database error:', (event.target as IDBRequest).error);
				reject((event.target as IDBRequest).error);
			};

			request.onsuccess = (event) => {
				// logDebug('Database opened successfully');
				resolve((event.target as IDBRequest).result);
			};

			request.onupgradeneeded = (event) => {
				const db = (event.target as IDBRequest).result;
				db.createObjectStore('strings');
			};
		});
	};

	async setItem(key: string, content: string): Promise<void> {
		try {
			const db = await this.initDB();
			const transaction = db.transaction(['strings'], 'readwrite');
			const objectStore = transaction.objectStore('strings');
			const stringRecord: StringRecord = { content };

			const request = objectStore.put(stringRecord, key);

			await new Promise<void>((resolve, reject) => {
				request.onsuccess = () => {
					// logDebug('String added to the database successfully');
					resolve();
				};

				request.onerror = (event) => {
					logError('Failed to add string:', (event.target as IDBRequest).error);
					reject((event.target as IDBRequest).error);
				};
			});
		} catch (error) {
			logError('Error adding string to DB:', error);
		}
	};

	async getItem(key: string): Promise<string> {
		try {
			const db = await this.initDB();
			const transaction = db.transaction(['strings'], 'readonly');
			const objectStore = transaction.objectStore('strings');
			const request = objectStore.get(key);
			const ret = await new Promise<string>((resolve, reject) => {
				request.onsuccess = (event) => {
					const stringRecord = (event.target as IDBRequest).result as StringRecord;
					if (stringRecord) {
						resolve(stringRecord.content);
					} else {
						// logWarning('No string found with the given id');
						resolve('');
					}
				};

				request.onerror = (event) => {
					logError('Failed to retrieve string:', (event.target as IDBRequest).error);
					reject((event.target as IDBRequest).error);
				};
			});
			return ret;
		} catch (error) {
			logError('Error retrieving string from DB:', error);
		}
		return '';
	};

	getItemSynced(key: string): string | undefined {
		logWarning('call this function on your own risk, it will block the main thread until the promise is resolved');
		let result: string | undefined;
		let error: any;

		const promise = this.getItem(key)
			.then((data) => {
				result = data;
			})
			.catch((err) => {
				error = err;
			});

		const loop = setInterval(() => {
			if (result !== undefined || error !== undefined) {
				clearInterval(loop);
			}
		}, 10);

		while (result === undefined && error === undefined) {
			// Busy-wait loop to block execution until the promise is resolved
		}

		if (error) {
			throw error;
		}

		return result;
	}



}

export const localLargeStorage = new LocalLargeStorage();

export default class PrivateUtils {

	static exportPDFElements(element: HTMLElement[], _props?: {
		outname?: string,
		orientation?: 'landscape' | 'portrait',
	}) {
		setTimeout(async () => {
			const canvases: HTMLCanvasElement[] = await Promise.all(element.map(async (element) => {
				const canvas = await html2canvas(element);
				return canvas;
			}));
			const props = {
				orientation: _props?.orientation || 'landscape',
				unit: 'px',
				format: [canvases.reduce((acc, canvas) => acc + canvas.height, 0) + 200, canvases.reduce((acc, canvas) => canvas.width > acc ? canvas.width : acc, 0) + 200]
			}
			logDebug('pdf props', props);
			const pdf = new jsPDF(props as any);
			let accuHeight = 0;
			canvases.forEach((canvas, index) => {
				const imgData = canvas.toDataURL('image/png');
				// logDebug(index, 'imgData', imgData)
				logDebug(`adding to canva ${index}`, accuHeight, canvas.width, canvas.height);
				pdf.addImage(imgData, 'PNG', 100, accuHeight, canvas.width, canvas.height);
				accuHeight += canvas.height;
			});

			pdf.save(`${_props?.outname || (Date.now())}.pdf`);
		}, 30);
	};


	static exportPDF() {
		getEstimateStore().setCreatingPDF(true);
		setTimeout(async () => {
			const canvases: HTMLCanvasElement[] = [];
			const topEstimatePanel = document.getElementById('topEstimatePanel');
			const canvas = await html2canvas(topEstimatePanel!);
			canvases.push(canvas);
			for (let i = 0; i < getEstimateStore().roomNames.length; i++) {
				const element = document.getElementById(`scrollPage-${i}`);
				const canvas = await html2canvas(element!);
				canvases.push(canvas);
			}

			// const pdfWidth = 210; // Width of A4 in mm
			const props = {
				orientation: 'landscape',
				unit: 'px',
				format: [canvases.reduce((acc, canvas) => acc + canvas.height, 0) + 200, canvas.width + 200]
			}
			logDebug('pdf props', props);
			const pdf = new jsPDF(props as any);
			let accuHeight = 0;
			canvases.forEach((canvas, index) => {
				const imgData = canvas.toDataURL('image/png');
				// logDebug(index, 'imgData', imgData)
				logDebug(`adding to canva ${index}`, accuHeight, canvas.width, canvas.height);
				pdf.addImage(imgData, 'PNG', 100, accuHeight, canvas.width, canvas.height);
				accuHeight += canvas.height;
			});

			pdf.save(`${getEstimateStore().insuredLast || getEstimateStore().insured} Estimate.pdf`);
			getEstimateStore().setCreatingPDF(false);
			// // const inputElement = document.getElementById('content-to-export'); // Adjust the ID to match your content's container
			// 	html2canvas(pdfDivRef.current!).then((canvas: any) => {
			// 		const imgData = canvas.toDataURL('image/png');
			// 		const canvasAspectRatio = canvas.width / canvas.height;
			// 		const pdfWidth = 210; // Width of A4 in mm
			// 		const pdfHeight = pdfWidth / canvasAspectRatio; // Calculate height based on the canvas aspect ratio

			// 		const pdf = new jsPDF({
			// 			orientation: canvas.width > canvas.height ? 'landscape' : 'portrait',
			// 			unit: 'mm',
			// 			format: [pdfWidth, pdfHeight * 2]
			// 		});

			// 		// Add the canvas image to the PDF with the same aspect ratio
			// 		pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
			// 		pdf.addImage(imgData, 'PNG', 0, pdfHeight, pdfWidth, pdfHeight);
			// 		pdf.save('download.pdf');
			// 		estimateStore.current.setCreatingPDF(false);
			// 	});
		}, 30);
	};

}
