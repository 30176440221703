import React, { useEffect, useRef, useState } from 'react';
import { LassoSimpleButtonFull } from '../../components/ui/utils/LassoButtons';
import { getAppStore, getUserStore } from '../../data/DataStores';
import TopMostPortal from '../../components/ui/utils/Portals';
import LassoMVPUserSettingsModal from '../components/LassoMVPUserSettingsModal';
import { useNavigate } from 'react-router-dom';
import RestAPI from '../../utils/restAPI';
import { logDebug, logError } from '../../shared/logger';
import { IOrganizationObject } from '../../shared/interfaces';
import { observer } from 'mobx-react';
import LoadingAnimation from '../../components/ui/utils/LoadingAnimation';
import SecureImage from '../../components/ui/utils/SecureImage';

const OrganizationTicket: React.FC<{ organization: IOrganizationObject }> = ({ organization }) => {
	const navigate = useNavigate();
	// organization.organizationLogo = "https://www.propertyrestoration.com/media/1075/logo.png";
	return <div className="w-[437px] h-[309px] bg-white rounded-xl border border-gray-300 flex-col justify-start items-start inline-flex">
		<div className="self-stretch h-[132px] py-8 bg-gray-200 flex-col justify-center items-center flex">
			<img className="w-[508px] h-48 opacity-60 mix-blend-soft-light" src="/assets/ui/orgbg.png" />
			<div className="w-[434px] h-[104px] justify-center items-center inline-flex absolute">
				<SecureImage
					onUrlChange={(url) => organization.organizationLogo = url}
					// destination={`users/${userStore.current.user?.uid}/private`}
					w={180} h={104}
					defaultImage={organization.organizationLogo ? `${RestAPI.getStorageFileUrl(organization.organizationLogo)}` : "/assets/ui/org-placeholder.png"} />

			</div>
		</div>
		<div className="self-stretch h-[177px] p-6 flex-col justify-start items-start gap-4 flex">
			<div className="self-stretch h-[73px] flex-col justify-start items-start gap-2 flex">
				<div className="text-gray-900 text-lg font-semibold font-['Hanken Grotesk'] leading-[25.20px]">{organization.organizationName}</div>
				<div className="self-stretch text-gray-400 text-sm font-normal font-['Hanken Grotesk'] leading-tight">{organization.organizationDescription}</div>
			</div>
			<div className="self-stretch justify-start items-center gap-2 inline-flex">
				<div className="w-6 h-6 pl-[2.14px] pr-[1.86px] py-0.5 justify-center items-center flex">
					<div className="w-5 h-5 relative">
					</div>
				</div>
				<div className="flex-col justify-start items-start gap-0.5 inline-flex">
					<div className="text-slate-700 text-base font-medium font-['Hanken Grotesk'] leading-normal">{organization.organizationAddress}</div>
				</div>
			</div>
		</div>
	</div>
	// navigate(`/organizations/${organization.organizationId}`)
}


const LassoMVPCreatorPage: React.FC = observer(() => {
	const userStore = useRef(getUserStore());
	const [search, setSearch] = useState<string>('');
	const navigate = useNavigate();
	const appStore = getAppStore();
	const [fetching, setFetching] = useState<boolean>(false);
	const loadOrganizations = async () => {
		setFetching(true);
		await appStore.loadOrganizations();
		setFetching(false);
	}
	useEffect(() => {
		loadOrganizations();
	}, []);

	return <>
		<div className="w-full h-[212px] flex-col justify-start items-start gap-4 inline-flex">

			<div className="self-stretch h-[72px] px-8 justify-start items-center gap-6 inline-flex">
				<div className="grow shrink basis-0 h-11 justify-start items-center gap-4 flex">
					<div className="w-[188px] flex-col justify-start items-start gap-2 inline-flex">
						<div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
							<div className="self-stretch py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
								<div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
									<div className="w-1 h-5 relative" />
									<input
										onChange={(e) => { setSearch(e.target.value) }}
										className="text-gray-500 w-40 text-base font-normal font-['Hanken Grotesk'] leading-normal border-0 focus:outline-none"
										placeholder='Search'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="justify-end items-center gap-4 flex">
					<LassoSimpleButtonFull caption="New organization" onClick={() => {
						navigate('/organizations/new')
					}} />
				</div>
			</div>
			<div className="self-stretch px-8 justify-between items-center inline-flex">
				<div className="text-slate-600 text-xs font-medium font-['Hanken Grotesk'] leading-[14.40px]">{appStore.organizations.length} organizations shown</div>
				<div className="flex-col justify-start items-start inline-flex">
					<div className="pl-2 pr-1 py-2 bg-white rounded-lg border border-gray-200 justify-center items-center gap-2 inline-flex">
						<div className="text-slate-700 text-xs font-medium font-['Hanken Grotesk'] leading-[14.40px]">Sort Alphabetically A-Z</div>
						<div className="w-5 h-5 relative" />
					</div>
				</div>
			</div>
		</div>

		{
			appStore.organizations.length === 0 &&
			<div className="w-[100vw] h-full flex-col justify-center items-center gap-[23px] inline-flex">
				<img className="w-[149px] h-24" src="/assets/ui/noOrg.svg" />
				<div className="text-black text-xl font-semibold font-['Hanken Grotesk'] leading-7">No organizations to show here</div>
				<div className="text-black text-xs font-normal font-['Hanken Grotesk'] leading-none">Click on the button below to add your first organization</div>
			</div>
		}
		{
			appStore.organizations.length > 0 &&
			<div className="w-[100vw] h-full flex-col justify-center items-center gap-[23px] inline-flex">
				{/* <img className="w-[149px] h-24" src="/assets/ui/creator.png" /> */}
				{/* <div className="text-black text-xl font-semibold font-['Hanken Grotesk'] leading-7">Your organizations</div>
				<div className="text-black text-xs font-normal font-['Hanken Grotesk'] leading-none">Click on an organization to manage it</div> */}
				<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3  gap-16 m-5">
					{
						appStore.organizations.filter(o => !(search.trim()) || o.organizationName.toLowerCase().includes(search.trim())).map((organization, index) => {
							return <div className='cursor-pointer' onClick={() => navigate(`/organizations/${organization.organizationId}`)}>
								<OrganizationTicket key={index} organization={organization} />
							</div>
						})
					}
				</div>
			</div>
		}
		{/* TODO :: duplicate code */}
		{!userStore.current.user?.isRegistered && <TopMostPortal>
			<LassoMVPUserSettingsModal editingUser={{
				role: '-',
				email: userStore.current.user?.email || '',
				firstName: userStore.current.user?.firstName || '',
				lastName: userStore.current.user?.lastName || '',
				photoURL: userStore.current.user?.photoURL || ''
			}}
				update={async (updatedUser) => {
					if (userStore.current.user?.isRegistered) {

					} else {
						const newUserData = await RestAPI.fetchAPI('/rest/access/registerNewUser', 'POST', {
							newUser: {
								email: userStore.current.user?.email,
								firstName: updatedUser.firstName,
								lastName: updatedUser.lastName,
								photoURL: updatedUser.photoURL,
							}
						});
						if (newUserData) {
							userStore.current.updateRegisteredUser(newUserData);
						}
					}
				}}
			/>
		</TopMostPortal>}
		{fetching && <LoadingAnimation />}
	</>
});

export default LassoMVPCreatorPage;