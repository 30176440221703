import { observer } from 'mobx-react';

import getMappingToolStore from '../data/MappingToolStore';
import { getMappingStore } from '../../../../utils/CreatorTools/MappingUtils';
import { useEffect, useState } from 'react';
import SharedUtils from '../../../../shared/SharedUtils';
import { logDebug } from '../../../../shared/logger';
import { wizardTitles } from './GenericWizardLeftBar';
import { IWizardStepProps } from './GenericWizardRightBar';

const MappingWizardStep3: React.FC<IWizardStepProps> = observer(({
	onCancel,
	onNext,
	onBack
}) => {
	const mappingToolStore = getMappingToolStore();
	const mappingStore = getMappingStore();
	const [unmappedVariables, setUnmappedVariables] = useState<string[][]>([]);
	const [allDone, setAllDone] = useState(false);
	useEffect(() => {
		const unmappedVariables: string[][] = [];
		mappingToolStore.selectedLineItems.forEach((lineItem, index) => {
			const allVariables = SharedUtils.removeDuplicateAndEmpty(lineItem.elements.flatMap(e => e.type === 'variable' ? e.acceptableValues! : []));
			const mapping = mappingToolStore.getVariablesMapping(lineItem.id);
			const mappedVariables = mapping.map(m => m.variableValue);

			const unmapped = allVariables.filter(v => SharedUtils.arrayIndexOfCaseInsensitive(mappedVariables, v) === -1);
			unmappedVariables.push(unmapped);
		});
		setAllDone(unmappedVariables.flatMap(v => v).length === 0);
		setUnmappedVariables(unmappedVariables);
	}, [mappingToolStore.selectedLineItems]);

	logDebug('unmappedVariables', unmappedVariables);
	return <>
		<div className="self-stretch h-full flex-col justify-between items-start gap-8 flex">
			<div className="self-stretch justify-start items-start gap-2.5 inline-flex">
				<div className="grow shrink basis-0 text-gray-900 text-lg font-normal font-['Hanken Grotesk'] leading-7">{wizardTitles[mappingToolStore.wizardStep - 1]}</div>
			</div>
			{
				!allDone
				&& <div className='overflow-y-scroll'>
					<div >
						The following variables are not mapped. Please map them to a human input ( comma seperated input).
					</div>
					<div >
						{
							`use {N} for numbers`
						}
					</div>
					{
						unmappedVariables.map((variables, index) => {
							return <div className='w-full '>
								<div>
									{mappingToolStore.selectedLineItems[index].originalLineItem}
								</div>
								<div className='w-full'>
									{variables.map(v => {
										return <div className='flex border border-cyan-200 items-center m-2'>
											<div className='w-64 px-1'>{v}</div>
											<input className='shrink grow border px-1 m-1' type="text" defaultValue={v} onBlur={(e) => {
												const varMapping = mappingToolStore.getVariablesMapping(mappingToolStore.selectedLineItems[index].id);
												varMapping.push({
													variableValue: v,
													ticInput: e.target.value,
													accepted: true,
													aiMatch: { variable: v, confidence: 'confident' },
													deleted: false,
													dotColor: ''
												});
												mappingToolStore.setVariablesMapping(mappingToolStore.selectedLineItems[index].id, [...varMapping]);
											}} />
										</div>
									})}
								</div>
							</div>
						})
					}
				</div>
			}
			{
				allDone
				&& <div className='overflow-y-scroll'>
					<div >
						All variables are mapped.
					</div>
				</div>
			}
			<div className="w-full p-6 bg-white border-t border-gray-200 justify-between items-start inline-flex">
				<div
					className="px-[18px] py-2.5 bg-white rounded-lg border border-gray-200 justify-center items-center gap-2 flex cursor-pointer select-none"
					onClick={() => {
						onBack();
					}}
				>
					<div className="justify-start items-center gap-2 flex ">
						<div className="text-slate-700 text-base font-semibold font-['Hanken Grotesk'] leading-normal">Back</div>
					</div>
				</div>
				<div className="justify-end items-center gap-14 flex">
					<div
						className="h-11 px-[18px] py-2.5 bg-blue-900 rounded-lg shadow border border-blue-900 justify-center items-center gap-2 flex cursor-pointer select-none"
						onClick={async () => {
							unmappedVariables.forEach((variables, index) => {
								const varMapping = mappingToolStore.getVariablesMapping(mappingToolStore.selectedLineItems[index].id);
								variables.forEach((v: string) => {
									if (v.trim() === '') return;
									if (!varMapping.find(m => m.variableValue === v)) {
										varMapping.push({
											variableValue: v,
											ticInput: v,
											accepted: true,
											aiMatch: { variable: v, confidence: 'confident' },
											deleted: false,
											dotColor: ''
										});
										mappingToolStore.setVariablesMapping(mappingToolStore.selectedLineItems[index].id, [...varMapping]);
									}
								})
							});
							await mappingToolStore.saveCurrentMapping('new');
							onNext(4);
							// analyzeMatch(selectedTupple, selectedLineItem);
						}}
					>
						<div className="justify-start items-center gap-2 flex">
							<div className="text-white text-base font-semibold font-['Hanken Grotesk'] leading-normal ">Save Mapping</div>
						</div>
					</div>
				</div >
			</div >
		</div>

	</>
});

export default MappingWizardStep3;