import React, { useEffect, useRef, useState } from 'react';
import { LassoSimpleButtonFull } from '../../components/ui/utils/LassoButtons';
import { EstimaticsDataKeys, EstimaticsFileTypes, IEstimaticsData, getAppStore, getEstimaticsCurrentProjectStore, getUserStore } from '../../data/DataStores';
import TopMostPortal from '../../components/ui/utils/Portals';
import LassoMVPUserSettingsModal from '../components/LassoMVPUserSettingsModal';
import { useNavigate } from 'react-router-dom';
import RestAPI from '../../utils/restAPI';
import NewProjectModal from '../components/Estimatics/EstimaticsNewProjectModal';
import { observer } from 'mobx-react';
import { CollectionReference, collection } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { logDebug } from '../../shared/logger';
import LoadingAnimation from '../../components/ui/utils/LoadingAnimation';
import EstimaticsStatusComboBox, { getEstimaticsStatusMap, getEstimaticsStatusMapDefault } from '../components/Estimatics/EstimaticsStatusComboBox';
import { EstimaticsAssigneeBox } from './EstimaticsProjectPage';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import Analytics from '../../utils/Analytics';
import Select from 'react-select';

export function useWindowSize() {
	const [size, setSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		const handleResize = () => {
			// logDebug('useWindowSize', window.innerWidth, window.innerHeight);
			setSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return size;
}

function useTableWidths() {
	const initialTableWidths: { [k: string]: number } = {
		'Project name': 130,
		'Reference': 130,
		'Address': 130,
		'Status': 130,
		'Assignee': 150,
		'Office': 130,
		'Created': 100,
		'Approx. claim': 100,
		'Field data': 100,
	};
	const [tableWidths, setTableWidths] = useState(initialTableWidths);
	const lastTableWidths = useRef(initialTableWidths);
	const { width } = useWindowSize();

	// useEffect(() => {
	// 	// logDebug('useTableWidths', width);
	// 	if (width > 1800) {
	// 		setTableWidths(initialTableWidths);
	// 		return;
	// 	}
	// 	// Example logic to update the widths based on window size
	// 	const newWidths = { ...initialTableWidths };
	// 	const adjustmentFactor = width / 1400; // Example adjustment based on window width

	// 	for (const key in newWidths) {
	// 		if (newWidths.hasOwnProperty(key)) {
	// 			newWidths[key] = Math.floor(initialTableWidths[key] * adjustmentFactor);
	// 		}
	// 	}

	// 	setTableWidths(newWidths);
	// }, [width]);

	const totalTableWidth = Object.values(tableWidths).reduce((a, b) => a + b, 0);
	// const totalTableWidth = Math.max(1100, Object.values(tableWidths).reduce((a, b) => a + b, 0));
	// if (totalTableWidth === 1100) {
	// 	if (lastTableWidths.current !== tableWidths) {
	// 		setTableWidths(lastTableWidths.current);
	// 	}
	// } else {
	// 	lastTableWidths.current = tableWidths;
	// }
	// logDebug('totalTableWidth', totalTableWidth);
	return { tableWidths, totalTableWidth, minTableWidth: 1100 };
}
const RowElement: React.FC<{ name: string, value: string, showTooltip?: boolean, rowIndex: number, tableWidths: { tableWidths: { [k: string]: number } } }> = ({ tableWidths, rowIndex, name, value, showTooltip }) => {
	return <>
		{showTooltip && <Tooltip id={`tooltip-${name}-${rowIndex}`} />}
		<div className="h-5 pl-5 justify-start items-center gap-2.5 flex"
			style={{ width: Math.floor(tableWidths.tableWidths[name]), overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
			<div
				data-tooltip-delay-show={500}
				data-tooltip-id={`tooltip-${name}-${rowIndex}`}
				data-tooltip-content={value}
				className={`grow shrink basis-0 text-gray-900 text-sm ${name === 'Project name' ? 'font-bold' : 'font-normal'} font-['Hanken Grotesk'] leading-tight whitespace-nowrap overflow-y-hidden overflow-x-hidden text-ellipsis`}>
				{value}
			</div>
		</div>
	</>
}

const ImageForAssignee: React.FC<{ assignee: string }> = observer(({ assignee }) => {
	const userStore = getUserStore();
	const user = userStore.getOtherUserList(false).find((user) => user.lassoUserId === assignee);
	const initials = (user ? (`${user.firstName[0] || ''}${user.lastName[0] || ''}`) : ('U')).toUpperCase();
	const [photoURL, setPhotoURL] = useState(user?.photoURL || '');

	useEffect(() => {
		if (user?.lassoUserId === userStore.user?.lassoUserId) {
			setPhotoURL(userStore.user?.photoURL || '');
		}
	}, [userStore.user?.photoURL]);

	if (!photoURL) {
		return <div className="w-8 h-8 py-1.5 bg-gray-100 rounded-[200px] border-2 border-white justify-center items-center inline-flex">
			<div className="w-8 text-center text-slate-600 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
				{initials}
			</div>
		</div>
	}
	else {

		return <div className="w-8 h-8 rounded-full bg-gray-100 border border-white overflow-hidden flex items-center justify-center relative">
			<div className="w-8 h-8 py-1.5 bg-gray-100 rounded-[200px] border-2 border-white justify-center items-center inline-flex absolute z-1">
				<div className="w-8 text-center text-slate-600 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
					{initials}
				</div>
			</div>
			<img src={RestAPI.getStorageFileUrl(photoURL)} alt="Avatar" className="w-full h-full object-cover absolute z-10" />
		</div>
	}
});



const NewRowElement: React.FC<{ name: string, value: string, showTooltip?: boolean, rowIndex?: number, tableWidths: { tableWidths: { [k: string]: number } }, estimateData: IEstimaticsData }> = ({
	estimateData,
	tableWidths,
	name,
	value
}) => {

	switch (name) {
		case 'Project name':
		case 'Address':
		case 'Reference':
			return <span>
				<span
					data-tooltip-delay-show={500}
					data-tooltip-id={`tooltip-${name}-${estimateData.estimateId}`}
					data-tooltip-content={value}
					className={`relative pl-5 ${name === 'Project name' ? 'font-bold' : 'font-normal'}`}>
					{value}
				</span>
				<Tooltip id={`tooltip-${name}-${estimateData.estimateId}`} />
			</span>


		case 'Created':
		case 'Approx. claim':
		case 'Office':
			return <span
				className={`pl-5 font-normal`}>
				{value}
			</span>
		case 'Assignee':
			return <div className="h-8 pl-5 justify-start items-center gap-2 flex" style={{ width: Math.floor(tableWidths.tableWidths['Assignee']), overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
				<div className='h-8 w-8 min-h-8 min-w-8'>
					<ImageForAssignee assignee={value} />
				</div>
				<div className="leading-tight no-break">
					<EstimaticsAssigneeBox disabled={true} value={value} />
				</div>
			</div>
		case 'Status':
			return <EstimaticsStatusComboBox disabled={true} status={value} width={120} height={24} />
		case 'Field data':
			const fc = getFilesCount(estimateData);
			return <div className="h-10 pl-5   justify-start items-center gap-4 flex" style={{ width: Math.floor(tableWidths.tableWidths['Field data']), overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
				<Tooltip className="z-10" id={`tooltip-fieldData-${estimateData.estimateId}`} />
				<div className="w-full h-6 relative flex-col justify-center items-center flex "
				>
					<div className='relative '

						data-tooltip-delay-show={fc.filesCount === 0 ? 100000 : 0}
						data-tooltip-id={`tooltip-fieldData-${estimateData.estimateId}`}
						data-tooltip-html={`
						<div className="w-[137px] h-[146px] flex-col justify-center items-center inline-flex">
							<div className="self-stretch p-2 bg-slate-700/opacity-90 rounded justify-center items-center inline-flex">
							<div className="grow shrink basis-0">
								<span style="text-white text-xs font-bold font-['Hanken Grotesk'] leading-[14.40px]">
									Attachments count:
									<br/>
								</span>
								<span style="text-white text-xs font-medium font-['Hanken Grotesk'] leading-[14.40px]">
								Tic sheet: ${fc.ticCount}
								<br/>
								Photos: ${fc.imageCount}
								<br/>
								Other documents: ${fc.otherCount}
								<br/>
								Links: ${fc.linksCount}
								<br/>
								Notes: ${fc.notesCount}
								</span>
							</div>
						</div>
					`
						}
					>

						<img src={`/assets/ui/fieldData-${fc.filesCount > 0 ? 'x' : '0'}.svg`} className="w-6 h-6 relative" />
						{
							fc.filesCount > 0 &&
							<div

								className=" absolute -top-2 -right-2 bg-sky-50 rounded-[100px] border border-white flex-col justify-center items-center flex w-5 h-5">
								<div className="text-blue-900 text-[10px] font-['Hanken Grotesk'] "
									style={{ fontSize: '12px' }}
								>
									{fc.filesCount}
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		default:
			return <span
				className='pl-5'>
				{value}
			</span>
	}

}



const EstimatesTableRow: React.FC<{ estimateData: IEstimaticsData, rowIndex: number, tableWidths: { tableWidths: { [k: string]: number }, totalTableWidth: number } }> = observer(({ estimateData, rowIndex, tableWidths }) => {
	const navigate = useNavigate();
	const userStore = getUserStore();
	function getImageForAssignee() {
		const user = userStore.getOtherUserList(false).find((user) => user.lassoUserId === estimateData.assignee);
		const initials = (user ? (`${user.firstName[0] || ''}${user.lastName[0] || ''}`) : ('U')).toUpperCase();

		if (!user?.photoURL) {
			return <div className="w-8 h-8 py-1.5 bg-gray-100 rounded-[200px] border-2 border-white justify-center items-center inline-flex">
				<div className="w-8 text-center text-slate-600 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
					{initials}
				</div>
			</div>
		}
		else {

			return <div className="w-8 h-8 rounded-full bg-gray-100 border border-white overflow-hidden flex items-center justify-center relative">
				<div className="w-8 h-8 py-1.5 bg-gray-100 rounded-[200px] border-2 border-white justify-center items-center inline-flex absolute z-1">
					<div className="w-8 text-center text-slate-600 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
						{initials}
					</div>
				</div>
				<img src={RestAPI.getStorageFileUrl(user.photoURL)} alt="Avatar" className="w-full h-full object-cover absolute z-10" />
			</div>
		}
	}


	const ticCount = (estimateData.files || []).filter(f => !f.deleted && f.fileType === EstimaticsFileTypes.tic).length;
	const imageCount = (estimateData.files || []).filter(f => !f.deleted && f.fileType === EstimaticsFileTypes.image).length;
	const otherCount = (estimateData.files || []).filter(f => !f.deleted && f.fileType === EstimaticsFileTypes.other).length;
	const linksCount = estimateData.links.length;
	const notesCount = estimateData.notes.length;
	const filesCount = (estimateData.files || []).filter(f => !f.deleted).length + linksCount + notesCount;
	return <tr
		onClick={() => {
			navigate(`/projects/${estimateData.estimateId}`)
		}}
		className="cursor-pointer self-stretch  justify-start items-center gap-6 inline-flex">

		{/* <div className="grow shrink basis-0 h-16 justify-start items-center gap-2 flex bg-white px-2 py-5  border-l border-r border-b border-gray-200"> */}

		<td><RowElement name="Reference" value={estimateData.estimateReferenceNumber} showTooltip={true} rowIndex={rowIndex} tableWidths={tableWidths} />
		</td><td><RowElement name="Address" value={estimateData.propertyAddress} showTooltip={true} rowIndex={rowIndex} tableWidths={tableWidths} />
		</td><td><div className="h-6 pl-5 justify-start items-center gap-2 flex " style={{ width: Math.floor(tableWidths.tableWidths['Status']), overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
			<div className="h-6 justify-start items-start flex overflow-hidden">
				<EstimaticsStatusComboBox disabled={true} status={estimateData.status} width={120} height={24} />
			</div>
		</div>
		</td><td><div className="h-8 pl-5 justify-start items-center gap-2 flex" style={{ width: Math.floor(tableWidths.tableWidths['Assignee']), overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
			{getImageForAssignee()}
			<div className="grow shrink basis-0 text-gray-900 text-sm font-normal font-['Hanken Grotesk'] leading-tight whitespace-nowrap overflow-y-hidden overflow-x-hidden text-ellipsis">
				<EstimaticsAssigneeBox disabled={true} value={estimateData.assignee} />
			</div>
		</div>
		</td><td><RowElement name="Office" value={estimateData.office} rowIndex={rowIndex} tableWidths={tableWidths} />
		</td><td><RowElement name="Created" value={estimateData.dateCreated} rowIndex={rowIndex} tableWidths={tableWidths} />
		</td><td><RowElement name="Approx. claim" value={estimateData.approximateClaim} rowIndex={rowIndex} tableWidths={tableWidths} />
		</td><td>
			<div className="h-10 pl-5   justify-start items-center gap-4 flex" style={{ width: Math.floor(tableWidths.tableWidths['Field data']), overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
				<Tooltip id={`tooltip-fieldData-${rowIndex}`} />
				<div className="w-full h-6 relative flex-col justify-center items-center flex "
				>
					<div className='relative '

						data-tooltip-delay-show={filesCount === 0 ? 100000 : 0}
						data-tooltip-id={`tooltip-fieldData-${rowIndex}`}
						data-tooltip-html={`
							<div className="w-[137px] h-[146px] flex-col justify-center items-center inline-flex">
								<div className="self-stretch p-2 bg-slate-700/opacity-90 rounded justify-center items-center inline-flex">
								<div className="grow shrink basis-0">
									<span style="text-white text-xs font-bold font-['Hanken Grotesk'] leading-[14.40px]">
										Attachments count:
										<br/>
									</span>
									<span style="text-white text-xs font-medium font-['Hanken Grotesk'] leading-[14.40px]">
									Tic sheet: ${ticCount}
									<br/>
									Photos: ${imageCount}
									<br/>
									Other documents: ${otherCount}
									<br/>
									Links: ${linksCount}
									<br/>
									Notes: ${notesCount}
									</span>
								</div>
							</div>
						`
						}
					>

						<img src={`/assets/ui/fieldData-${filesCount > 0 ? 'x' : '0'}.svg`} className="w-6 h-6 relative" />
						{
							filesCount > 0 &&
							<div

								className=" absolute -top-2 -right-2 bg-sky-50 rounded-[100px] border border-white flex-col justify-center items-center flex w-5 h-5">
								<div className="text-blue-900 text-[10px] font-['Hanken Grotesk'] "
									style={{ fontSize: '12px' }}
								>
									{filesCount}
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</td>
		{/* </div> */}
	</tr>
});


const TableHeader: React.FC<{ arrow?: string, name: string, onClick: () => void, tableWidths: any }> = ({ name, onClick, tableWidths, arrow }) => {
	return <div className={`h-5 justify-start items-center  flex no-break    pl-2 overflow-hidden `}
		style={{
			minWidth: Math.floor(tableWidths.tableWidths[name]),
		}}>
		{!arrow && <span className='min-w-[7px] w-[7px]'>&nbsp;</span>}
		<div
			// onClick={onClick}
			className="text-sm font-semibold font-['Hanken Grotesk'] leading-tight no-break overflow-hidden ">
			{arrow}{name}
		</div>
	</div>

}

function getFilesCount(estimateData: IEstimaticsData) {
	const ticCount = (estimateData.files || []).filter(f => !f.deleted && f.fileType === EstimaticsFileTypes.tic).length;
	const imageCount = (estimateData.files || []).filter(f => !f.deleted && f.fileType === EstimaticsFileTypes.image).length;
	const otherCount = (estimateData.files || []).filter(f => !f.deleted && f.fileType === EstimaticsFileTypes.other).length;
	const linksCount = estimateData.links.length;
	const notesCount = estimateData.notes.length;
	const filesCount = (estimateData.files || []).filter(f => !f.deleted).length + linksCount + notesCount;
	return {
		ticCount,
		imageCount,
		otherCount,
		linksCount,
		notesCount,
		filesCount
	};
}

const EstimatesTable: React.FC<{ data: IEstimaticsData[] }> = observer(({ data }) => {
	const estimaticsStore = getEstimaticsCurrentProjectStore();
	const tableWidths = useTableWidths();
	const navigate = useNavigate();
	const headers: {
		header: string;
		key: EstimaticsDataKeys;
	}[] = [
			{ header: 'Project name', key: 'projectName' },
			{ header: 'Reference', key: 'estimateReferenceNumber' },
			{ header: 'Address', key: 'propertyAddress' },
			{ header: 'Status', key: 'status' },
			{ header: 'Assignee', key: 'assignee' },
			{ header: 'Office', key: 'office' },
			{ header: 'Created', key: 'dateCreated' },
			{ header: 'Approx. claim', key: 'approximateClaim' },
			{ header: 'Field data', key: 'fieldData' },
		];
	// const [sortedData, setSortedData] = useState<IEstimaticsData[]>([]);
	const [sortBy, setSortBy] = useState<{ key: EstimaticsDataKeys, order: string }>({
		key: 'dateCreated',
		order: 'desc'
	});


	useEffect(() => {
		//logDebug('sort start')
		const newSortedData = data.sort((a: IEstimaticsData, b: IEstimaticsData) => {
			if (sortBy.key === 'dateCreated' || sortBy.key === 'dateContacted' || sortBy.key === 'dateOfLoss') {
				const dateA = new Date(a[sortBy.key]);
				const dateB = new Date(b[sortBy.key]);
				if (sortBy.order === 'asc') {
					return dateA.getTime() - dateB.getTime();
				} else {
					return dateB.getTime() - dateA.getTime();
				}

			} else if (sortBy.key === 'status') {
				const statusA = getEstimaticsStatusMap()[a[sortBy.key]]?.index || 0;
				const statusB = getEstimaticsStatusMap()[b[sortBy.key]]?.index || 0;
				if (sortBy.order === 'asc') {
					return statusA - statusB;
				} else {
					return statusB - statusA;
				}
			} else if (sortBy.key === 'fieldData') {
				const { filesCount: ac } = getFilesCount(a);
				const { filesCount: bc } = getFilesCount(b);

				if (sortBy.order === 'asc') {
					return ac - bc;
				} else {
					return bc - ac;
				}

			} else {
				if (sortBy.order === 'asc') {
					return (a[sortBy.key] ?? '').localeCompare(b[sortBy.key] ?? '');
				} else {
					return (b[sortBy.key] ?? '').localeCompare(a[sortBy.key] ?? '');
				}
			}
		}).filter((d: any) => {
			logDebug('estimaticsStore.assigneeFilter.value', estimaticsStore.assigneeFilter?.value)
			if (!estimaticsStore.assigneeFilter?.value) return true;
			if (d['assignee'] === estimaticsStore.assigneeFilter.value) return true;
			if (!d['assignee'] && estimaticsStore.assigneeFilter.value === 'Unassigned') return true;
		}).filter((d: any) => {
			if (!estimaticsStore.statusFilter?.value) return true;
			if (d['status'] === estimaticsStore.statusFilter?.value) return true;
			if (!d['status'] && estimaticsStore.statusFilter.value === getEstimaticsStatusMapDefault()) return true;
		}).filter((d: any) => {
			if (!estimaticsStore.officeFilter?.value) return true;
			if (d['office'] === estimaticsStore.officeFilter?.value) return true;

		}).filter((d: any) => {
			const searchFilter = estimaticsStore.searchFilter.trim().toLowerCase();
			if (!searchFilter) return true;
			if ((d['projectName'] || '').toLowerCase().includes(searchFilter)) return true;
			if ((d['estimateReferenceNumber'] || '').toLowerCase().includes(searchFilter)) return true;
			if ((d['propertyAddress'] || '').toLowerCase().includes(searchFilter)) return true;

		});
		setTimeout(() => {
			estimaticsStore.sortedData = newSortedData;
			//logDebug('sort end')
		}, 0);
	}, [data, sortBy, estimaticsStore.statusFilter, estimaticsStore.assigneeFilter, estimaticsStore.searchFilter, estimaticsStore.officeFilter]);

	const updateSortBy = (index: number) => {
		const previousSortBy = { ...sortBy };
		const currentSortBy = { ...sortBy };

		if (currentSortBy.key === headers[index].key) {
			currentSortBy.order = currentSortBy.order === 'asc' ? 'desc' : 'asc';
		} else {
			currentSortBy.order = 'asc';
		}
		currentSortBy.key = headers[index].key;
		Analytics.Instance.track('EstimaticsSortBy', { sortBy: currentSortBy.key, order: currentSortBy.order, previousSortBy: previousSortBy.key, previousOrder: previousSortBy.order });
		//logDebug('currentSortBy', currentSortBy);
		setSortBy(currentSortBy);
	}

	//logDebug('render EstimatesTable');


	return (
		<div className='overflow-x-auto min-h-[220px]   rounded-xl shadow border bg-gray-50 border-gray-200 scrollbar-thin mx-8'>
			<div className=''>

				{
					// sortedData.length > 0 &&
					<table className='w-full  font-["Hanken Grotesk"] ' >
						<tbody>
							<tr className=' h-12 border-b border-gray-200 '>
								{
									headers.map((header, index) => {
										return <th key={index} className='relative btn px-2' onClick={() => updateSortBy(index)} >
											<span className={'hover:text-blue-600 '}>
												<TableHeader arrow={header.key === sortBy.key ? (sortBy.order === 'asc' ? '↓' : '↑') : ''} tableWidths={tableWidths} name={header.header} onClick={() => { }} />
											</span>
										</th>
									})
								}
							</tr>
							{
								estimaticsStore.sortedData.length >= 0 &&

								estimaticsStore.sortedData.map((estimateData, rowIndex) => {
									return <tr key={estimateData.estimateId} className={rowIndex < estimaticsStore.sortedData.length - 1 ? 'border-b border-gray-200 btn' : 'btn'}
										onClick={() => {
											Analytics.Instance.track('EstimaticsProjectClicked', { projectId: estimateData.estimateId });
											navigate(`/projects/${estimateData.estimateId}`)
										}}
									>
										{
											headers.map((header, _index) => {
												return <td key={_index} className='bg-white no-break max-w-24 h-16 text-sm px-2'>
													<NewRowElement estimateData={estimateData} name={header.header} value={estimateData[header.key]} tableWidths={tableWidths} />
												</td>
											})
										}

									</tr>
								})
							}
							{
								estimaticsStore.sortedData.length === 0 &&
								<tr>
									<td colSpan={9} >
										<div className="w-[1376px] h-[313px] py-16 flex-col justify-center items-center gap-[23px] inline-flex">
											<div className="w-[72px] h-[72px] pl-[3.27px] pr-[4.09px] pt-[9px] pb-[9.82px] justify-center items-center inline-flex">
												<img className="w-[64.64px] h-[53.18px] relative" src='assets/ui/empty-filter.svg' />

											</div>
											<div className="flex-col justify-center items-center gap-2 flex">
												<div className="text-gray-900 text-base font-medium font-['Hanken Grotesk'] leading-snug select-none" >No results</div>
												<div className="text-gray-900 text-xs font-normal font-['Hanken Grotesk'] leading-none select-none">Consider changing up your filters to allow more results to come through</div>
											</div>
											<div className="justify-center items-center gap-2 inline-flex btn"
												onClick={() => {
													Analytics.Instance.track('EstimaticsResetFiltersClicked');
													estimaticsStore.officeFilter = null;
													estimaticsStore.statusFilter = null;
													estimaticsStore.assigneeFilter = null;
													estimaticsStore.searchFilter = '';
												}}
											>
												<div className="justify-start items-center gap-2 flex">
													<div className="text-blue-900 text-sm font-semibold font-['Hanken Grotesk'] leading-tight">Reset filters</div>
												</div>
											</div>
										</div>
									</td>
								</tr>
							}
						</tbody>
					</table>
				}
			</div>
		</div >
	);


});


const EstimaticsFilterSelectBox: React.FC<{
	name: string,
	options: string[][],
	value: string,
	onChange: (val: string) => void
}> = ({ name, options, value, onChange }) => {
	if (name === 'Assignee') {
		logDebug('EstimaticsFilterSelectBox', name, value);
	}
	const [showOptions, setShowOptions] = useState(false);
	const [displayValue, setDisplayValue] = useState(options.find(o => o[0] === value) ? options.find(o => o[0] === value)![1] : value);
	const _setSelectedValue = (e: any, index: number) => {
		e.stopPropagation();
		setShowOptions(false);
		if (index === -1) {
			Analytics.Instance.track('EstimaticsFilterSelectBoxShowAll', { filterName: name });
			setDisplayValue(name);
			onChange('');
		} else {
			Analytics.Instance.track('EstimaticsFilterSelectBoxShowOption', {
				filterName: name,
				displayValue: options[index][1],
				value: options[index][0]
			});
			setDisplayValue(options[index][1]);
			onChange(options[index][0]);
		}
	}

	const onBackgroundClick = (e: any) => {
		let element = e.target;
		while (element) {
			if (element.getAttribute && element.id === `filter-${name}`) {
				return;
			}
			element = element.parentElement;
		}
		setShowOptions(false);
	}
	useEffect(() => {
		if (!value || value === 'Show all') {
			setDisplayValue(name);
		}
		document.removeEventListener('click', onBackgroundClick);
		document.addEventListener('click', onBackgroundClick);
		return () => {
			document.removeEventListener('click', onBackgroundClick);
		}
	}, []);



	return <div id={`filter-${name}`}>
		<div className='bg-white rounded-lg border border-gray-300 p-2 '>
			<div className='no-break cursor-pointer flex  justify-between' onClick={() => {
				Analytics.Instance.track('EstimaticsFilterSelectBoxClicked', { filterName: name, show: !showOptions });
				setShowOptions(showOptions => !showOptions);
			}}>
				<div className={`w-60 flex-col justify-start items-start gap-0.5 inline-flex ${displayValue === name && 'text-gray-400'}`}>{displayValue}</div>
				<img className="w-5 h-5" src="/assets/ui/chevron-down.svg" />
			</div>
		</div>
		{
			showOptions &&

			<div className="absolute z-20 w-60  bg-white rounded-lg shadow border border-gray-200 justify-start items-start inline-flex">
				<div className="grow shrink basis-0 py-1 flex-col justify-start items-start inline-flex " onClick={() => setShowOptions(false)}>
					<div className="self-stretch px-1.5 py-0.5 justify-start items-center inline-flex">
						<div className="grow shrink basis-0 pl-2 pr-2.5 py-2.5 rounded-md flex-col justify-start items-start gap-2 inline-flex">
							<div className="self-stretch justify-start items-center gap-2 inline-flex">
								<div className="text-gray-400 text-xs font-bold font-['Hanken Grotesk'] leading-[14.40px]">{name}</div>
							</div>
						</div>
					</div>
					<div className="h-[46px] w-full  flex-col justify-center items-start flex ">
						<div className="self-stretch px-1.5 py-0.5 justify-start items-center inline-flex">
							<div className="grow shrink basis-0 pl-2 pr-2.5 py-2.5 rounded-md flex-col justify-start items-start gap-2 inline-flex">
								<div className="self-stretch justify-start items-center gap-2 inline-flex cursor-pointer"
									onClick={(e) => _setSelectedValue(e, -1)}>
									<div className="text-gray-900 text-base font-bold font-['Hanken Grotesk'] leading-snug">Show all</div>
								</div>
							</div>
						</div>
						<div className="self-stretch w-full  h-[0px] opacity-40 flex-col justify-start items-start gap-2.5 inline-flex">
							<div className="self-stretch h-[0px] border border-gray-300"></div>
						</div>
					</div>
					{
						options.map((option, index) => {
							return <div key={index} className="no-break w-56 self-stretch px-1.5 py-0.5 justify-start items-center inline-flex">
								<div className="grow shrink basis-0 pl-1 pr-1 py-2 rounded-md flex-col justify-start items-start gap-2 inline-flex">
									<div className="self-stretch justify-start items-center gap-2 inline-flex cursor-pointer"
										onClick={(e) => _setSelectedValue(e, index)}
									>
										<div className=" text-gray-900 text-base font-medium font-['Hanken Grotesk'] leading-normal">{option[1]}</div>
									</div>
								</div>
							</div>

						})
					}
				</div>
			</div>

		}
	</div>
}

const EstimatesTableTopBar: React.FC = observer(() => {
	const newProjectStore = getEstimaticsCurrentProjectStore();
	const userStore = getUserStore();
	const [forceRender, setForceRender] = useState(0);
	const windowSize = useWindowSize();
	const [showFilterPanel, setShowFilterPanel] = useState(false);
	const estimaticsStore = getEstimaticsCurrentProjectStore();
	const [previousFilters, setPreviousFilters] = useState({
		assigneeFilter: '',
		statusFilter: '',
		officeFilter: '',
		searchFilter: ''
	});
	const wBreakPoint = 1330;

	useEffect(() => {
		if (windowSize.width > wBreakPoint) {
			setShowFilterPanel(false);
		}
	}, [windowSize]);


	useEffect(() => {
		if ((newProjectStore.assigneeFilter !== previousFilters.assigneeFilter ||
			newProjectStore.statusFilter !== previousFilters.statusFilter ||
			newProjectStore.officeFilter !== previousFilters.officeFilter ||
			newProjectStore.searchFilter !== previousFilters.searchFilter) &&
			!newProjectStore.assigneeFilter && !newProjectStore.statusFilter && !newProjectStore.officeFilter && !newProjectStore.searchFilter) {
			setForceRender(forceRender + 1);
		}

		setPreviousFilters({
			assigneeFilter: newProjectStore.assigneeFilter,
			statusFilter: newProjectStore.statusFilter,
			officeFilter: newProjectStore.officeFilter,
			searchFilter: newProjectStore.searchFilter
		});

		// if (!newProjectStore.assigneeFilter && !newProjectStore.statusFilter && !newProjectStore.officeFilter && !newProjectStore.searchFilter) {

		// }
	}, [newProjectStore.assigneeFilter, newProjectStore.statusFilter, newProjectStore.officeFilter, newProjectStore.searchFilter]);



	return <div className="w-full  flex-col justify-start items-start gap-4 inline-flex">
		<div className="self-stretch h-[72px] px-8 justify-between items-center gap-6 inline-flex">
			<div>
				<div className="grow shrink basis-0 h-10 justify-start items-center gap-4 flex">
					<div className="w-60 h-10 flex-col justify-start items-start gap-0.5 inline-flex">
						<div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
							<img src='/assets/ui/search-lg.svg' className="w-5 h-5 relative" />
							<div className="grow shrink basis-0 h-5 justify-start items-center gap-2 flex">
								<input type="text" className="w-[90%] px-1 focus:outline-none font-normal font-['Hanken Grotesk']" placeholder='Search'
									value={newProjectStore.searchFilter}
									onChange={(e) => {
										newProjectStore.searchFilter = e.target.value;
									}}
								/>
							</div>
						</div>
					</div>

					{
						windowSize.width > wBreakPoint && <>
							<Select
								key={`assigneeFilter-${forceRender}`}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										borderRadius: '6px',
									}),
								}}
								onChange={(val) => {
									newProjectStore.assigneeFilter = val;
								}}
								value={newProjectStore.assigneeFilter}
								className='w-[280px]'
								placeholder='Assignee'
								isClearable={true}
								isSearchable={true}
								options={[...userStore.getOtherUserList(false).map(u => ({ value: u.lassoUserId, label: `${u.firstName} ${u.lastName}` }))]}
							/>
							<Select
								key={`statusFilter-${forceRender}`}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										borderRadius: '6px',
									}),
								}}
								onChange={(val) => {
									newProjectStore.statusFilter = val;
								}}
								value={newProjectStore.statusFilter}
								className='w-[280px]'
								placeholder='Estimate Status'
								isClearable={true}
								isSearchable={true}
								options={[...Object.keys(getEstimaticsStatusMap()).map(v => ({ value: v, label: v }))]}
							/>
							<Select
								key={`officeFilter-${forceRender}`}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										borderRadius: '6px',
									}),
								}}
								onChange={(val) => {
									newProjectStore.officeFilter = val;
								}}
								value={newProjectStore.officeFilter}
								className='w-[280px]'
								placeholder='Office'
								isClearable={true}
								isSearchable={true}
								options={[...userStore.officeList.filter(o => o).map(v => ({ value: v, label: v }))]}
							/>
						</>
					}

				</div>
			</div>
			<div className="justify-end items-center gap-4 flex">
				{
					windowSize.width <= wBreakPoint &&
					<div
						onClick={() => {
							setShowFilterPanel(true);
						}}
						className="btn w-10 h-10 p-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
						<img src='/assets/ui/filter-funnel-02.svg' className="w-5 h-5 relative" />
					</div>
				}
				<div className="text-white text-base font-semibold font-['Hanken Grotesk'] leading-normal no-break">
					<div
						onClick={() => {
							Analytics.Instance.track('EstimaticsNewProjectClicked');
							newProjectStore.showNewProjectModal = true;
						}}

						className="cursor-pointer px-[18px] py-2.5 bg-blue-900 rounded-lg shadow border border-blue-900 justify-start items-center gap-2 flex">
						<div className="justify-start items-center gap-2 flex "					>
							New Project
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="self-stretch px-8 justify-between items-center inline-flex">
			<div className="text-slate-600 text-xs font-medium font-['Hanken Grotesk'] leading-[14.40px]">{newProjectStore.sortedData.length} projects shown</div>
			<div className="opacity-0 flex-col justify-start items-start inline-flex">
				<div className="pl-2 pr-1 py-2 bg-white rounded-lg border border-gray-200 justify-center items-center gap-2 inline-flex">
					<div className="justify-start items-center gap-2 flex">
						<div className="text-slate-700 text-xs font-medium font-['Hanken Grotesk'] leading-[14.40px]">Sort Alphabetically A-Z</div>
					</div>
					<div className="w-5 h-5 px-[5px] justify-center items-center flex" />
				</div>
			</div>
		</div>
		{
			showFilterPanel &&
			<TopMostPortal onBackgroundClick={() => setShowFilterPanel(false)}>
				<div className='bg-white w-[330px] h-full fixed right-0 flex flex-col items-start justify-start'>
					<div className='w-full relative'>
						<div
							className='cursor-pointer absolute right-4 top-2'
							onClick={() => setShowFilterPanel(false)}>
							<img src='/assets/ui/x-close.svg' className="w-5 h-5 relative" />
						</div>
					</div>
					<div className="px-6 pt-6 flex-col justify-start items-start inline-flex">
						<div className="self-stretch h-[244px] flex-col justify-start items-start gap-8 flex">
							<div className="self-stretch justify-start items-start gap-2.5 inline-flex">
								<div className="grow shrink basis-0 text-gray-900 text-lg font-normal font-['Hanken Grotesk'] leading-7">Filter projects</div>
							</div>
							<Select
								key={`assigneeFilter-${forceRender}`}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										borderRadius: '6px',
									}),
								}}
								onChange={(val) => {
									newProjectStore.assigneeFilter = val;
								}}
								value={newProjectStore.assigneeFilter}
								className='w-[280px]'
								placeholder='Assignee'
								isClearable={true}
								isSearchable={true}
								options={[...userStore.getOtherUserList(false).map(u => ({ value: u.lassoUserId, label: `${u.firstName} ${u.lastName}` }))]}
							/>
							<Select
								key={`statusFilter-${forceRender}`}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										borderRadius: '6px',
									}),
								}}
								onChange={(val) => {
									newProjectStore.statusFilter = val;
								}}
								value={newProjectStore.statusFilter}
								className='w-[280px]'
								placeholder='Estimate Status'
								isClearable={true}
								isSearchable={true}
								options={[...Object.keys(getEstimaticsStatusMap()).map(v => ({ value: v, label: v }))]}
							/>
							<Select
								key={`officeFilter-${forceRender}`}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										borderRadius: '6px',
									}),
								}}
								onChange={(val) => {
									newProjectStore.officeFilter = val;
								}}
								value={newProjectStore.officeFilter}
								className='w-[280px]'
								placeholder='Office'
								isClearable={true}
								isSearchable={true}
								options={[...userStore.officeList.map(v => ({ value: v, label: v }))]}
							/>

							<div className="justify-center items-center gap-2 inline-flex btn"
								onClick={() => {
									Analytics.Instance.track('EstimaticsResetFiltersClicked');
									estimaticsStore.officeFilter = null;
									estimaticsStore.statusFilter = null;
									estimaticsStore.assigneeFilter = null;
									estimaticsStore.searchFilter = '';
								}}
							>
								<div className="justify-start items-center gap-2 flex">
									<div className="text-blue-900 text-sm font-semibold font-['Hanken Grotesk'] leading-tight">Reset filters</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</TopMostPortal>
		}
	</div>
});

const LassoMVPUserPageWhenRegistered: React.FC = observer(() => {
	const userStore = getUserStore();
	const appStore = getAppStore();
	const newProjectStore = getEstimaticsCurrentProjectStore();
	const estimatesCollectionRef = collection(useFirestore(), `/organizations/${userStore.user!.organizationId}/estimates`) as CollectionReference<IEstimaticsData>;
	const _estimates = useFirestoreCollectionData<IEstimaticsData>(estimatesCollectionRef, { idField: 'id' });

	useEffect(() => {
		appStore.setBreadcrumbPath([]);
		newProjectStore.showNewProjectModal = false;
	}, []);


	if (_estimates.status === 'loading') {
		return <LoadingAnimation />
	}

	const estimates = _estimates.data.filter((d) => !d.deleted);
	return <>
		{
			estimates.length === 0 &&
			<div className="w-[1440px] h-[358px] pt-14 flex-col justify-center items-center gap-[23px] inline-flex">
				<div className="w-32 h-32 pl-[5.82px] pr-[7.27px] pt-4 pb-[17.45px] justify-center items-center inline-flex">
					<img src='/assets/estimatics/empty-state.svg' className="w-[114.91px] h-[94.54px] relative" />
				</div>
				<div className="text-gray-900 text-xl font-semibold font-['Hanken Grotesk'] leading-7">No projects to show here</div>
				<div className="text-gray-900 text-xs font-normal font-['Hanken Grotesk'] leading-none">Click on the button below to add your first project</div>
				<div className="px-7 py-4 bg-blue-900 rounded-lg shadow border border-blue-900 justify-start items-center gap-3 inline-flex btn" onClick={() => {
					Analytics.Instance.track('EstimaticsNewProjectClicked');
					newProjectStore.showNewProjectModal = true;
				}} >
					<div className="justify-start items-center gap-2 flex">
						<div className="text-white text-lg font-semibold font-['Hanken Grotesk'] leading-7">New project</div>
					</div>
				</div>
			</div>
		}
		{
			estimates.length > 0 && <div className={newProjectStore.showNewProjectModal ? 'overflow-y-hidden h-[80vh]' : 'flex flex-col'} style={{
				maxHeight: 'calc(100vh - 160px)'
			}}>
				<EstimatesTableTopBar />
				<EstimatesTable data={estimates} />
			</div>
		}

		{
			newProjectStore.showNewProjectModal &&
			<NewProjectModal />
		}

	</>
});
const LassoMVPUserPage: React.FC = observer(() => {
	const userStore = getUserStore();
	const appStore = getAppStore();
	const newProjectStore = getEstimaticsCurrentProjectStore();

	useEffect(() => {
		appStore.setBreadcrumbPath([]);
		newProjectStore.showNewProjectModal = false;
	}, []);

	return <>
		{
			userStore.user?.isRegistered ?
				<LassoMVPUserPageWhenRegistered /> :
				<TopMostPortal>
					<LassoMVPUserSettingsModal editingUser={{
						role: '-',
						email: userStore.user?.email || '',
						firstName: userStore.user?.firstName || '',
						lastName: userStore.user?.lastName || '',
						photoURL: userStore.user?.photoURL || ''
					}}
						update={async (updatedUser) => {
							if (userStore.user?.isRegistered) {

							} else {
								const newUserData = await RestAPI.fetchAPI('/rest/access/registerNewUser', 'POST', {
									newUser: {
										email: userStore.user?.email,
										firstName: updatedUser.firstName,
										lastName: updatedUser.lastName,
										photoURL: updatedUser.photoURL,
									}
								});
								if (newUserData) {
									userStore.updateRegisteredUser(newUserData);
									location.reload();
								}
							}
						}}

					/>
				</TopMostPortal>
		}
	</>
});

export default LassoMVPUserPage;