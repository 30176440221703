import React, { useEffect, useState } from 'react';
import RestAPI from '../../../utils/restAPI';
import { logError } from '../../../shared/logger';
import LottieAnimation from './LottieAnimation';
import * as animationData from './image_preloader.json'
import TopMostPortal from './Portals';
const SecureImage: React.FC<{
	destination?: string,
	defaultImage: string,
	w: number,
	h: number,

	alt?: string,
	className?: string,
	onUrlChange?: (url: string) => void
}> = ({ alt, className, w, h, defaultImage, destination, onUrlChange }) => {
	// TODO :: add placeholder loading image
	const [fetching, setFetching] = useState(false);
	const [inputKey, setInputKey] = useState(0);
	const uploadRef = React.useRef<HTMLInputElement>(null);
	const [imageURL, setImageURL] = useState(defaultImage);

	useEffect(() => {
		if (defaultImage.toLowerCase().startsWith(RestAPI.getStorageFileUrl(''))) {
			setImageURL(defaultImage);
		}
	}, []);
	return <div className={className + ` w-[${w}px] h-[${h}px] rounded-full overflow-hidden flex items-center justify-center absolute btn`}
		onClick={() => uploadRef.current?.click()}>
		{
			!fetching &&
			<img src={imageURL} alt={alt || ''} className='w-full h-full object-cover' width={w} height={h} />
		}
		{fetching && <LottieAnimation w={w + 100} h={h + 100} animationData={animationData} />}
		{
			destination && <TopMostPortal className=''>
				<input key={inputKey} type="file" id="files" accept="image/*" ref={uploadRef} style={{ display: 'none' }}
					onChange={async (e) => {
						setFetching(true);
						try {
							if (uploadRef.current!.files && uploadRef.current!.files.length > 0) {
								try {
									const formData = new FormData();
									formData.append('destination', destination);
									formData.append('files', uploadRef.current!.files[0]);
									const data = await RestAPI.fetchAPI('/rest/files/upload', 'POST', formData);
									onUrlChange && onUrlChange(data[0].destination);
									setImageURL(RestAPI.getStorageFileUrl(data[0].destination));
								} catch (error) {
									logError('Error uploading files', error);
								}
								setInputKey(inputKey + 1);
							}
						} catch (err) {

						}
						setFetching(false);
					}}
				/>
			</TopMostPortal>
		}
	</div>

}

export default SecureImage;