import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import './Portals.scss';
import { logDebug } from '../../../shared/logger';

interface PortalProps {
	children?: React.ReactNode;
	className?: string;
	el?: string;
	onBackgroundClick?: () => void
}

const TopMostPortal: React.FC<PortalProps> = ({ children = undefined, className = 'top-most-portal', el = 'div', onBackgroundClick }) => {
	const [container] = useState<HTMLElement>(() => {
		// This will be executed only on the initial render
		// https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
		const elm = document.createElement(el);
		elm.id = 'top-most-portal';
		return elm;
	});

	const _onBackgroundClick = (e: any) => {
		// logDebug('parentElement.id', e.target.id);
		// let parentElement = e.target.parentElement
		// while (parentElement) {
		// 	logDebug('parentElement.id', parentElement.id);
		// 	parentElement = parentElement.parentElement;
		// }
		const topMostElementParent = e.target.parentElement;
		if (topMostElementParent.id === 'top-most-portal' || e.target.id === 'top-most-portal') {
			onBackgroundClick && onBackgroundClick();
		}
		// logDebug('parentElement.id', e.target.id);
		// let parentElement = e.target.parentElement;
		// while (parentElement) {
		// 	logDebug('parentElement.id', parentElement.id);
		// 	if (parentElement.id === 'top-most-portal') {
		// 		return;
		// 	}
		// 	parentElement = parentElement.parentElement;
		// }
		// onBackgroundClick && onBackgroundClick();
	}


	useEffect(() => {
		onBackgroundClick && container.addEventListener('click', _onBackgroundClick);
		const enumerator = container.classList.keys()
		for (const key in enumerator) {
			container.classList.remove(key)
		}
		className && container.classList.add(className)
		document.body.appendChild(container)
		return () => {
			onBackgroundClick && container.removeEventListener('click', _onBackgroundClick);
			document.body.removeChild(container)
		}
	}, [])

	return ReactDOM.createPortal(children, container)
}


export default TopMostPortal;