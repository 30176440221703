import { makeAutoObservable, runInAction } from 'mobx';
import { IDatabaseLineItem } from '../../LineItemDBCreationToolPage';
import SharedUtils from '../../../../shared/SharedUtils';
import { Firestore, collection, doc, getFirestore, onSnapshot, setDoc } from 'firebase/firestore';
import { logDebug, logError } from '../../../../shared/logger';
import { TemplateCellTuple } from '../../../../shared/Azure';
import { appFeatures } from '../../../../data/AppFeatures';

export interface IAIMatch {
	variable: string;
	confidence: 'confident' | 'not confident';
}
export type AIMatchings = { [s: string]: IAIMatch }

export interface VariableMapping {
	measurement?: string;
	ticInput: string;
	aiMatch: IAIMatch;
	variableValue: string;
	accepted: boolean;
	deleted: boolean;
	dotColor: string;
}
export declare type VariableMappingMap = { [key: string]: VariableMapping[] };
export declare type AIMatchingsMap = { [key: string]: AIMatchings };
export interface IDatabaseMapping {
	variablesMapping: VariableMappingMap;
	selectedLineItemsID: string[];
	tupleID: string;
	aiMatchings: AIMatchingsMap;
	ticType: string;
	state: 'new' | 'verified' | 'unverified';
}


export function createLineItemFromMappingAndInput(lineItem: IDatabaseLineItem, mapping: VariableMapping[], inputValue: string): string {
	mapping = mapping.filter((m) => !m.deleted);
	const ticValues = inputValue.replace(/\s/g, ' ').toLowerCase().split(' ');
	const variableFromTic = mapping.filter((m) => ticValues.includes(m.ticInput.toLowerCase())).map((m) => m.variableValue.toLowerCase());
	const output = lineItem.elements.map((element) => {
		if (element.type === 'text') return element.value;
		if (!element.acceptableValues || !element.acceptableValues.length) return element.value;

		const var1 = element.acceptableValues.filter((v) => variableFromTic.includes(v));
		if (!var1.length) {
			if (element.allowEmptyValue) {
				return '';
			}
			return element.defaultValue || element.acceptableValues[0];
		}
		return var1[0];
	}).join(' ');
	return output;
}

class MappingToolStore {
	get supportedTicTypes() {
		return ['Tic sheet type 1', 'Tic sheet type 2', 'Tic sheet type 3']
	}

	private _lineItems: IDatabaseLineItem[] = [];
	private _tupleID: string = '';

	// #region variablesMapping property
	private _variablesMapping: VariableMappingMap = {};
	get variablesMappingCount(): number {
		return Object.keys(this._variablesMapping).length;
	}
	getVariablesMapping(key: string): VariableMapping[] {
		return SharedUtils.clone(this._variablesMapping[key] || []); // TODO :: think of something better
	}
	setVariablesMapping(key: string, value: VariableMapping[]) {
		this._variablesMapping[key] = value;
		this._variablesMapping = { ...this._variablesMapping };
	}
	resetVariablesMapping() {
		this._variablesMapping = {};
	}
	// #endregion

	// #region aiMatchings property
	private _aiMatchings: { [key: string]: AIMatchings } = {};
	get aiMatchingsCount(): number {
		return Object.keys(this._aiMatchings).length;
	}
	getAIMatchings(key: string): AIMatchings {
		return this._aiMatchings[key];
	}
	setAIMatchings(key: string, value: AIMatchings, merge = false) {
		const newValue = merge ? { ...this._aiMatchings[key], ...value } : value;
		this._aiMatchings = {
			...this._aiMatchings,
			[key]: { ...newValue }
		};
	}
	resetAIMatchings() {
		this._aiMatchings = {};
	}
	get aiMatchings(): AIMatchingsMap {
		return this._aiMatchings;
	}

	// #endregion

	// #region selectedLineItems property
	private _selectedLineItems: IDatabaseLineItem[] = [];
	get selectedLineItems(): IDatabaseLineItem[] {
		return this._selectedLineItems;
	}
	set selectedLineItems(value: IDatabaseLineItem[]) {
		this._selectedLineItems = value;
	}
	// #endregion

	//#region wizardStep property
	private _wizardStep: number = 0;
	get wizardStep(): number {
		return this._wizardStep;
	}
	set wizardStep(value: number) {
		this._wizardStep = value;
	}
	//#endregion

	//#region ticType property
	private _ticType: string = 'Tic sheet type 1';
	get ticType(): string {
		return this._ticType;
	}
	set ticType(value: string) {
		this._ticType = value;
	}
	//#endregion

	//#region overTuple property
	private _overTuple: [number, number] = [-1, -1];
	get overTuple(): [number, number] {
		return this._overTuple;
	}
	set overTuple(value: [number, number]) {
		this._overTuple = value;
	}
	//#endregion

	//#region selectedTuple property
	private _selectedTuple: [number, number] = [-1, -1];
	get selectedTuple(): [number, number] {
		return this._selectedTuple;
	}
	set selectedTuple(value: [number, number]) {
		this._selectedTuple = value;
	}
	//#endregion

	//#region dbMappings property
	private _dbMappings: IDatabaseMapping[] = [];
	get dbMappings(): IDatabaseMapping[] {
		return this._dbMappings;
	}
	//#endregion



	public readonly measurements = [
		'No Measurements',
		'Floor(F)',
		'Ceiling(C)',
		'Walls(W)',
		'Floors and ceiling(WC)',
		'Perimeter of ceiling(PC)',
		'Perimeter of floor(PF)',
		'Long wall(LL)',
		'Short wall(SL)',
		'Volume of room(V)',
		'Floor below stairs(FBS)',
		'Walls below stairs(WBS)'
	];

	constructor() {
		makeAutoObservable(this);
		if (!appFeatures.isEstimatics) {
			this._fetchMappings();
		}
	}

	setCurrentTupple(selectedTupple?: TemplateCellTuple) {
		this._variablesMapping = {};
		this._selectedLineItems = [];
		this._aiMatchings = {};
		this._ticType = 'Tic sheet type 1';
		this._tupleID = '';
		if (selectedTupple) {
			SharedUtils.assert(this.wizardStep === 1, 'wizardStep should be 1');
			runInAction(() => {
				this._tupleID = selectedTupple.meta!.id!;
				const mapping = this.dbMappings.find((dbMapping) => dbMapping.tupleID === this._tupleID);
				if (mapping) {
					this._variablesMapping = { ...mapping.variablesMapping };
					this._selectedLineItems = mapping.selectedLineItemsID.map((lineItemID) => {
						return SharedUtils.clone(this._lineItems.find((lineItem) => lineItem.id === lineItemID)!);
					});
					this._aiMatchings = { ...mapping.aiMatchings };
					this._ticType = mapping.ticType;
				}
				// logDebug('selectedItems from store', SharedUtils.clone(this._selectedLineItems));
			});
		}
	}

	public async saveCurrentMapping(state: 'new' | 'verified' | 'unverified') {
		const newMapping: IDatabaseMapping = SharedUtils.clone({
			variablesMapping: this._variablesMapping,
			selectedLineItemsID: this._selectedLineItems.map((lineItem) => lineItem.id),
			tupleID: this._tupleID,
			state,
			aiMatchings: this._aiMatchings,
			ticType: this._ticType
		});
		const firestore: Firestore = getFirestore();
		const docRef = doc(firestore, SharedUtils.getDbPath('tools/mapping_tool/mappings/' + newMapping.tupleID));
		try {
			await setDoc(docRef, {
				...newMapping
			}, {
				merge: false
			});
		} catch (err) {
			logError('Error while saving mapping', err);
		}
	}

	public _fetchMappings() {
		const firestore: Firestore = getFirestore();
		SharedUtils.assert(firestore, 'firebase is not initialized');
		const queryWatch = onSnapshot(collection(firestore, SharedUtils.getDbPath('tools/mapping_tool/mappings')),
			(snapshot) => {
				snapshot.docChanges().forEach((change) => {
					const docData = change.doc.data() as IDatabaseMapping;
					runInAction(() => {
						if (change.type === 'added') {
							this.dbMappings.push(docData);
						} else if (change.type === 'modified') {
							const index = this.dbMappings.findIndex((dbMapping) => dbMapping.tupleID === docData.tupleID);
							if (index !== -1) {
								this.dbMappings[index] = docData;
							}
						} else if (change.type === 'removed') {
							const index = this.dbMappings.findIndex((dbMapping) => dbMapping.tupleID === docData.tupleID);
							if (index !== -1) {
								this.dbMappings.splice(index, 1);
							}
						}
					});
				});
				// logDebug('dbMappings', SharedUtils.clone(this.dbMappings));
			});
		const queryWatch2 = onSnapshot(collection(firestore, SharedUtils.getDbPath('tools')),
			(snapshot) => {
				const lineItems: IDatabaseLineItem[] = [];
				snapshot.forEach((doc) => {
					runInAction(() => {
						const data = doc.data() as IDatabaseLineItem;
						lineItems.push({
							...data,
							id: doc.id
						});
					});
				});
				runInAction(() => {
					this._lineItems = lineItems;
				});
			});
		return () => {
			queryWatch();
			queryWatch2();
		}
	}


	// const firestoreToolsCollectionRef = collection(useFirestore(), SharedUtils.getDbPath('tools')) as CollectionReference<IDatabaseLineItem>;
	// const lineItemsDataFromDataBase = useFirestoreCollectionData<IDatabaseLineItem>(firestoreToolsCollectionRef, { idField: 'id' });


}


const _mappingToolStore: MappingToolStore = new MappingToolStore();
const getMappingToolStore = () => _mappingToolStore;

export default getMappingToolStore;