import mixpanel from 'mixpanel-browser';
import { logDebug, logError, logWarning } from '../shared/logger';
import SharedUtils from '../shared/SharedUtils';
import { appFeatures } from '../data/AppFeatures';
import { EstimaticsProjectStoreType, getEstimateStore, getEstimaticsCurrentProjectStore, getUserStore, UserStoreType } from '../data/DataStores';
// import { logDebug } from './Logger';
function getOnlyDate(date: Date): string {
	return date.toISOString().split('T')[0];
}

export default class Analytics {

	public debugMode: boolean = false;

	private static _instance: Analytics;
	private _hasIdentity: boolean = false;
	private _testMode: boolean = false;
	private _estimaticsStore: EstimaticsProjectStoreType;
	private _userStore: UserStoreType;

	private constructor() {
		this._estimaticsStore = getEstimaticsCurrentProjectStore();
		this._userStore = getUserStore();
		try {
			this.debugMode = appFeatures.isDebug(false);
			mixpanel.init('4300682260a8afc39a36b14c5722d382', { debug: this._testMode });
			const date_only = `appStartSent-${getOnlyDate(new Date())}`;
			if (!localStorage.getItem(date_only)) {
				mixpanel.track('App Start');
				localStorage.setItem(date_only, 'true');
			}
		} catch (err) {
			logWarning('Error in Analytics constructor', 'Analytics', err);
		}
	}

	public static get Instance(): Analytics {
		return this.getInstance();
	}

	public static getInstance(): Analytics {
		if (!Analytics._instance) {
			Analytics._instance = new Analytics();
		}
		try {
			if (!Analytics._instance._hasIdentity) {
				const userStore = getUserStore();
				if (userStore.user?.uid) {
					mixpanel.identify(userStore.user?.uid)
					Analytics._instance._hasIdentity = true;
				}
			}
		} catch (err) {
			logWarning('Error in getInstance', 'getInstance', err);
		}
		return Analytics._instance;
	}

	public setCurrentUserData(email: string, name: string) {
		try {
			mixpanel.people.set({
				'$name': name,
				'$email': email,
				'UserType': 'Memeber',
				'screenHeight': screen.height,
				'screenWidth': screen.width,
			});
		} catch (err) {
			logWarning('Error in setCurrentUserData', 'setCurrentUserData', err);
		}
	}

	public pageView(page: string, properties?: any) {
		try {
			this.track('page_view', { page_title: page, ...properties });
			this.track(page, { ...properties });
		} catch (err) {
			logWarning('Error in pageView', 'pageView', err);
		}
	}

	public track(event: string, properties?: any) {

		try {
			if (this.debugMode && !this._testMode) {
				logDebug(event, properties);
			} else {
				if (appFeatures.isEstimatics) {
					try {
						this._estimaticsStore.estimaticsData?.estimateId && (properties = {
							...(properties || {}),
							domain: location.hostname,
							estimateId: this._estimaticsStore?.estimaticsData?.estimateId || 'unknown',
							projectName: this._estimaticsStore?.estimaticsData?.projectName || 'unknown',
							organizationId: this._userStore?.user?.organizationId || 'unknown',
							userId: this._userStore?.user?.lassoUserId || 'unknown',
							userName: this._userStore?.user?.displayName || 'unknown',
						});
					} catch (err) {
						logError('Error setting analytics meta data', 'track', err);
					}
				}
				mixpanel.track(event, properties);
			}
		} catch (err) {
			logWarning('Error in tracking', 'track', err);
		}
	}

	public alias(userId: string) {
		try {
			mixpanel.alias(userId);
		} catch (err) {
			logWarning('Error in alias', 'alias', err);
		}
	}
}