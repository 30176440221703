import { makeAutoObservable, runInAction } from 'mobx';
import { IExtractionValue, IHeatmapData, TemplateCellTuple, TemplateMetaData, allowedUnits } from '../../shared/Azure';
import RestAPI from '../restAPI';
import OpenAI from 'openai';
import { MD5 } from 'crypto-js';
import { logDebug, logError } from '../../shared/logger';
import { CSSProperties } from 'react';

import SharedUtils from '../../shared/SharedUtils';
import { LocalLargeStorage, localLargeStorage } from '../PrivateUtils';
import { IDatabaseLineItem } from '../../MVP/CreatorTools/LineItemDBCreationToolPage';
import { assert } from 'console';
import { Firestore, collection, onSnapshot } from 'firebase/firestore';




class MappingStore {
	public templateData: TemplateMetaData | undefined;
	public extractionData: { [s: string]: IExtractionValue[] } | undefined;
	private _loading: boolean = false;
	private _forceFetchMetadata: boolean = false;
	private _loadDataFromServerCalled = false;



	constructor() {
		logDebug('MappingStore constructor');
		makeAutoObservable(this);
		// this._loadDataFromServer();
	}

	getImageForCandidate(selectedTupple: TemplateCellTuple, ticInput: string): string[] {
		const ret: string[] = ['/assets/ui/x-close.svg'];
		for (const v of selectedTupple.meta?.heatmapData || []) {
			const words = this.getCandidates(v);
			if (words.find(w => w.trim().toLowerCase() === ticInput.trim().toLowerCase())) {
				ret.push(`data:image/png;base64,${v.region64}`);
			}
		}

		return ret;
	}

	public async loadData(force: boolean = false) {
		if (this._loadDataFromServerCalled && !force) return;
		this._loadDataFromServerCalled = true;
		await this._loadDataFromServer();
	}


	public getCandidates(_v: IHeatmapData) {
		const v = SharedUtils.clone(_v) as IHeatmapData;
		const template = [
			v.template[0].toLowerCase().replace(/\s/g, ' ').split(' '),
			v.template[1].toLowerCase().replace(/\s/g, ' ').split(' ')
		];
		v.words[0] = v.words[0].filter((w) => !template[0].includes(w.word.toLowerCase()));
		v.words[1] = v.words[1].filter((w) => !template[1].includes(w.word.toLowerCase()));

		const confidence0 = Math.min(...v.words[0].map((w) => w.confidence));
		const confidence1 = Math.min(...v.words[1].map((w) => w.confidence));
		if (confidence0 > 0.9 && confidence1 > 0.9) {
			const words = [...v.words[0].map(v => v.word), ...v.words[1].map(v => v.word)];
			const filteredWords = words.map((v) => {
				if (!isNaN(Number(v))) return '';
				v = v.trim();

				v = v.replaceAll('(', '');
				v = v.replaceAll(')', '');

				while (v.startsWith(',') || v.startsWith('.')) {
					v = v.substring(1);
				}
				while (v.endsWith(',') || v.endsWith('.')) {
					v = v.substring(0, v.length - 1);
				}

				let tmp = allowedUnits.find((u) => v.toLowerCase().endsWith(u));
				if (tmp) {
					v = v.substring(0, v.length - tmp.length);
				}

				tmp = allowedUnits.find((u) => v.toLowerCase().startsWith(u));
				if (tmp) {
					v = v.substring(tmp.length);
				}

				if (allowedUnits.includes(v.toLowerCase())) {
					return '';
				}

				if (!isNaN(Number(v))) return '';

				// if v contains a number replace it with {N}
				v = v.replace(/\d+/g, '{N}');


				return v;
			});
			const cArr = SharedUtils.removeDuplicateAndEmpty(filteredWords);
			return cArr
		}
		return [];

	}

	private _extractCandidates(heatmapData: IHeatmapData[]) {
		let candidates = SharedUtils.removeDuplicateAndEmpty(heatmapData.map((v, ind) => {
			return this.getCandidates(v);
		}).flatMap((v) => v));

		// remove numbers
		// candidates = candidates.filter((v) => isNaN(Number(v)));
		return candidates;
	}

	private _setIdToCell(cell: TemplateCellTuple, cellPos: {
		rowIndex: number,
		cellIndex: number
	}, templateData: TemplateMetaData, heatmapData: { [s: string]: IHeatmapData[] }, maxHeatmapValue: number) {
		if (cell.meta?.isCategory) return;

		for (let i = cellPos.rowIndex || 0; i >= 0; i--) {
			if (templateData.rows[i].cellTuples[cellPos.cellIndex].meta?.isCategory) {
				// TODO :: move all id and selectables initialization to the server
				let cat = templateData.rows[i].cellTuples[cellPos.cellIndex];
				cell.meta = cell.meta || {};
				cell.meta.heatMapProcessedData = cell.meta.heatMapProcessedData || {}
				let cellContent = cell.cells[0].content.trim();
				cell.meta.id = `${cat.cells[0].content} ${cellContent}`;
				cell.meta.id = cell.meta.id.replace('&', 'and');
				cell.meta.id = cell.meta.id.replaceAll('(', '').replaceAll(')', '');
				cell.meta.id = cell.meta.id.trim().replace(/\s/g, '_').toLowerCase();
				cell.meta.id = cell.meta.id.replace(/[^a-zA-Z0-9]/g, '_');
				cell.meta.id = cell.meta.id.replace(/_+/g, '_');
				cell.meta.heatmapData = heatmapData[cell.meta.id];

				//@ts-ignore
				delete cell.meta.selecteables;

				cell.meta.selectables = selectablesMap[cell.meta.id] ?? [];
				logDebug('cell.meta', cell.meta.id, cell.meta.selectables);
				if (cell.meta.heatmapData && cell.meta.heatmapData.length > 0) {
					cell.meta.heatMapProcessedData.count = Math.max(0, cell.meta.heatmapData.length - 3);
					const value = cell.meta.heatMapProcessedData.count / maxHeatmapValue;
					// Calculate red and green values
					const red = 0; //Math.floor(255 * (1 - value));
					const green = 255;
					const blue = 0; // Blue is not used in this gradient
					const alpha = Math.min(1, value * 2);
					cell.meta.heatMapProcessedData.bgColor = `rgba(${red}, ${green}, ${blue},${alpha})`;
					if (cell.meta.heatmapData.length > 30) {
						cell.meta.heatMapProcessedData.bgColor = `rgba(${red}, ${green}, ${blue},${1})`;
					}
					cell.meta.heatMapProcessedData.candidates = this._extractCandidates(cell.meta.heatmapData);
				} else {
					cell.meta.heatMapProcessedData.bgColor = '';
				}
				break;
			}
		}
	}


	private async _loadDataFromServer() {
		logDebug('Loading data from server')
		this.loadingData = true;
		let td: {
			templateData: TemplateMetaData,
			heatmap?: { [s: string]: IHeatmapData[] },
			extraction?: { [s: string]: IExtractionValue[] }
		} | undefined = undefined;

		try {
			if (!this._forceFetchMetadata) {
				const cachedData = await localLargeStorage.getItem('__templateData');
				if (cachedData) {
					td = JSON.parse(cachedData);
				}
			}
			if (!td) {
				td = await RestAPI.fetchAPI('loadTemplateData', 'GET', { heatmap: true, extraction: false });
				const tdhm = Object.values(td!.heatmap!).map((v) => v.filter(v1 => v1.selectedWords.length > 0)).flat();

			}
		} catch (err) {
			logError('Error loading template data', err);
		}

		if (td && td.templateData) {
			td.heatmap = td.heatmap || {};
			const maxHeatmapValue: number = Math.max(...Object.values(td.heatmap).map((v) => v.length));
			td.templateData.rows.forEach((row, rowIndex) => {
				row.cellTuples.forEach((tuple, index) => {
					this._setIdToCell(tuple, { rowIndex, cellIndex: index }, td!.templateData, td!.heatmap!, maxHeatmapValue);
				});
			});
			await localLargeStorage.setItem('__templateData', JSON.stringify(td));
			runInAction(() => {
				this.templateData = td!.templateData;
				this.extractionData = td!.extraction;
				logDebug('data loaded');
			});
		} else {
			logError('Error loading template data');
		}
		this.loadingData = false;
	}

	public get loadingData() {
		return this._loading;
	}
	public set loadingData(value: boolean) {
		this._loading = value;
	}


}

const _mappingStore: MappingStore = new MappingStore();
const getMappingStore = () => _mappingStore;

export interface ILinkValue {
	confidence: 'confident' | 'not confident',
	variable: string
	status?: 'approved' | 'not approved'
}



export default class MappingUtils {
	private static _sendFakeMapping = true;
	private static _instance: MappingUtils;
	private static _localLargeStorage = new LocalLargeStorage('MappingUtils');
	private constructor() {
	}
	public static instance() {
		return this._instance || (this._instance = new this());
	}

	public static async linkVariablesUsingOpenAI(variables: string[], inputs: string[], force: boolean = false) {
		if (this._sendFakeMapping) {
			const mapping = {} as { [s: string]: ILinkValue };
			for (const input of inputs) {
				if (!mapping[input]) {
					mapping[input] = {
						variable: 'not available',
						confidence: 'confident'
					}
				}
			}
			return mapping;
		}


		// logDebug('linkVariablesUsingOpenAI', variables, inputs);
		variables = SharedUtils.removeDuplicateAndEmpty(variables.map(v => v.replace(/\s/g, ' ')));
		inputs = SharedUtils.removeDuplicateAndEmpty(inputs.map(v => v.replace(/\s/g, ' ')));

		const OPENAI_API_KEY = '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'; // TODO :: move to server so we won't expose the key
		const openai = new OpenAI({
			dangerouslyAllowBrowser: true,
			apiKey: OPENAI_API_KEY //process.env['OPENAI_API_KEY']
		});

		const content = `
		given the following glossary
		W=Wall,
		C=ceiling,
		F=floor,
		PF=Perimeter floor,
		PC=Perimeter Ceiling,
		DR=Detach&reset,
		RR=Remove&replace,
		Rem=Remove,
		R=Replace only

		and the following values ( each value in his own line )

		${variables.join('\n')}

		what value from the above will you connect to the following human input ( each input in his own line )

		${inputs.join('\n')}

		if there is something that you are not confidence about please add that you are not confident.
		each value should be mapped to only one variable from above.
		if there is no mapping available place "not available" in the variable field.
		if it maps to 2 then add that you are not confident.
		the variable value in the json must come from the list of values above.
		json file format should look like this:
		{
			"r": {
				"variable": "remove",
				"confidence": "confident|not confident"
			},
		}
		`;

		const contentHash = `${MD5(content).toString()}-v2`;
		if (!force && await this._localLargeStorage.getItem(contentHash)) {
			return JSON.parse(await this._localLargeStorage.getItem(contentHash)!);
		}

		const test = await openai.chat.completions.create({
			model: "gpt-4o",
			messages: [{
				role: "system", content: "You are a helpful assistant designed to output only JSON without any other text."
			}, {
				role: "user", content

			}],
		});

		try {
			let text: string = test.choices[0].message.content!;
			text = text.replace("```json", "").replace("```", "").trim();
			logDebug("reply from openAI", text);

			await this._localLargeStorage.setItem(contentHash, JSON.stringify(JSON.parse(text)));
			const mapping = JSON.parse(text);
			for (const input of inputs) {
				if (!mapping[input]) {
					mapping[input] = {
						variable: 'not available',
						confidence: 'confident'
					}
				}
			}
			return mapping;
		} catch (e) {
			logError("open AI error:", e);
		}
		return {};
	}


	getTableFromTemplate(setSelectedTuple: (tuple: [number, number]) => void, setOverTuple: (tuple: [number, number]) => void, selectedTuple: [number, number], OverTuple: [number, number], props?: CSSProperties, td?: TemplateMetaData) {
		const _templateData = _mappingStore.templateData || td;
		if (!_templateData) {
			return [];
		}
		const ret: (JSX.Element | JSX.Element[])[] = [];

		_templateData!.header.forEach((row, rowIndex) => {
			ret.push(<tr key={rowIndex}>{row.cells.map((cell, index) => {
				return <td
					// colSpan={cell.columnSpan}
					key={index}
					style={{
						// border: `1px solid black`,
					}}>
					{
						cell.content
					}
				</td>
			}
			)}</tr>);
		});


		_templateData!.rows.forEach((row, rowIndex) => {
			if (false) {
			} else {
				ret.push(<tr key={rowIndex}>{
					row.cellTuples.map((tuple, index) => {
						return <td
							style={{
								padding: 0,
							}}
							key={index}>
							<table width="100%">
								<tbody>
									<tr
										onClick={() => (!_templateData.rows[rowIndex].cellTuples[index].meta?.isCategory) && setSelectedTuple([rowIndex, index])}
										onMouseEnter={() => (!_templateData.rows[rowIndex].cellTuples[index].meta?.isCategory) && setOverTuple([rowIndex, index])}
										onMouseLeave={() => (!_templateData.rows[rowIndex].cellTuples[index].meta?.isCategory) && setOverTuple([-1, -1])}
										style={{
											color: tuple.meta?.id ? 'black' : 'blue',
											cursor: (!_templateData.rows[rowIndex].cellTuples[index].meta?.isCategory) ? 'pointer' : 'default',
											backgroundColor: rowIndex === OverTuple[0] && index === OverTuple[1] ? 'yellow' : (rowIndex === selectedTuple[0] && index === selectedTuple[1]) ? 'green' : (tuple.meta?.isCategory ? 'lightblue' : 'white'),
											// border: `1px solid gray`
										}}
									>
										<td
											style={{
												padding: 0,
												width: '70%',
												height: props?.height ?? 40,
												// border: `1px solid gray`
											}}
										>
											<span>{tuple.cells[0].content}</span>
											<br />
											<span className='text-xs'>{tuple.meta!.selectables && JSON.stringify(tuple.meta!.selectables)}</span>
										</td>
										<td
											style={{
												backgroundColor: tuple.meta?.heatMapProcessedData?.bgColor || '',
												padding: 0,
												width: '30%',
												height: props?.height ?? 40,
												// border: `1px solid gray`
											}}>
											<span>{tuple.cells[1].content}</span>
											<br />
											{!tuple.meta?.isCategory && tuple.cells[1].content && <span>ref:{tuple.meta?.heatMapProcessedData?.count || '0'}</span>}
											{/* <span>{JSON.stringify(tuple.meta?.heatMapProcessedData?.candidates, null, 1)}</span> */}
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					}
					)}</tr>);

			}
		});
		return ret;
	}


	// Normalize a string (convert to lowercase and trim whitespace)
	static normalizeString(str: string): string {
		return str.toLowerCase().trim();
	}

	// Tokenize a string into words
	static tokenizeString(str: string): string[] {
		return this.normalizeString(str).split(/\s+/); // Split by any whitespace
	}

	// Check if a database string matches the search terms
	static countMatchingTokens(dbString: string, searchTerms: string[]): number {
		const normalizedDbString = this.normalizeString(dbString);
		// return searchTerms.every(term => new RegExp(term).test(normalizedDbString));
		return searchTerms.filter(term => {
			try {
				return new RegExp(term).test(normalizedDbString)
			} catch (error) {
				// logError('Error in regex', error);
			}
			return false;
		}).length;

	}

	// Filter database strings by input string
	static filterDatabase(db: IDatabaseLineItem[], input: string): IDatabaseLineItem[] {
		if (!input.trim() || !db || db.length === 0) return [];
		// const searchTerms = this.tokenizeString(input);
		// return db.filter(dbString => this.matchesSearchTerms(dbString.originalLineItem, searchTerms));
		const searchTerms = this.tokenizeString(input);
		const results = db
			.map(lineItem => ({
				lineItem,
				matchingTokens: this.countMatchingTokens(lineItem.originalLineItem, searchTerms)
			}))
			.filter(result => result.matchingTokens > 0)
			.sort((a, b) => b.matchingTokens - a.matchingTokens);

		return results.map(result => result.lineItem);
	}

}

export {
	getMappingStore
}



const selectablesMap: { [key: string]: string[] } = {
	"paint_and_stain_masking_sf_lf": [
		"(SF)",
		"(LF)"
	],
	"contents_manipulation_room_size_hours": [
		"(Room Size)",
		"(Hours)"
	],
	"contents_pack_out_yes": [
		"(YES)"
	],
	"contents_boxes_s_m_l": [
		"(S)",
		"(M)",
		"(L)"
	],
	"contents_window_drapes_detach_reset": [
		"reset)",
		"(detach"
	],
	"paint_and_stain_door_slab_sides": [
		"(Sides)"
	],
	"paint_and_stain_opening_window_or_door": [
		"(window",
		"door)"
	],
	"electrical_smoke_co_detector": [
		"Smoke",
		"CO Detector"
	],
	"walls_and_ceiling_wall_insulation_r_13_r_15_r_19": [
		"(R-13)",
		"(R-15)",
		"(R-19)"
	],
	"paint_and_stain_opening_window_or_door_large": [
		"(Large)",
		"(window",
		"door)"
	],
	"walls_and_ceiling_ceiling_insulation_r_30_r_38": [
		"(R-30)",
		"(R-38)"
	],
	"paint_and_stain_concrete_floor_wall": [
		"(Floor)",
		"(Wall)"
	],
	"walls_and_ceiling_drywall_lf_1_2_or_5_8_flood_cuts_2ft_or_4ft": [
		"1/2",
		"5/8",
		"2ft",
		"4ft",
		"(flood cuts)"
	],
	"walls_and_ceiling_drywall_1_2_or_5_8": [
		"1/2",
		"5/8"
	],
	"walls_and_ceiling_drywall_tape_joint_repair_per_lf": [
		"joint",
		"repair"
	],
	"walls_and_ceiling_cement_board_1_4_or_1_2": [
		"( 1/4 )",
		"(1/2)"
	],
	"walls_and_ceiling_plaster_coats_1_2_3_or_blueboard": [
		"(1)",
		"(2)",
		"(3)",
		"Blueboard"
	],
	"finish_trim_baseboards_size_": [
		"(Size)"
	],
	"electrical_outlet_switch_covers": [
		"Outlet",
		"Switch"
	],
	"electrical_outlet_switch": [
		"Outlet",
		"Switch"
	],
	"walls_and_ceiling_ceiling_tile_acoustic_suspended_2x2_2_4": [
		"(acoustic)",
		"(suspended)",
		"(2x2)",
		"(2×4)"
	],
	"finish_trim_crown_molding_size_": [
		"(Size)"
	],
	"walls_and_ceiling_ceiling_grid_2x2_or_2x4": [
		"(2x2)",
		"(2x4)"
	],
	"finish_trim_chair_rail_size_": [
		"(Size)"
	],
	"finish_trim_casing_size_": [
		"(Size)"
	],
	"walls_and_ceiling_skim_coat_light_regular_heavy": [
		"(light)",
		"(regular)",
		"(heavy)"
	],
	"doors_and_windows_int_pre_hung_door_type": [
		"(type)"
	],
	"doors_and_windows_ext_pre_hung_door_type": [
		"(type)"
	],
	"walls_and_ceiling_paneling_size_": [
		"(Size)"
	],
	"finish_trim_vinyl_base_molding_4_inch_6_inch": [
		"(4 inch)",
		"(6 inch)"
	],
	"finish_trim_trim_board_size": [
		"(size)"
	],
	"doors_and_windows_window_wood_vinyl_style_": [
		"(wood)",
		"(Vinyl)",
		"Style="
	],
	"plumbing_radiator_unit_size": [
		"Unit",
		"Size"
	],
	"plumbing_radiator_cover_size": [
		"Cover",
		"Size"
	],
	"flooring_wood_flooring_hw_eng_lam": [
		"(HW)",
		"(ENG)",
		"(LAM)"
	],
	"flooring_vinyl_sheet_roll": [
		"(roll)"
	],
	"flooring_vinyl_self_adhesive": [
		"(self adhesive)"
	],
	"flooring_step_charge_for_tucked_per_rise": [
		"(per rise)"
	],
	"flooring_underlayment_subfloor": [
		"Underlayment",
		"Subfloor"
	],
	"flooring_cement_board_1_4_or_1_2": [
		"( 1/4)",
		"(1/2)"
	],
	"flooring_threshold_wood_mtl_cult_marble_marble": [
		"(wood)",
		"(MTL)",
		"(cult marble)",
		"(marble)"
	],
	"flooring_level_concrete_light_avg_heavy": [
		"(Light)",
		"(Avg)",
		"(Heavy)"
	]
}