
function getLogTime() {
	// get time in string like hh:mm:ss.ms

	if (location.href.includes('localhost')) {
		const date = new Date();
		const hours = date.getHours();
		const minutes = date.getMinutes();
		const seconds = date.getSeconds();
		const milliseconds = date.getMilliseconds();
		// pad with 0 if needed
		const pad = (num: number) => num.toString().padStart(2, '0');
		return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}.${milliseconds}`;
	}

	return new Date().toLocaleTimeString("en-US", { hour12: false });
}

const errorOnceMap: { [key: string]: boolean } = {}
export function logErrorOnce(...msgs: any[]): void {
	const key = msgs.join();
	if (!errorOnceMap[key]) {
		errorOnceMap[key] = true;
		logError(msgs);
	}
}


export function logError(...msgs: any[]): void {
	console.error(getLogTime(), ...msgs);
}
export function logWarning(...msgs: any[]): void {
	console.warn(getLogTime(), ...msgs);
}

export function logInfo(...msgs: any[]): void {
	console.info(getLogTime(), ...msgs);
}

const debugOnceMap: { [key: string]: boolean } = {}
export function logDebugOnce(...msgs: any[]): void {
	const key = msgs.join();
	if (!debugOnceMap[key]) {
		debugOnceMap[key] = true;
		logDebug(msgs);
	}
}
export function logDebug(...msgs: any[]): void {
	console.log(getLogTime(), ...msgs);
}

// export function logTrace(...msgs: any[]): void {
// 	console.log(getLogTime(), ...msgs);
// }
