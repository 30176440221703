import React, { useRef } from 'react';
import './App.scss';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react';
import { firebaseConfig, getUserStore } from './data/DataStores';

import LassoMVPLoginPage from './MVP/pages/LassoMVPLoginPage';
import LassoMVPLandingPage from './MVP/pages/LassoMVPLandingPage';
import LassoMVPOrganizationSettingsPage from './MVP/pages/LassoMVPOrganizationSettingsPage';
import LassoTopBar from './MVP/components/LassoTopBar';

import { FirebaseAppProvider, FirestoreProvider, useFirebaseApp } from 'reactfire';
import { getFirestore } from 'firebase/firestore';
import { logDebug } from './shared/logger';
import EstimaticsProjectPage from './MVP/pages/EstimaticsProjectPage';
import Analytics from './utils/Analytics';

const NotFoundPage: React.FC = () => {
	return (
		<div>
			<h1>404 Not Found</h1>
		</div>
	);
};
const App: React.FC = observer(() => {
	// TOOD :: set up runtime config for dev/prod/debug
	const firestore = useRef(getFirestore(useFirebaseApp(), (location.href.includes('localhost') && !location.href.includes('disable-dev-private')) ? 'estimatics-dev-private' : ''));
	const analytics = useRef(Analytics.getInstance());
	const firestoreInstance = firestore.current;
	const userStore = getUserStore();
	// logDebug('firestore', firestoreInstance);
	if (!userStore.appReady) {
		return <div>Loading App...</div>;
	}
	return <FirestoreProvider sdk={firestoreInstance}>
		{
			userStore.user ?
				<div>
					<Router>
						<LassoTopBar />
						<div className='m-8'>
							<Routes>
								<Route path="/organizations/:organizationId" element={<LassoMVPOrganizationSettingsPage />} />
								<Route path="/projects/:estimateId" element={<EstimaticsProjectPage />} />
								<Route path="*" element={<LassoMVPLandingPage />} />
							</Routes>
						</div>
					</Router>
				</div>
				:
				<LassoMVPLoginPage />}
	</FirestoreProvider>
});




const FirebaseApp: React.FC = () => {
	return <FirebaseAppProvider firebaseConfig={firebaseConfig}>
		<App />
	</FirebaseAppProvider>
}

export default FirebaseApp;

