import React, { CSSProperties } from 'react'
import './Toggle.scss'
import Toggle from 'react-toggle';
import { logDebug } from '../../../shared/logger';

const LassoSimpleButton: React.FC<{ disabled?: boolean, onClick: () => void, caption: string }> = ({ disabled, caption, onClick }) => {
	return <div
		onClick={() => {
			if (disabled) return;
			onClick();
		}}
		className={`${disabled ? 'bg-[#98A2B3] border-[#98A2B3]' : 'bg-blue-900 border-blue-900'} select-none cursor-pointer px-[18px] py-2.5  rounded-tr-lg rounded-br-lg shadow border  justify-center items-center gap-2 flex`}>
		<div className="whitespace-nowrap text-white text-base font-semibold font-['Inter'] leading-normal">{caption}</div>
	</div>
}


const LassoSimpleButtonFull: React.FC<{ disabled?: boolean, onClick?: () => void, caption: string, customButtonStyle?: CSSProperties, customTextStyle?: CSSProperties }> = ({ disabled, caption, onClick, customButtonStyle, customTextStyle }) => {
	return <div
		onClick={() => {
			if (disabled) return;
			onClick && onClick();
		}}
		className={`${disabled ? 'bg-[#98A2B3] border-[#98A2B3]' : 'bg-blue-900 border-blue-900'} inline-flex py-3 px-4 rounded-lg  select-none cursor-pointer justify-center items-center gap-2`}
		style={{ ...customButtonStyle }}
	>
		{/*  px-[18px] py-2.5  rounded-tr-lg rounded-br-lg shadow border  justify-center items-center gap-2 flex` */}
		<div
			className={`whitespace-nowrap text-white text-base font-semibold font-['Inter'] leading-normal `}
			style={{ ...customTextStyle }}
		>
			{caption}

		</div>
	</div>
}

const LassoToggle: React.FC<{ checked?: boolean, onChange?: (val: boolean) => void, disabled?: boolean }> = ({ checked, onChange, disabled }) => {
	return <Toggle
		disabled={disabled}
		icons={false}
		defaultChecked={!!checked}
		onChange={(e) => {
			if (disabled) return;
			onChange && onChange(e.target.checked)
		}} />
}

export {
	LassoToggle,
	LassoSimpleButton,
	LassoSimpleButtonFull
};