import React, { useState } from 'react';
import { logDebug } from '../../../shared/logger';

const ImageWithPlaceholder: React.FC<{ className?: string, src: string, placeholder: string, alt: string }> = ({ className, src, placeholder, alt }) => {
	const [isLoaded, setIsLoaded] = useState(false);

	const handleImageLoad = () => {
		// logDebug('ImageWithPlaceholder', src)
		setIsLoaded(true);
	};

	return <img
		src={isLoaded ? src : placeholder}
		alt={alt}
		onLoad={handleImageLoad}
		className={className ?? ''}
	// style={{ display: isLoaded ? 'block' : 'none' }}
	/>
	{/* {!isLoaded && <img src={placeholder} alt="placeholder" />} */ }


};

export default ImageWithPlaceholder;